import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Form, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody, CardHeader } from "reactstrap";

import Dropzone from "react-dropzone";

import classnames from "classnames";
import MetaTags from "react-meta-tags";


//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import Img1 from "../../assets/images/post-1.jpg";
import Img2 from "../../assets/images/post-2.jpg";

import bgimg from "../../assets/images/pattern-bg.jpg";
import { Link } from 'react-router-dom';

import { appContext } from "../../App";
import { useProfile } from "../../Hooks/UserHooks";


// Snow Editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useSpring, animated } from '@react-spring/web'

import graphql from "../../graphql/gql"
import {TranslateContext} from "../../providers/Translate";

const Feedback = () => {
  const {language} = React.useContext(TranslateContext)
    const dispatch = useDispatch();
    const { userProfile, loading } = useProfile();

    var [balance, SetBalance] = useState(99999);
    var [minutes, SetMinutes] = useState(0);
    var [minutecost, SetMinuteCost] = useState(0);

    const [email, SetEmail] = useState("")
    const [value, setValue] = useState(`<h2>Здравствуйте!</h2> 
    </br> 
    <h3>Хочу сообщить о проблеме.</h3> 
    </br> 
    `);
    /*
    set breadcrumbs
    */

   const [SendEmail] = useMutation(graphql.mutations.EMAIL,
    {
      onCompleted: (data) => {
         alert("Письмо отправлено!")
      },
      onError(error) {
        alert(error)
      },
  });

    useEffect(() => {
        const breadCrumbItems = {
            title: language["Feedback"],
        };
        dispatch(setBreadcrumb(breadCrumbItems));
    }, [dispatch]);

    const [customActiveTab, setcustomActiveTab] = useState("1");

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    useEffect(()=>{
        SetMinutes(Math.round(balance/Math.max(1, minutecost)))
    }, [balance])

    const styles = useSpring({
        from: {
          opacity: 0,
          y: '10%',
        },
        to: {
          opacity: 1,
          y: '0%',
        },
      })

    return (
        <React.Fragment>
            <MetaTags>
                <title>Техническая поддержка | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>

                    <Row>
                    <animated.div  style={{...styles}}>
                        <div className="col-xxl-12">

                            <div className="card">
                                <div className="card-header text-center">
                                        <h1>{language["Feedback"]}</h1>
                                </div>
                                <div className="card-body">

                                    <Row className="mb-3">
                                        <Col md={12}>
                                            Техническая поддержка осуществляется через телеграм <a target='blank' href="https://t.me/timelist_talk">@timelist_talk</a>.
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={12}>
                                            Или отправьте задачу службе поддержки напрямую через форму: <a target='blank' href="https://forms.yandex.ru/cloud/6748bacfe010db76dfb2e546/">открыть форму</a>.
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col md={12}>
                                            Или напишите на почту: <a target='blank' href="mailto:support@timelist.ru">support@timelist.ru</a>.
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <label htmlFor="example-email-input" className="col-md-2 col-form-label">Email</label>
                                        <Col md={10}>
                                            <input className="form-control" type="email" value={email} onChange={(e)=>SetEmail(e.currentTarget.value)} defaultValue="bootstrap@example.com" placeholder="Email"
                                                id="example-email-input" />
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <label htmlFor="example-email-input" className="col-md-2 col-form-label">Введите ваше сообщение</label>
                                        <Col md={10}>
                                        <div id="bubble-editor" style={{ height: "300px" }}>
                                            <ReactQuill theme="bubble" id="bubble-editor" value={value} onChange={setValue} style={{ height: "300px" }} />
                                        </div>
                                        </Col>
                                    </Row>


                                    <Row className="mb-3">
                                        <Col md={12} className="text-center">
                                            <button type="button" className="btn btn-primary w-md" onClick={()=>{
                                                SendEmail({variables: {
                                                    email: "support@timelist.ru", message: value, backemail: email
                                                }})
                                            }}>Отправить</button>
                                        </Col>
                                    </Row>


                                </div>
                            </div>
                        </div>
                    </animated.div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Feedback;
