import React, { useState, useEffect, useContext } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, Modal, DropdownToggle, Nav, Form, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardBody, CardHeader } from "reactstrap";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Dropzone from "react-dropzone";

import classnames from "classnames";
import MetaTags from "react-meta-tags";
import Moment from 'react-moment';

//SimpleBar
import SimpleBar from "simplebar-react";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar7 from "../../assets/images/users/avatar-7.jpg";

import Img1 from "../../assets/images/post-1.jpg";
import Img2 from "../../assets/images/post-2.jpg";

import bgimg from "../../assets/images/pattern-bg.jpg";
import { Link } from 'react-router-dom';

import { appContext } from "../../App";

import {useFileUploader} from '../../Hooks/FileUploaderHook'
import { useProfile, useUsers } from "../../Hooks/UserHooks";
import {usePresets} from "../../Hooks/PresetsHooks"

import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";

import { useSpring, animated } from '@react-spring/web'

var delfile = null
var dontsave = true
const Terms = () => {
  const { userId } = useProfile();
  const dispatch = useDispatch();
  const { language } = React.useContext(TranslateContext)
  const { error: presetserror, LoadPresets } = usePresets()
  const { setNotify } = React.useContext(NotifyContext);

  const [terms, SetTerms] = React.useState(null)
  const [term, SetNewTerm] = React.useState("")

  const {user, LoadTerms, SaveTerms} = useUsers({onDone: (operation, data) => {
    if (operation == "loadTerms")
    {
        dontsave = true
        var terms = data.trim().split(',')
        var filtered = terms.filter(function (el) {
            return el != null && el != "" && el != undefined;
          });

        SetTerms(filtered)
    }
  }})

  useEffect(() => {
    const breadCrumbItems = {
        title: "Мои термины",
    };
    dispatch(setBreadcrumb(breadCrumbItems));
  }, [dispatch]);

  const SaveTermsToServer = () => {

    var filtered = terms.filter(function (el) {
        return el != null && el != "" && el != undefined;
    });

    SaveTerms({variables: {id: userId, terms: filtered.join(',')}})
  }

  useEffect(()=>{
    LoadTerms({variables:{id: userId}})
  }, [userId])

  useEffect(()=>{
    if (terms == null || dontsave)
    {
        dontsave = false
        return
    }
    SaveTermsToServer()
  }, [terms])

  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
    config: { duration: 500 },
  })
    
    return (
        <React.Fragment>
        <MetaTags>
            <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
        </MetaTags>
        <div className="page-content">
            <Container fluid>

                <Row>
                    <Col md={12} lg={12} xl={12}>
                    <animated.div  style={{...styles}}>
                        <Card className="mb-0">
                                        <CardBody>
                                            <div className="auth-full-page-content rounded d-flex p-3 my-2">
                                                <div className="w-100">
                                                    <div className="d-flex flex-column h-100">

                                                        <div className="auth-content my-auto">

                                                            <p><b>Введите в поле ниже специфические слова и выражения, на которые искусственному интеллекту стоит обратить внимание.</b></p>

                                                            <div>
                                                                <div className='alert alert-primary' style={{display: "inline-block"}}>важно: каждый новый термин увеличивает время расшифровки</div>
                                                            </div>

                                                            <form className="mt-4 pt-2" action="/index">
                                                            
                                                            <div className=" mb-4 auth-pass-inputgroup position-relative">
                                                                    <Row>
                                                                        <Col md={4} style={{marginBottom:20}}>
                                                                            <input type={"text"} className="form-control m-0 pe-5" id="password-input" placeholder={language["New term"]} defaultValue="" value={term} onChange={e => SetNewTerm(e.target.value)} />

                                                                            <div className="my-3 text-left">
                                                                                <button className="btn btn-primary waves-effect waves-light" type="button" onClick={()=>{
                                                                                    SetTerms([...terms, term])
                                                                                    SetNewTerm("")
                                                                                }}>{language["Save"]}</button>
                                                                            </div>
                                                                        </Col>

                                                                        <Col  md={8}>
                                                                            {terms?.map((t,i)=>{
                                                                                return <span className='badge text-black badge-pill pl-2' style={{fontSize:18, marginRight:20, marginBottom:20, background:"#F6F7F8"}}>{t}{" "}
                                                                                        <button type="button" className='btn pl-1' onClick={()=>{
                                                                                            terms.splice(i,1)
                                                                                            SetTerms([...terms])
                                                                                        }}>X</button>
                                                                                </span>
                                                                            })}
                                                                        </Col>
                                                                    </Row>
                                                            </div>

                                                                
                                                                
                                                            </form>

                                                          
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                        </Card>
                        </animated.div>
                    </Col>
                </Row>
            </Container>
        </div>
    </React.Fragment>
    );
};


export default Terms;

