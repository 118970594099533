import React, { useEffect } from 'react'
import s from './myFiles.module.scss'
import { ExistFilePanel } from './components/ExistFilePanel'
import { Tabs } from './components/Tabs'
import AudioPlayer from 'react-h5-audio-player'
import { SpeakersPanel } from './components/SpeakersPanel'
import { AnaliticsPanel } from "./components/AnaliticsPanel"
import { Col, Modal, Row } from 'reactstrap'
import { useFileUploader } from '../../Hooks/FileUploaderHook'
import { TranslateContext } from '../../providers/Translate'
import { NotifyContext } from '../../providers/Notify'
import { ModalContent } from './components/ModalContent'
import { useProfile } from '../../Hooks/UserHooks'
import Moment from 'react-moment'
import { NewFilePanel } from './components/NewFilePanel'
import { Tooltip } from 'reactstrap'
import { useSpring, animated } from '@react-spring/web'

import config from "../../config"

import { useUsers } from '../../Hooks/UserHooks'
import { Alert } from 'bootstrap'



import EditIcon from '../../assets/images/edit-save.png'
// const {log} = console

function unEscape(htmlStr) {
    htmlStr = htmlStr.replace(/&lt;/g, '<')
    htmlStr = htmlStr.replace(/&gt;/g, '>')
    htmlStr = htmlStr.replace(/&quot;/g, '"')
    htmlStr = htmlStr.replace(/&#39;/g, "'")
    htmlStr = htmlStr.replace(/&amp;/g, '&')
    return htmlStr
}

function Escape(htmlStr) {
    htmlStr = htmlStr.replace(/&lt;/g, '<')
    htmlStr = htmlStr.replace(/&gt;/g, '>')
    htmlStr = htmlStr.replace(/&quot;/g, '"')
    htmlStr = htmlStr.replace(/&#39;/g, "'")
    htmlStr = htmlStr.replace(/&amp;/g, '&')
    return htmlStr
}

const IsDataFile = (fileName) => {
    return (
        fileName.includes('.pdf') ||
        fileName.includes('.doc') ||
        fileName.includes('.docx') ||
        fileName.includes('.txt') ||
        fileName.includes('.rtf')
    )
}

Array.prototype.insert = function ( index, ...items ) {
    this.splice( index, 0, ...items );
};

export const FileBlock = ({
    order,
    orgdata,
    searchtext,
    reloadData,
    userProfile,
    onShareClick,
    index,
    total
}) => {
    const decryption = order.lastCheckAnswer
    const timecodes = order.time_code
    const domaindir = orgdata?.database ?? ''
    //const fileStatus = order.lastCheckStatus
    const version = order.version

    const audioQuolity = order.audio_anl_value
    const audioQuolityMessage = order.audio_anl_message
    const audioColor = audioQuolity>=0.96 ? 'green' : (audioQuolity >= 0.9 ? 'orange' : 'red')
    
    let speakersData = order?.speakersData
        ? JSON.parse(order?.speakersData ?? '')
        : {}

    const { language } = React.useContext(TranslateContext)
    const { setNotify } = React.useContext(NotifyContext)
    const {
        downloadDocsAPI,
        removeFileAPI,
        translateFileAPI,
        updateDecryptionAPI,
        updateProtocolAPI,
        updateSpeakersAPI,
        createSpeakerAPI,
        removeSpeakerAPI,
        updateLeadsAPI,
        generateProtocolAPI,
        generateAnaliticAPI,
        publishOrderAPI,
        updateOrderTitleAPI,
    } = useFileUploader()
    const { userId } = useProfile()

    const [isOpen, setIsOpen] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('Decryption')
    const [tabs, setTabs] = React.useState([])
    const [speakersModal, setSpeakersModal] = React.useState(false)
    const [edittitle, SetEditTitle] = React.useState(false)
    const [protoChanged, SetProtoChanged] = React.useState(false)
    
    const [title, SetTitle] = React.useState('')
    const [protocolStatus, setProtocolStatus] = React.useState(
        order.protocolStatus
    )
    const [analiticStatus, setAnaliticStatus] = React.useState(
        order.analiticStatus
    )
    const [modalType, setModalType] = React.useState(null)
    const [isSaveLocked, setIsSaveLocked] = React.useState({
        Decryption: true,
        Protocol: true,
    })
    const [fileStatus, setFileStatus] = React.useState(order.lastCheckStatus)
    const [published, setPublished] = React.useState(order?.public ?? false)

    const [decryptionList, setDecryptionList] = React.useState({})
    const [decryptionList2, setDecryptionList2] = React.useState({})

    const [protocolList, setProtocolList] = React.useState({})
    const [protocolError, setParsedProtocolError] = React.useState(false)
    const [speakers, setSpeakers] = React.useState([])

    const [tbottom, settbottom] = React.useState(false)
    const [tbottom2, settbottom2] = React.useState(false)
    const [tbottom3, settbottom3] = React.useState(false)
    const [speakerHasMessages, setSpeakersHasMessages] = React.useState(false)
    const [speakerDeleteName, setSpeakerDeleteConfirm] = React.useState('')
    const [newSpaekerAwait, SetNewSpeakerAwait] = React.useState(false)
    const [editSPeakerName, setEditSpeakerName] = React.useState('')
    const [updatedText, SetUpdatedTextMode] = React.useState("")

    const [speakerOpenPanel, setSpeakerOpenPanel] = React.useState(false)
    const [fullscreen, setFullscreen] = React.useState(false)
    const btnRef = React.useRef(null)

    var player = React.createRef()

    const styles = useSpring({
        from: {
          opacity: 0,
          y: `${((index / total) * 100)}%`,
        },
        to: {
          opacity: 1,
          y: '0%',
        },
        config: { duration: 500 },
      })

    const stylesForTabs = useSpring({
        from: {
          opacity: 0,
          y: `-${((index / total) * 100)}%`,
        },
        to: {
          opacity: 1,
          y: '0%',
        },
      })

    const { RemoveConsolidation } = useUsers({
        onDone: (type, data) => {
            if (type == 'removedConsolidation' && data == true) {
                setNotify({
                    message: language['ConsolidationRemoved'],
                    type: 'success',
                })
                reloadData()
            }
        },
    })

    function similar(a,b) {
        var equivalency = 0;
        var minLength = (a.length > b.length) ? b.length : a.length;    
        var maxLength = (a.length < b.length) ? b.length : a.length;    
        for(var i = 0; i < minLength; i++) {
            if(a[i] == b[i]) {
                equivalency++;
            }
        }
        
    
        var weight = equivalency / maxLength;
        return (weight * 100) + "%";
    }

    const parseDecryption = (decryption, timecodes) => {
        let datafile = IsDataFile(order.file)
        let time_codes =
            timecodes && timecodes != '' ? JSON.parse(timecodes) : []
        let time_codes_original = [...time_codes]

        // регенерация временных кодов
        let mainPointsTemp = time_codes_original?.filter((tc) => tc[1].indexOf(':') > 0) ?? []

        // адаптация временных кодов с учетом переименованных спикеров
        let mainPoints = mainPointsTemp?.map((mp) => {
            let mpSplit = mp[1].split(':')
            let newName = speakersData.find((sp) => sp.initialName == mpSplit[0].trim())?.name?.trim() ?? mpSplit[0].trim()
            mp[1] = newName + ':' + mpSplit[1].trim() 
            return mp
        })

        let time_codes_ext = []

        for (let i = 0; i < mainPoints.length; i++) 
        {
            let elementIndex = time_codes_original.indexOf(mainPoints[i])

            if (i>0 && elementIndex>=0) {
                let temparra = time_codes_original.slice(0, elementIndex)
                let temparr1 = temparra[0]
                let temptext = temparr1[1]
                temparr1[1] = temptext.replace('\r', '').replace('\n', '').replace("\n\n","").replace(/\s/g, '').replace(/<(.|\n)*?>/g, '');
                
                time_codes_original.slice(1, elementIndex).map((value) => {
                    temparr1[1] += value[1].replace('\r', '').replace('\n', '').replace("\n\n","").replace(/\s/g, '').replace(/<(.|\n)*?>/g, '');
                })
                time_codes_ext.push(temparr1)//time_codes_original.slice(0, elementIndex))
                time_codes_original = time_codes_original.slice(elementIndex)
            }
       }
       
       let temparr1 = time_codes_original[0]
       let temparra = time_codes_original.slice(1)

       if (temparr1)
       {
            let text = temparr1 && temparr1[1]
            temparr1[1] = text.replace('\r', '').replace('\n', '').replace("\n\n","").replace(/\s/g, '').replace(/<(.|\n)*?>/g, '');

            temparra.map((value) => {
                temparr1[1] += value[1].replace('\r', '').replace('\n', '').replace("\n\n","").replace(/\s/g, '').replace(/<(.|\n)*?>/g, '');
            })
       }

       time_codes_ext.push(temparr1)
       
       // восстанавливаю переменную
       time_codes_original = [...time_codes]

        if (datafile) {
            // если это файл данных, то просто разбивка на параграфы
            const parsedDecryption3 = decryption
                .split('\n\n')
                .reduce((acc, value, idx) => {
                    if (!value) return acc
                    return {
                        ...acc,
                        [idx]: { speaker: '', speakerTime: '', text: value },
                    }
                }, {})

            setDecryptionList(parsedDecryption3)
        }

        let items = decryption.split(version == 0 ? '\n\n' : '\r')

        const parsedDecryption =
            !datafile &&
            items.reduce((acc, value, idx) => {
                if (!value) return acc

                let valueCompressed = value.replace('\r', '').replace('\n', '').replace("\n\n","").replace(/\s/g, '').replace(/<(.|\n)*?>/g, '');

                var timeInSeconds = null
                var timecode = undefined
                let lastTime = 0
                let delay = 0

                let remmodeIndex = -1
                //timecode = time_codes_ext[idx]
                timecode = time_codes_ext?.find((tc) => tc && tc[1].indexOf(valueCompressed)>=0) ?? undefined
    
                var date = null

                if (timecode) {
                    timeInSeconds = timecode[0]
                    var h = Math.floor(timeInSeconds / 3600)
                        .toString()
                        .padStart(2, '0')
                    var secondsO = timeInSeconds - h * 3600
                    var m = Math.floor(secondsO / 60)
                        .toString()
                        .padStart(2, '0')
                    var s = Math.floor(secondsO % 60)
                        .toString()
                        .padStart(2, '0')
                    date = new Date(`2023-01-01T${h}:${m}:${s}`)
                    //time_codes.splice(time_codes.indexOf(timecode), 1)
                }

                let fullText = value
                value = value.split(':')
                let speakerName = value[0]?.trim()
                let paragraph = fullText
                    .replace(speakerName, '')
                    .trim()
                    .replace(':', '', 1)

                let data = paragraph
                //.split("\n").reduce((alldata, p)=>{
                //return alldata + (p.indexOf("<p>")<0 && p.indexOf("</p>")<9 ? `${p}<br>` : ``)
                //}, "")

                return {
                    ...acc,
                    [idx]: {
                        speaker: speakerName,
                        speakerTime: date,
                        text: data,
                    },
                }
            }, {})

        const parsedDecryption2 = decryption
            ?.split(version == 0 ? '\n\n' : '\r')
            ?.reduce((acc, value, idx) => {
                if (!value) return acc
                var value2 = value.replace(/(^\n\n)/gi, '')
                return { ...acc, [idx]: value2 }
            }, {}) ?? {}

        if (!datafile) {
            setDecryptionList(parsedDecryption)
        }

        setDecryptionList2(parsedDecryption2)
    }

    const DeleteParagraph = (startrowindex, paragraph) => {
        let text = paragraph.text

        let items1 = Object.entries(decryptionList).filter((item, index) => {
            if (parseInt(item[0]) != parseInt(startrowindex)) return item
        })

        setDecryptionList(Object.fromEntries(items1))

        let items2 = Object.entries(decryptionList2).filter((item, index) => {
            if (parseInt(item[0]) != parseInt(startrowindex)) return item
        })

        setDecryptionList2(Object.fromEntries(items2))
    }

    const AddEmptyParagraph = (startrowindex, operation) => {
        let newItem = { speaker: '', speakerTime: new Date(), text: '' }

        let newItemsList = {}
        let newItemsList2 = {}
        let items = []
        let rowIndex = 0
        let previtem = null

        let allParagraphs = Object.entries(decryptionList)

        allParagraphs.map((item) => {
            if (
                operation == 'after' &&
                rowIndex == parseInt(startrowindex) + 1
            ) {
                newItem = {
                    speaker: previtem[1].speaker,
                    speakerTime: previtem[1].speakerTime,
                    text: '',
                }
                items.push([rowIndex, newItem])
                rowIndex++
                items.push([rowIndex, item[1]])
            } else if (
                operation == 'before' &&
                rowIndex == parseInt(startrowindex)
            ) {
                newItem = { speaker: '', speakerTime: '', text: '' }
                items.push([rowIndex, newItem])
                rowIndex++
                items.push([rowIndex, item[1]])
            } else {
                items.push([rowIndex, item[1]])
            }

            let timecodesArray = JSON.parse(timecodes)
            let newtimecodes = [
                ...timecodesArray.slice(0, rowIndex - 1),
                [0, "", 0],
                ...timecodesArray.slice(rowIndex),
            ]

            //console.log(newtimecodes)
            //console.log(timecodes, newtimecodes)
            //timecodes = JSON.stringify(newtimecodes)

            rowIndex++
            previtem = item

        })

        if (startrowindex == allParagraphs.length - 1) {
            items.push([
                allParagraphs.length,
                { speaker: '', speakerTime: '', text: '' },
            ])
        }

        rowIndex = 0
        newItemsList = Object.fromEntries(items)
        items = []

        Object.entries(decryptionList2).map((item) => {
            if (
                operation == 'after' &&
                rowIndex == parseInt(startrowindex) + 1
            ) {
                items.push([rowIndex, ':'])
                rowIndex++
                items.push([rowIndex, previtem[1]])
            } else if (
                operation == 'before' &&
                rowIndex == parseInt(startrowindex)
            ) {
                items.push([rowIndex, ':'])
                rowIndex++
                items.push([rowIndex, item[1]])
            } else {
                items.push([rowIndex, item[1]])
            }
            rowIndex++
            previtem = item
        })

        if (startrowindex == allParagraphs.length - 1) {
            items.push([allParagraphs.length, ':'])
        }

        newItemsList2 = Object.fromEntries(items)

        setDecryptionList(newItemsList)
        setDecryptionList2(newItemsList2)
    }

    const parsSpeakers = (speakers) => {
        if (speakers) {
            const speakersList = JSON.parse(speakers)
            //console.log(speakersList)
            setSpeakers(speakersList)
        }
    }

    const saveNewSpeakerName = (newSpeaker) => {
        const isEqualName = speakers.some(
            (speaker) => speaker.name === newSpeaker.name
        )
        if (isEqualName) {
            setNotify({ message: language['EqualSpeakers'], type: 'warning' })
            return
        }
        updateSpeakersAPI(
            order.id,
            newSpeaker.initialName,
            newSpeaker.name
        ).then(({ updatedSpeakers, updatedDecryption }) => {
            setSpeakers(updatedSpeakers)
            speakersData = updatedSpeakers ?? {}
            parseDecryption(updatedDecryption, timecodes)
        })
    }

    const changeLeadSpeaker = (initialName) => {
        const role =
            speakers.find((s) => s.initialName === initialName).role === 'LEAD'
                ? null
                : 'LEAD'
        updateLeadsAPI(order.id, initialName, role).then((updatedSpeakers) =>
            setSpeakers(updatedSpeakers)
        )
    }

    const addNewSpeaker = () => {
        SetNewSpeakerAwait(true)
        const newSpeaker = {
            name: `НОВЫЙ СПИКЕР ${speakers.length + 1}`,
            initialName: `НОВЫЙ СПИКЕР ${speakers.length + 1}`,
            role: '',
        }
        createSpeakerAPI(
            order.id,
            newSpeaker.initialName,
            newSpeaker.name
        ).then(({ updatedDecryption, updatedSpeakers }) => {
            SetNewSpeakerAwait(false)
            setSpeakers(updatedSpeakers)
            speakersData = updatedSpeakers ?? {}
        })
        //setSpeakers([...speakers, newSpeaker])
        //saveNewSpeakerName(newSpeaker)
    }

    const decryptionChange = (value, key) =>
        setDecryptionList({
            ...decryptionList,
            [key]: { ...decryptionList[key], text: value },
        })

    const speakerChange = (value, key) =>
        setDecryptionList({
            ...decryptionList,
            [key]: { ...decryptionList[key], speaker: value },
        })

    const parseProtocol = (protocol) => {
        setParsedProtocolError(false)
        const isArray = protocol.includes('[') && protocol.includes(']') // some regexp ?
        if (isArray) {
            const protocolArrays = JSON.parse(protocol)
            try 
            {
                let isEng = protocol.includes("Key points") || protocol.includes("Next steps")
                let isRus = protocol.includes("Ключевые моменты") || protocol.includes("Следующие шаги")

                let h1 = isEng ? "Next steps" : "Следующие шаги"
                let h2 = isEng ? "Key points" : "Ключевые моменты"

                const parsedProtocol = protocolArrays.reduce((acc, value) => {
                    if (value && value?.length > 0) {
                        
                        if (value[0].includes('Протокол совещания')) {
                            const secondPart = value?.reduce(
                                (acc, value, idx) => ({
                                    ...acc,
                                    ...(!value.includes(
                                        'Протокол совещания'
                                    ) && { [idx]: value }),
                                }),
                                {}
                            )
                            return { ...acc, FIRST_PART: secondPart }
                        } else if (value[0].includes(h1)) {
                            const secondPart = value?.reduce(
                                (acc, value, idx) => ({
                                    ...acc,
                                    ...(!value.includes(h1) && {
                                        [idx]: value,
                                    }),
                                }),
                                {}
                            )
                            return { ...acc, SECOND_PART: secondPart }
                        } 

                        if (value[0].includes(h2)) {
                            const secondPart = value?.reduce(
                                (acc, value, idx) => ({
                                    ...acc,
                                    ...(!value.includes(
                                        'Протокол совещания'
                                    ) && { [idx]: value }),
                                }),
                                {}
                            )
                            return { ...acc, FIRST_PART: secondPart }
                        } else {
                            const firstPart = value?.reduce(
                                (acc, value, idx) => ({
                                    ...acc,
                                    ...(!value.includes(h2) && {
                                        [idx]: value,
                                    }),
                                }),
                                {}
                            )
                            return { ...acc, SECOND_PART: firstPart }
                        }
                    } else 
                    {
                        return acc
                    }
                }, {})

                if (!parsedProtocol)
                {
                    setParsedProtocolError(true)
                } else 
                {
                    setProtocolList(parsedProtocol)
                }
                
            } catch (e) {
                
                setParsedProtocolError(true)
            }
            return
        }

        let lastTitle = 'FIRST_PART'
        const parsedProtocol =
            protocol.length &&
            protocol
                .split('\n')
                .filter((e) => !!e)
                .map((e) => e?.trim())
                .reduce((acc, value, idx) => {
                    if (value.includes('Следующие шаги') || value.includes('Next steps')) {
                        lastTitle = 'SECOND_PART'
                        return { ...acc }
                    }

                    return {
                        ...acc,
                        [lastTitle]: { ...acc[lastTitle], [idx]: value },
                    }
                }, {})

        if (!parsedProtocol)
        {

            setParsedProtocolError(true)
        } else 
        {
            setProtocolList(parsedProtocol)
        }
        
    }
    const protocolChange = (value, key, part) =>
    {
        setProtocolList({
            ...protocolList,
            [part]: { ...protocolList[part], [key]: value },
        })
    }

    const deleteProtocolParagraph = (key, part) => {
        try {
            const withoutParagraph = Object.entries(protocolList[part]).filter(
                (value) => value[0] !== key
            )
            setProtocolList({
                ...protocolList,
                [part]: Object.fromEntries(withoutParagraph),
            })

        } catch (e) {
            console.log(e)
            alert(e)
        }
    }
    const addProtocolParagraph = (title, key) => {

        try {
            let withNewParagraph = []

            if (!protocolList[title]) {
                //withNewParagraph = [{ title: { [0]: '' } }, [Date.now(), '']]
                //protocolList[title] = { [0]: '' }
                withNewParagraph = [
                    ...Object.entries(protocolList[title] ?? {}),
                    [Date.now(), ''],
                ]
            } else {

                if (key>=0) {
                    let arr1 = Object.entries(protocolList[title]).slice(0, parseInt(key)+1)
                    let arr2 = Object.entries(protocolList[title]).slice(parseInt(key)+1)
                    let index = 0

                    arr1.map((value) => {
                        withNewParagraph.push([index.toString(), value[1]])
                        index++
                    })

                    withNewParagraph.push([index.toString(), ""])
                    index++

                    arr2.map((value) => {
                        withNewParagraph.push([index.toString(), value[1]])
                        index++
                    })

                } else
                {
                    withNewParagraph = [
                        ...Object.entries(protocolList[title]),
                        [Date.now(), ''],
                    ]
                }
            }

            let obj2 = {
                ...protocolList,
                [title]: Object.fromEntries(withNewParagraph),
            }

            setProtocolList(obj2)
            
        } catch (e) {
            console.log(e)
            alert(e)
        }
    }

    const tabToString = () => {
        const protocolToArray = () =>
        {
            console.log(protocolList)
            return Object.entries(protocolList).reduce((acc, [title, paragraph]) => {
                if (title === 'FIRST_PART') {
                    const firstPart = Object.entries(paragraph).reduce(
                        (acc, [_, text]) => [...acc, text],
                        []
                    )
                    //return ([...acc, ['***Ключевые моменты***', ...firstPart]])
                    return [...acc, [...firstPart]]
                }
                const secondPart = Object.entries(paragraph).reduce(
                    (acc, [_, text]) => [...acc, text],
                    []
                )
                //return ([...acc, ['***Следующие шаги***', ...secondPart]])
                return [...acc, [...secondPart]]
            }, [])
        }
        const decryptionToString = () =>
            Object.entries(decryptionList)
                .map(([_, value]) => [
                    value.speaker?.trim(),
                    value.text?.trim(),
                ])
                .reduce(
                    (acc, value) =>
                        acc +
                        value[0] +
                        ' : ' +
                        value[1] +
                        (version == 0 ? '\n\n' : '\r'),
                    ''
                )

        const analiticToString = () => 
        {
            let arr = Object.entries(JSON.parse(order.analitic)).map(([key, value]) => {
                let text = ""
                if (key == "success") text += "Успешность\n\n";
                else if (key == "communication") text += "Коммуникативность\n\n";
                else text += key.trim() + ` заинтересованность: ${value[["interest"]]}` + "\n\n"
                if (value["sentiment"]) text += ` Настроение: \n\n ${value["sentiment"]}` + "\n\n"
                if (value["emotion"]) text += ` Эмоции: \n\n ${value["emotion"]}` + "\n\n"
                if (value["toxicity"]) text += ` Токсичность: \n\n ${value["toxicity"]}` + "\n\n"
                if (value["misunderstanding"]) text += ` Недопонимание: \n\n ${value["misunderstanding"]}` + "\n\n"
                else text += value + "\n\n"
                return text
            })

            
            return arr.join("\r")
        }

        return activeTab === 'Decryption'
            ? decryptionToString()
            : activeTab === 'Meeting Analytics' ? analiticToString() : JSON.stringify(protocolToArray())
    }

    function removeTags(str) {
        if (str === null || str === '') return false
        else str = str.toString()

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/gi, '')
    }

    async function copyToClipboard(textToCopy) {
        // Navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textToCopy)
            setNotify({ message: language['Copied'], type: 'success' })
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement('textarea')
            textArea.value = textToCopy

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = 'absolute'
            textArea.style.left = '-999999px'

            document.body.prepend(textArea)
            textArea.select()

            try {
                document.execCommand('copy')
                setNotify({ message: language['Copied'], type: 'success' })
            } catch (error) {
                console.error(error)
            } finally {
                textArea.remove()
            }
        }
    }

    const copyDocument = () => {
        const string =
            activeTab === 'Decryption'
                ? tabToString()
                : activeTab === 'Meeting Analytics' ? tabToString() : JSON.parse(tabToString())
                      .flat()
                      .reduce((acc, val) => acc + val + '\n', '')

        copyToClipboard(removeTags(string))
    }

    const downloadFromBase64 = (base64String, fileName, format) => {
        const currentFormat = { DOCX: 'docx', PDF: 'pdf', XLSX: 'xlsx' }[format]
        const newFileName = `${fileName.split('.')[0]}.${currentFormat}`

        const byteString = atob(base64String)
        const arrayBuffer = new ArrayBuffer(byteString.length)
        const int8Array = new Uint8Array(arrayBuffer)
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i)
        }

        const blob = new Blob([int8Array], {
            type: `application/${currentFormat}`,
        })

        const a = document.createElement('a')
        document.body.appendChild(a)
        const url = URL.createObjectURL(blob)
        a.href = url
        a.download = newFileName
        a.click()

        setTimeout(() => {
            URL.revokeObjectURL(url)
            document.body.removeChild(a)
        }, 0)
    }

    const downloadDocs = (format) => {
        downloadDocsAPI({
            orderId: order.id,
            field:
                activeTab === 'Decryption'
                    ? 'LAST_CHECK_ANSWER'
                    : 'PROTOCOL_ANSWER',
            format: format,
        }).then(({ data }) => {
            downloadFromBase64(
                data.downloadOrderFile.b64string,
                data.downloadOrderFile.filename,
                format
            )
        })
    }
    const removeFile = () =>
        removeFileAPI({ orderId: order.id, filename: order.file }).then(
            reloadData
        )

    const UpdateTitle = () => {
        updateOrderTitleAPI(order.id, title).then(() => {
            setNotify({ message: language['Saved'], type: 'success' })
            reloadData()
        })
    }
    const startTranslationFile = (speakers, autoprotocol, resumeType, decriptLang) => {
        return translateFileAPI(order.file, order.id, speakers, autoprotocol, resumeType, decriptLang)
    }
    const saveChanges = () => {
        //if (isSaveLocked[activeTab]) return


        const newString = tabToString()
        activeTab === 'Decryption'
            ? updateDecryptionAPI(order.id, newString)
                  .then((newDecryption) =>
                      parseDecryption(newDecryption, timecodes)
                  )
                  .then(() =>
                      setNotify({ message: language['Saved'], type: 'success' })
                  )
            : updateProtocolAPI(order.id, newString)
                  .then((newProtocol) => parseProtocol(newProtocol))
                  .then(() =>
                      setNotify({ message: language['Saved'], type: 'success' })
                  )

        setIsSaveLocked({ ...isSaveLocked, [activeTab]: true })

        //let event = new Event("closeditor");
        //document.dispatchEvent(event);
    }
    const getProtocol = (resumeType) => {
        generateProtocolAPI(order.id, resumeType).then(() =>
            {
                setNotify({ message: language['GetProtocol'], type: 'success' })
                reloadData()
            }
        )
        setProtocolStatus('PENDING')
    }

    const getAnalitic = () => {
        generateAnaliticAPI(order.id).then(() =>
            setNotify({ message: language['GetAnalitic'], type: 'success' })
        )
        setAnaliticStatus('PENDING')
    }

    const TogglePublished = (orderId, newPaublished) => {
        publishOrderAPI(orderId, newPaublished)
            .then(() => {
                setPublished(newPaublished)
                setNotify({
                    message: newPaublished
                        ? 'Расшифровка опубликована'
                        : 'Расшифровка снята с публикации',
                    type: 'success',
                })
            })
            .catch((err) => setNotify({ message: err, type: 'error' }))
    }

    const onDeleteSpeaker = (speaker) => {
        var itemsWithSpeaker = Object.entries(decryptionList).filter(
            (item) => item[1].speaker == speaker.name
        )

        if (itemsWithSpeaker.length > 0) {
            setSpeakersHasMessages(true)
        } else {
            setSpeakerDeleteConfirm(speaker.name)
        }
    }

    const HightLightFileText = (text, substr) => {
        return substr != ''
            ? text
                  .toUpperCase()
                  .replace(
                      substr.toUpperCase(),
                      `<span style="color:red;">${substr.toUpperCase()}</span>`
                  )
            : text
    }

    const DownloadFile = (data, filename) => {
        const jsonData = JSON.stringify(data)
        const link = document.createElement('a')

        link.setAttribute(
            'href',
            'data:text/plain;charset=utf-8,' + encodeURIComponent(jsonData)
        )
        link.setAttribute('download', filename || 'data.json')
        link.style.display = 'none'

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
    }

    const OnCreateResume = (resumeType) => {
        getProtocol(resumeType)

        let readed = JSON.parse(localStorage.getItem('readed'))?.filter(r=>r.toString() != order.id) ?? []
        localStorage.setItem('readed', JSON.stringify(readed))
    }

    React.useEffect(() => {
        setProtocolStatus(order.protocolStatus)
        setAnaliticStatus(order.analiticStatus)
        parseDecryption(order.lastCheckAnswer, timecodes)

        order.protocolAnswer && parseProtocol(order.protocolAnswer)

        let tabsList = ['Decryption']
        if (location.host != 'i.timelist.ru') {
            if (order.protocolAnswer) { tabsList.push('Protocol') }
            if (order.analitic) { tabsList.push('Meeting Analytics') }

            if (location.host === 'debug.timelist.ru') {
                tabsList.push('Detocs')
            }

            setTabs(
                tabsList
            )
            
        } else {
            
            let tabs = order.protocolAnswer
                    ? ['Decryption', 'Protocol']
                    : ['Decryption']
        
            setTabs(tabs)
            
        }
        parsSpeakers(order.speakersData)
    }, [order])

    React.useEffect(() => {
        if (order.lastCheckStatus === 'NEW_FILE') {
            setIsOpen(true)
            setModalType('newFile')
        }
        if (order.lastCheckStatus === 'NOT_PAID') {
            setModalType('lowBalance')
        }

        if (order.lastCheckStatus != fileStatus) {
            setFileStatus(order.lastCheckStatus)
        }
    }, [order.lastCheckStatus])

    useEffect(() => {
        //  setSpeakerOpenPanel(isOpen)
        if (location.host === 'debug.timelist.ru') 
        {
            //throw new Error('debug 111')
        }
    }, [isOpen])

    useEffect(() => {
        if (protoChanged) {
            saveChanges()
            SetProtoChanged(false)
        }
    }, [protocolList])

    return (
        <Row>
            <div className={'col-md-12'}>
                <animated.div  style={{...styles}}>
                <Row>
                    <Col
                        className={
                            isOpen && window.innerWidth > 766 && !IsDataFile(order.file)
                                ? 'col-md-3'
                                : 'd-none'
                        }
                    >

                        {order?.lastCheckAnswer && order?.lastCheckAnswer != "" ?
                        <>
                            {window.innerWidth > 766 && isOpen && !IsDataFile(order.file) && (
                                <SpeakersPanel
                                    onStartEditing = {setEditSpeakerName}
                                    editSpeakerName={editSPeakerName}
                                    speakers={speakers}
                                    newSpaekerAwait={newSpaekerAwait}
                                    saveNewSpeakerName={saveNewSpeakerName}
                                    getProtocol={getProtocol}
                                    changeLeadSpeaker={changeLeadSpeaker}
                                    addNewSpeaker={addNewSpeaker}
                                    protocolStatus={protocolStatus}
                                    onDeleteSpeaker={onDeleteSpeaker}
                                    getAnalitic={getAnalitic}
                                    analiticStatus={analiticStatus}
                                    onUpdatedTextChange={(mode)=>{
                                        SetUpdatedTextMode(mode)
                                    }}
                                />
                            )}
                        </> : <>
                            <div className='text-center alert alert-warning'>
                                Расшифруйте файл чтобы получить аналитику или протокол...
                            </div>
                        </>}
                    </Col>

                    <Col
                        className={
                            isOpen && window.innerWidth > 766 && !IsDataFile(order.file)
                                ? 'col-md-9'
                                : 'col-md-12'
                        }
                    >
                        <div
                            className={'card'}
                            style={
                                fullscreen
                                    ? {
                                          position: 'fixed',
                                          top: 0,
                                          left: 0,
                                          right: 0,
                                          bottom: 0,
                                          width: '100%',
                                          height: '100%',
                                          zIndex: 10000,
                                      }
                                    : null
                            }
                        >
                            <div className="card-header d-block d-md-flex justify-content-between align-items-center">
                                <span
                                    className="text-dark  text-uppercase d-flex justify-content-start align-items-center"
                                    style={{ fontWeight: 'bold', fontSize: 16 }}
                                >
                                    {(order.protocolStatus == "PENDING") && (
                                       <div className={'readyIndicator me-2'} />
                                    )}

                                    {(order.lastCheckStatus == "STARTED" || order.lastCheckStatus == "SENT" || order.lastCheckStatus == "PENDING") && (
                                       <div className={'readyIndicator me-2'} />
                                    )}

                                    {(order.lastCheckStatus == "SUCCESS" || order.protocolStatus == "SUCCESS")  && (
                                       <div className={ !(JSON.parse(localStorage.getItem('readed')) ?? []).includes(order.id) ? 'readyIndicatorStatic me-2' : 'd-none'} />
                                    )}


                                    {!edittitle &&
                                    userProfile?.id == order.owner?.id ? (
                                        <button
                                            className="btn btn-sm me-2 "
                                            onClick={() => {
                                                SetTitle(
                                                    order.title != ''
                                                        ? order.title
                                                        : order.file
                                                )
                                                SetEditTitle(true)
                                            }}
                                        >
                                            <img
                                                src={require('../../assets/images/edit-save.png')}
                                                alt=""
                                                style={{
                                                    height: 40,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </button>
                                    ) : null}

                                    <div className="d-flex align-items-center justify-content-start">
                                        {edittitle ? (
                                            <div className="d-flex align-items-center justify-content-start mt-2 mb-1">
                                                <input
                                                    className="form-control w-100"
                                                    type="text"
                                                    value={title}
                                                    onChange={(e) => {
                                                        SetTitle(e.target.value)
                                                    }}
                                                />

                                                <button
                                                    className="btn"
                                                    onClick={() => {
                                                        UpdateTitle()
                                                        SetEditTitle(false)
                                                    }}
                                                >
                                                    <b>Сохранить</b>
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                className="me-2"
                                                style={{
                                                    overflowWrap: 'break-word',
                                                    wordBreak: 'break-all',
                                                }}
                                            >
                                                <span dangerouslySetInnerHTML = {{__html: HightLightFileText(order.title && order.title.trim() != '' ? order.title : order.file, searchtext)}} />

                                                {userProfile?.id !=
                                                order.owner?.id ? (
                                                    <div>
                                                        <small
                                                            style={{
                                                                textTransform:
                                                                    'lowercase',
                                                                color: 'gray',
                                                            }}
                                                        >
                                                            Владелец файла:{' '}
                                                            {order.owner?.f}{' '}
                                                            {order.owner?.i}{' '}
                                                            {order.owner?.o}
                                                        </small>
                                                    </div>
                                                ) : null}
                                            </div>
                                        )}
                                    </div>
                                </span>

                                <div className="mt-3 d-flex justify-content-between align-items-center mt-md-0 justify-content-md-end">
                                    <div className='d-flex'>
                                        {IsDataFile(order.file) ? (
                                            <span>
                                                <a
                                                    href={
                                                        domaindir
                                                            ? `https://static.timelist.ru/${domaindir}/${order.owner?.id}/${order.file}`
                                                            : `https://static.timelist.ru/${order.owner?.id}/${order.file}`
                                                    }
                                                    id={`TooltipDownload_${order.id}`}
                                                    className="btn btn-outline-secondary me-2"
                                                    target="_blank"
                                                    download
                                                >
                                                    <i
                                                        style={{ width: 15 }}
                                                        className={
                                                            'fas fa-download text-muted'
                                                        }
                                                    />
                                                </a>

                                                <Tooltip
                                                    placement="bottom"
                                                    isOpen={tbottom3}
                                                    target={`TooltipDownload_${order.id}`}
                                                    toggle={() => {
                                                        settbottom3(!tbottom3)
                                                    }}
                                                >
                                                    {'Скачать файл.'}
                                                </Tooltip>
                                            </span>
                                        ) : null}

                                        {(userProfile?.id == order.owner?.id &&
                                            orgdata &&
                                            published) ||
                                        (userProfile?.id == order.owner?.id &&
                                            !orgdata) ? (
                                            <>
                                                <button
                                                    id={`TooltipBottom3_${order.id}`}
                                                    className="btn btn-outline-secondary me-2"
                                                    onClick={() => {
                                                        let link = orgdata
                                                            ? `https://api.timelist.ru/transcript?id=${order.id}&orgid=${orgdata?.id}`
                                                            : `https://api.timelist.ru/transcript?id=${order.id}`
                                                        setNotify({
                                                            message:
                                                                link +
                                                                ' отправлена в буфер обмена.',
                                                            type: 'success',
                                                        })
                                                        copyToClipboard(link)
                                                    }}
                                                >
                                                    <i
                                                        style={{ width: 15 }}
                                                        className={
                                                            'fas fa-link text-muted'
                                                        }
                                                    />
                                                </button>


                                                <Tooltip
                                                    placement="bottom"
                                                    isOpen={tbottom3}
                                                    target={`TooltipBottom3_${order.id}`}
                                                    toggle={() => {
                                                        settbottom3(!tbottom3)
                                                    }}
                                                >
                                                    {
                                                        'Скопировать публичную ссылку в буфер.'
                                                    }
                                                </Tooltip>
                                            </>
                                        ) : null}

                                        {orgdata &&
                                        userProfile?.id == order.owner?.id ? (
                                            <>
                                                <button
                                                    id={`TooltipBottom_${order.id}`}
                                                    className="btn btn-outline-secondary me-2"
                                                    onClick={() =>
                                                        TogglePublished(
                                                            order.id,
                                                            !published
                                                        )
                                                    }
                                                >
                                                    <i
                                                        style={{ width: 15 }}
                                                        className={
                                                            published
                                                                ? 'fas fa-eye text-muted'
                                                                : 'fas fa-eye-slash text-muted'
                                                        }
                                                    />
                                                </button>

                                                <Tooltip
                                                    placement="bottom"
                                                    isOpen={tbottom}
                                                    target={`TooltipBottom_${order.id}`}
                                                    toggle={() => {
                                                        settbottom(!tbottom)
                                                    }}
                                                >
                                                    {published
                                                        ? 'Сделать приватным'
                                                        : 'Сделать публичным'}
                                                </Tooltip>

                                                <button
                                                    id={`TooltipBottom2_${order.id}`}
                                                    className="btn btn-outline-secondary me-4"
                                                    onClick={() =>
                                                        onShareClick(order)
                                                    }
                                                >
                                                    <i className="fa fa-share-alt text-muted" />
                                                </button>

                                                <Tooltip
                                                    placement="bottom"
                                                    isOpen={tbottom2}
                                                    target={`TooltipBottom2_${order.id}`}
                                                    toggle={() => {
                                                        settbottom2(!tbottom2)
                                                    }}
                                                >
                                                    Передать другим
                                                    пользователям
                                                </Tooltip>
                                            </>
                                        ) : null}
                                    </div>

                                    <div className='d-flex'>
                                        {!!order.uploadId && (
                                            <button
                                                title={'Проблема с расшировкой'}
                                                className={`btn btn-outline-secondary me-2`}
                                                style={{
                                                    padding: '8px',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                }}
                                                onClick={() =>
                                                    setModalType(
                                                        'supportInformation'
                                                    )
                                                }
                                            >
                                                <i className="bx bx-question-mark" />
                                            </button>
                                        )}

                                        <button
                                            title={'На полный экран'}
                                            id={`fullscreenbtn_${userProfile?.id}`}
                                            className="btn btn-outline-secondary me-2"
                                            onClick={() => {
                                                setFullscreen(!fullscreen)
                                                setIsOpen(!fullscreen)
                                            }}
                                        >
                                            <i
                                                class={
                                                    fullscreen
                                                        ? 'fa fa-compress'
                                                        : 'fa fa-expand'
                                                }
                                            ></i>
                                        </button>

                                        {!fullscreen  ? (
                                            <button
                                                title={
                                                    !isOpen
                                                        ? 'Развернуть карточку'
                                                        : 'Свернуть карточку'
                                                }
                                                className="btn btn-outline-secondary"
                                                style={{border:"3px solid #333336"}}
                                                onClick={() =>
                                                {
                                                    setIsOpen(!isOpen)
                                                    if (!isOpen) {
                                                        let readed = JSON.parse(localStorage.getItem('readed')) ?? []
                                                        if (!readed.includes(order.id))
                                                        {
                                                            readed.push(order.id)
                                                            localStorage.setItem('readed', JSON.stringify(readed))
                                                        }
                                                    }
                                                }}
                                            >
                                                {isOpen ? (
                                                    <i className="fa fa-chevron-up  " style={{color:"#333336"}} />
                                                ) : (
                                                    <i className="fa fa-chevron-down " style={{color:"#333336"}} />
                                                )}
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-3">
                                {!fullscreen ? (
                                    <div className="row">
                                        {orgdata &&
                                        userProfile?.id == order.owner?.id ? (
                                            <></>
                                        ) : (
                                            <div className="col-auto">
                                                {userProfile?.id ==
                                                order.owner?.id ? (
                                                    <button className="btn btn-outline-secondary">
                                                        <i className="fas fa-music text-muted" />
                                                    </button>
                                                ) : (
                                                    <div className="d-flex align-items-center justify-content-start">
                                                        <span
                                                            className="me-2"
                                                            style={{
                                                                padding:
                                                                    '7px 13px',
                                                                border: '1px solid gray',
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            <i className="fas fa-share text-muted" />
                                                        </span>

                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            id={`TooltipBottom3_${order.id}`}
                                                            onClick={() => {
                                                                // удалене закрепа
                                                                RemoveConsolidation(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                orderId:
                                                                                    order.id,
                                                                                userIds:
                                                                                    [
                                                                                        userProfile?.id,
                                                                                    ],
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <i className="fas fa-backspace text-muted" />
                                                        </button>

                                                        <Tooltip
                                                            placement="bottom"
                                                            isOpen={tbottom2}
                                                            target={`TooltipBottom3_${order.id}`}
                                                            toggle={() => {
                                                                settbottom2(
                                                                    !tbottom2
                                                                )
                                                            }}
                                                        >
                                                            Удалить ссылку на
                                                            чужую расшифровку.
                                                        </Tooltip>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div className="col-auto"></div>

                                        <div className="col-12">
                                            {fileStatus === 'SUCCESS' ||
                                            fileStatus === 'NEW_FILE' ? (
                                                <ExistFilePanel
                                                    fromUser={
                                                        userProfile?.id !=
                                                        order.owner?.id
                                                            ? order.owner?.id
                                                            : null
                                                    }
                                                    canDelete={
                                                        userProfile?.id ==
                                                        order.owner?.id
                                                    }
                                                    downloadDocs={
                                                        activeTab ===
                                                        'Decryption'
                                                            ? downloadDocs
                                                            : null
                                                    }
                                                    deleteFile={() =>
                                                        setModalType(
                                                            'deleteFile'
                                                        )
                                                    }
                                                    copyDocument={copyDocument}
                                                    isOpen={isOpen}
                                                    toggle={() =>
                                                        setIsOpen(!isOpen)
                                                    }
                                                    toggleSpeakersMobile={() =>
                                                        setSpeakersModal(
                                                            !speakersModal
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <NewFilePanel
                                                    fromUser={
                                                        userProfile?.id !=
                                                        order.owner?.id
                                                            ? order.owner?.id
                                                            : null
                                                    }
                                                    canDelete={
                                                        userProfile?.id ==
                                                        order.owner?.id
                                                    }
                                                    deleteFile={() =>
                                                        setModalType(
                                                            'deleteFile'
                                                        )
                                                    }
                                                    fileStatus={fileStatus}
                                                    onHelp={() =>
                                                        setModalType(
                                                            'supportInformation'
                                                        )
                                                    }
                                                    waitingTime={
                                                        order.uploadTime
                                                    }
                                                    startTranslationFile={
                                                        startTranslationFile
                                                    }
                                                    onStartTranslationFile={() =>
                                                        setFileStatus(
                                                            'INPROGRESS'
                                                        )
                                                    }
                                                    onCancelTranslationFile={() =>
                                                        setFileStatus(
                                                            'UPLOADED'
                                                        )
                                                    }
                                                    toggleSpeakersMobile={() =>
                                                        setSpeakersModal(
                                                            !speakersModal
                                                        )
                                                    }
                                                    fileName={order.file}
                                                    filesize={order.filesize}
                                                    protocolStatus={
                                                        protocolStatus
                                                    }
                                                    isIdExist={!!order.uploadId}
                                                    OnCreateResume={
                                                        OnCreateResume
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                ) : null}

                                <animated.div  style={{...stylesForTabs}}>
                                <div
                                    className={
                                        decryption && isOpen ? 'pt-3' : 'd-none'
                                    }
                                >
                                    <Tabs
                                        isNotDocument={
                                            IsDataFile(order.file) == false
                                        }
                                        fullscreen={fullscreen}
                                        OnAddEmptyParagraph={AddEmptyParagraph}
                                        OnDeleteParagraph={DeleteParagraph}
                                        searchtext={searchtext}
                                        analitic={order?.analitic ? JSON.parse(order?.analitic) : ""}
                                        decryptionList={decryptionList}
                                        decryptionList2={decryptionList2}
                                        protocolList={protocolList}
                                        timelist={timecodes}
                                        decryptionChange={decryptionChange}
                                        speakerChange={speakerChange}
                                        protocolChange={protocolChange}
                                        deleteProtocolParagraph={
                                            deleteProtocolParagraph
                                        }
                                        tabs={tabs}
                                        activeTab={activeTab}
                                        setActiveTab={setActiveTab}
                                        addProtocolParagraph={
                                            addProtocolParagraph
                                        }
                                        speakers={speakers}
                                        playAudio={(h, m, s) => {
                                            if (player && player.current) {
                                                //player.current.audio.current.currentTime = s + m * 60 + h * 60 * 60;
                                                //player.current.audio.current.play()
                                                player.current.currentTime =
                                                    s + m * 60 + h * 60 * 60
                                                player.current.play()
                                            }
                                        }}
                                        unlockButton={() => {
                                           SetProtoChanged(true)
                                           //saveChanges()
                                           //setIsSaveLocked({
                                             // ...isSaveLocked,
                                             // [activeTab]: false,
                                           //})
                                        }}
                                        OnSaveData={()=>{
                                           saveChanges()
                                        }}
                                        isDataFile = { order.resumeType == 'doc' }
                                        detox = { updatedText == 'detox'}
                                        normalized = { updatedText == 'normalized'}
                                        simplifed = { updatedText == 'simplifed'}
                                    />
                                    
                                    {/*
                                            <div className="my-3 d-flex justify-content-end">
                                                <button
                                                    ref={btnRef}
                                                    className={`btn ${
                                                        isSaveLocked[activeTab]
                                                            ? 'btn-outline-secondary'
                                                            : 'btn-outline-primary'
                                                    }`}
                                                    onClick={()=> {
                                                        saveChanges()
                                                    }}
                                                >
                                                    {language['Save']}
                                                </button>
                                            </div>
                                    */}
                                    
                                    {/*
                <AudioPlayer
                  src={domaindir ? `https://static.timelist.ru/${domaindir}/${ order.owner?.id }/${ order.file }` : `https://static.timelist.ru/${ userId }/${ order.file }`}
                  ref={player}
                  onPlay={ () => {} }
                /> */}
                                    {!IsDataFile(order.file) ? (
                                        <audio
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                marginTop:20
                                            }}
                                            ref={player}
                                            controls
                                        >
                                            <source
                                                src={
                                                    domaindir
                                                        ? `${config.fileserver.host}/${domaindir}/${order.owner?.id}/${order.file}`
                                                        : `${config.fileserver.host}/${userId}/${order.file}`
                                                }
                                            />
                                        </audio>
                                    ) : null}
                                </div>
                                </animated.div>
                            </div>
                            <div className="card-footer p-3">
                                <div className="col d-block d-md-none">
                                    {!IsDataFile(order.file) && (
                                        <div>{` (${order.minutes} ${language['Min']})`}</div>
                                    )}
                                    <div>{` (${(
                                        (order.filesize ?? 0) /
                                        (1024 * 1024)
                                    ).toFixed(2)} ${
                                        language['FileSize']
                                    })`}
                                    </div>

                                    {!IsDataFile(order.file) && audioQuolityMessage && <div style={{color: audioColor}}>
                                        Качество: {audioQuolityMessage.toLowerCase()}{". "}
                                        {audioQuolity > 0.9 && audioQuolity < 0.96 ? "Возможно некоректное распознавание некоторых фрагментов." : ""}
                                        {audioQuolity < 0.9 ? "Большая вероятность некорректного распознавания и пропуска фрагментов." : ""}
                                    </div>}

                                    <div>
                                        <Moment format="DD.MM.YYYY HH:mm">
                                            {order.createdAt}
                                        </Moment>
                                    </div>
                                </div>
                                <div className="col d-none d-md-block">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div>
                                            {!IsDataFile(order.file) &&
                                                ` Длительность: ${order.minutes} ${language['Min']}, `}
                                            <span>{` ${
                                                language['FileSize']
                                            } : ${(
                                                (order.filesize ?? 0) /
                                                (1024 * 1024)
                                            ).toFixed(2)} Мб.`}</span>

                                            {!IsDataFile(order.file) && audioQuolityMessage && <span style={{marginLeft: 10, color: audioColor}}>
                                                Качество: {audioQuolityMessage.toLowerCase()}{". "}
                                                {audioQuolity > 0.9 && audioQuolity < 0.96 ? "Возможно некоректное распознавание некоторых фрагментов." : ""}
                                                {audioQuolity < 0.9 ? "Большая вероятность некорректного распознавания и пропуска фрагментов." : ""}
                                            </span>}

                                        </div>
                                        <div>
                                            {` ${language['Created']}:`}{' '}
                                            <Moment format="DD.MM.YYYY HH:mm">
                                                {order.createdAt}
                                            </Moment>
                                        </div>
                                    </div>
                                </div>

                                {protocolError && (
                                    <div className="col mt-2">
                                        <div className="text-danger font-size-12">{"Ошибка в документе протокола, обратитесь к администратору!"}</div>
                                    </div>
                                )}      
                            </div>
                        </div>
                    </Col>
                </Row>
                </animated.div>
            </div>

            <Modal
                isOpen={speakersModal}
                toggle={() => setSpeakersModal(!speakersModal)}
                id="exampleModalScrollable"
                centered
            >
                <div className="px-2 py-4">
                    <SpeakersPanel
                        onStartEditing = {setEditSpeakerName}
                        editSpeakerName={editSPeakerName}
                        speakers={speakers}
                        saveNewSpeakerName={saveNewSpeakerName}
                        getProtocol={getProtocol}
                        changeLeadSpeaker={changeLeadSpeaker}
                        addNewSpeaker={addNewSpeaker}
                        protocolStatus={protocolStatus}
                        onDeleteSpeaker={onDeleteSpeaker}
                    />
                    <div
                        className={s.close_speakers_modal}
                        onClick={() => setSpeakersModal(!speakersModal)}
                    >
                        <i className="mdi mdi-close-thick font-size-20 text-white" />
                    </div>
                </div>
            </Modal>

            {!!modalType && (
                <Modal
                    isOpen={!!modalType}
                    toggle={() => setModalType(null)}
                    id="exampleModalScrollable"
                    centered
                >
                    <ModalContent
                        contentType={modalType}
                        onCancel={() => setModalType(null)}
                        deleteFile={() => {
                            removeFile()
                            setModalType(null)
                        }}
                        splitFile={() => setModalType(null)}
                        mergeFile={() => setModalType(null)}
                        idDecryptionAI={order.uploadId}
                    />
                </Modal>
            )}

            <Modal
                isOpen={speakerHasMessages}
                toggle={() => setSpeakersHasMessages(!speakerHasMessages)}
                id="exampleModalScrollable"
                centered
            >
                <div className="px-2 py-4 text-center">
                    <div
                        className={s.close_speakers_modal}
                        onClick={() =>
                            setSpeakersHasMessages(!speakerHasMessages)
                        }
                    >
                        <i className="mdi mdi-close-thick font-size-20 text-white" />
                    </div>

                    <b className="my-2">У этого спикера еще остались реплики</b>
                    <p className="my-2">
                        Сначала нужно все реплики спикера перенести другим
                        спикерам в расшифровке. После этого можно будет удалить
                        спикера.
                    </p>

                    <button
                        className="btn btn-primary mt-3"
                        type="button"
                        onClick={() =>
                            setSpeakersHasMessages(!speakerHasMessages)
                        }
                    >
                        Понятно
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={speakerDeleteName != ''}
                toggle={() => setSpeakerDeleteConfirm('')}
                id="exampleModalScrollable"
                centered
            >
                <div className="px-2 py-4 text-center">
                    <div
                        className={s.close_speakers_modal}
                        onClick={() => setSpeakerDeleteConfirm('')}
                    >
                        <i className="mdi mdi-close-thick font-size-20 text-white" />
                    </div>

                    <b className="my-2">
                        Вы уверены, что хотите удалить этого спикера?
                    </b>

                    <div>
                        <button
                            className="btn btn-primary mt-3"
                            style={{ marginRight: '20px' }}
                            type="button"
                            onClick={() => setSpeakerDeleteConfirm('')}
                        >
                            Отмена
                        </button>

                        <button
                            className="btn btn-outline-primary mt-3"
                            type="button"
                            onClick={() => {
                                removeSpeakerAPI(
                                    order.id,
                                    speakerDeleteName
                                ).then(
                                    ({
                                        updatedDecryption,
                                        updatedSpeakers,
                                    }) => {
                                        console.log(updatedSpeakers)
                                        setSpeakers(updatedSpeakers)
                                    }
                                )
                                setSpeakerDeleteConfirm('')
                            }}
                        >
                            Удалить
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={speakerOpenPanel}
                toggle={() => setSpeakerOpenPanel(false)}
                id="exampleModalScrollable2"
                centered
            >
                <SpeakersPanel
                    onStartEditing = {setEditSpeakerName}
                    editSpeakerName={editSPeakerName}
                    speakers={speakers}
                    saveNewSpeakerName={saveNewSpeakerName}
                    getProtocol={getProtocol}
                    changeLeadSpeaker={changeLeadSpeaker}
                    addNewSpeaker={addNewSpeaker}
                    protocolStatus={protocolStatus}
                    onDeleteSpeaker={onDeleteSpeaker}
                />
            </Modal>
        </Row>
    )
}

export { IsDataFile, Escape, unEscape }
