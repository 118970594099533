import React from 'react';
import Select from 'react-select/creatable';

export const EmailList = ({
                       emailList,
                       selectedEmails,
                       handleEmailSelectChange,
                       handleCreateEmail,
                       handleCheckboxChange,
                       handleEmailRemove,
                       inputValue,
                       setInputValue,
                       emailError,
                       successMessage,
                       setSuccessMessage,
                   }) => {
    return (
        <div className="mb-3 w-100">
            {emailError && (
                <div className="alert alert-danger w-100 mb-3" role="alert">
                    {emailError}
                </div>
            )}
            <Select
                isClearable
                value={selectedEmails.length === 0 ? null : null}
                onChange={handleEmailSelectChange}
                options={emailList}
                onCreateOption={handleCreateEmail}
                onFocus={() => setSuccessMessage('')}
                inputValue={inputValue}
                onInputChange={(newValue) => setInputValue(newValue)}
                placeholder="Введите email"
                formatCreateLabel={(inputValue) => `Добавить email: "${inputValue}"`}
            />

            {emailList.length > 0 && (
                <div className="w-100 mt-2" style={{ maxHeight: '150px', overflowY: 'auto' }}>
                    {emailList.map((email) => (
                        <div
                            key={email.value}
                            className="d-flex align-items-center justify-content-between mb-2 border-bottom py-2"
                        >
                            <input
                                type="checkbox"
                                checked={selectedEmails.some((selected) => selected.value === email.value)}
                                onChange={() => handleCheckboxChange(email)}
                                className="me-2"
                            />
                            <span>{email.label}</span>
                            <button
                                onClick={() => handleEmailRemove(email)}
                                className="bg-transparent border-0 text-danger p-0 ms-auto me-2"
                            >
                                ✖
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {successMessage && (
                <div className="alert alert-success w-100 text-center" role="alert">
                    {successMessage}
                </div>
            )}
        </div>
    );
};
