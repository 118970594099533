import React, {useEffect, useState} from "react";
import {Button, Card, CardBody} from "reactstrap";
import {Speaker} from "./Speaker";
import { TranslateContext } from '../../../providers/Translate';
import { SuccessIcon } from "../../../assets/images/icons";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { AnaliticsPanel } from "./AnaliticsPanel";

import Select, { StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated'
import chroma from 'chroma-js';

import { useUsers } from '../../../Hooks/UserHooks'
import { NotifyContext } from '../../../providers/Notify'

import config from "../../../config";

let hasChanged = false
let lastText = ""

export const Tags = ( { speakers, newSpaekerAwait, saveNewSpeakerName, getProtocol, changeLeadSpeaker, addNewSpeaker, protocolStatus, onDeleteSpeaker, editSpeakerName, onStartEditing, getAnalitic, analiticStatus, onUpdatedTextChange, order, onChange } ) => {
  const { language } = React.useContext(TranslateContext)
  const protocolTitleColor = {
    PENDING: 'text-primary',
    SUCCESS: 'text-success',
    FAILURE: 'text-warning',
  }

  const animatedComponents = makeAnimated()

  const { setNotify } = React.useContext(NotifyContext)

  let selectRef = React.createRef()
  const [selectedMulti3, setselectedMulti3] = useState(null)
  const [usersListForShare, setUsersTags] = React.useState([])
  
  const { error, UserTags, AddTag, RemoveTag, UpdateTag, AssignTagToOrder, RemoveTagFromOrder, UpdateOrderTags } = useUsers({
    onDone: (type, data) => {
       if (type == 'tags') {

        let d = data?.map((d) => {
            return {
              label: d.name, value:d.id, color:d.color, global: false, parent: d.parent
            }
        })

        setUsersTags(d)
            
       }

       if (type == 'addtag') {
        UserTags({variables: {
          withGlobal: true
        }})

        
       }

       if (type == 'removetag') {
        
       }

       if (type == 'updatetag') {
        
       }

       if (type == 'ordertags') {
          
       }
    }
})

  

  function handleMulti3(selectedItems) {
    setselectedMulti3(selectedItems)
    
    UpdateOrderTags({
      variables: {
        orderId: order?.id ?? "",
        tags: selectedItems.map((d) => {
          return d.value
        })
      }
    })

    hasChanged = true
    if (onChange) onChange()
  }

  const handleKeyPress = (e) => {
    
    if (e.key === 'Escape') {
      e.preventDefault()

      e.target.value = ""
      hasChanged = false
      lastText = ""
      selectRef?.current?.blur()
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      
      AddTag({variables:{
        name: e.target.value,
        color: "#87cefa",
        parent: null
      }})
    }
  }

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    multiValue: (styles, { data }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        backgroundColor: color.alpha(0.3).css(),
        border: data.global ? "3px solid " + data.color : ""
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#49423D"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = chroma(data.color);
      return {
        ...styles,
        color: data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? data.color
              : color.alpha(0.3).css()
            : undefined,
        },
        ':before': {
          ...styles[':before'],
          color: data.color,
          content: data.global ? '"\\1F310"' : '""',
        }
        
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      ':hover': {
        backgroundColor: data.color,
        color: 'white',
      },
    }),
  };

  useEffect(() => {
    if (error != "")
    {
      setNotify({
        message: error,
        type: 'danger',
      })
    }
  }, [error])

  useEffect(() => {
    UserTags({variables: {
      withGlobal: true
    }})

    if (order?.tags?.length>0) {
      
      let d = order?.tags?.map((d) => {
        return {
          label: d.name, value:d.id, color:d.color, global: false, parent: d.parent
        }
      })

      setselectedMulti3(d)
    }
  }, [order])

  useEffect(() => {

    const handleOutlineClick = () => {
       if (hasChanged)
       {
        
        AddTag({variables:{
          name: lastText,
          color: "#87cefa",
          parent: null
        }})

        hasChanged = false
        lastText = ""
       }
    }

   // document.addEventListener('outlineclick', handleOutlineClick)

    return () => {
      document.removeEventListener('outlineclick', handleOutlineClick)
    }

  }, [])

  //

  return (
    <>
       <div className="">
          <div className="templating-select select2-container mainContainer" style={{ width: "100%" }}>
                  <Select
                      value={selectedMulti3}
                      isMulti={true}
                      onBlur={(e)=>{

                        if (e.target.value!="")
                        {
                          AddTag({variables:{
                            name: e.target.value,
                            color: "#87cefa",
                            parent: null
                          }})
                        }
                
                        hasChanged = false
                        lastText = ""
                      }}
                      onFocus={()=>{
                        lastText = ""
                        hasChanged = false
                      }}
                      onChange={handleMulti3}
                      onKeyDown={handleKeyPress}
                      onMenuOpen ={() => {
                        UserTags({variables: {
                          withGlobal: true
                        }})
                      }}
                      options={usersListForShare}
                      styles={colourStyles}
                      classNamePrefix="tags-select"
                      closeMenuOnSelect={false}
                      placeholder="Теги..."
                      ref={selectRef}
                      components={animatedComponents}
                  />
          </div>
      </div>
    </>
  )
}
