import {Card, Col, Form, Row} from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import React, { useEffect,useRef, useState } from "react";
import {TranslateContext} from "../../providers/Translate";
import {NotifyContext} from "../../providers/Notify"
import { formatBytes } from "../../helpers/helpers_functions";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from '@react-spring/web'

import { useLinks } from "../../Hooks/LinksHooks"

import {
  showRightSidebarAction,
  showSearchBarAction,
  showUploaderBarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

export const TeleLinkUploadBlock = ({
  maxFileSize,
  isFilesUploading,
  isFilesConverting,
  sendFiles,
  terminationDate
}) => {
    const dispatch = useDispatch();
    const {language} = React.useContext(TranslateContext)
    const { setNotify } = React.useContext(NotifyContext)

    const [TeleLink, SetTeleLink] = useState("")
    const [TeleLinkName, SetTeleLinkName] = useState("")
    const [TeleLinkDateTime, SetTeleLinkDateTime] = useState("")
    const [TimeZoneOffset, SetTimeZoneOffset] = useState("")
    const [TeleLinkSingle, SetTeleLinkSingle] = useState(false)
    const [VKSType, SeVKSType] = useState(localStorage.getItem("VKSType") ?? "telemost")
    const [showTitle, setShowTitle] = useState(false);
    const [placement, setPlacement] = useState('right');
    const popoverRef = useRef(null);
    const iconRef = useRef(null);

    const { error, AddLink } = useLinks({onDone: (operation, data)=>{
            if (operation == "newlink")
            {
                setNotify({ message: "Новая ссылка добавлена!", type: 'info' })
                SetTeleLink("")
                SetTeleLinkName("")
                SetTeleLinkDateTime(null)
                SetTimeZoneOffset("")
            }

        }})

    const styles = useSpring({
        from: {
            opacity: 0,
            y: '10%',
        },
        to: {
            opacity: 1,
            y: '0%',
        },
    })
    const getTimeZoneOffset = () => {
        const offset = -new Date().getTimezoneOffset()
        const sign = offset >= 0 ? "+" : "-";
        return `${sign}${Math.abs(offset)}`;
    };

    const AddNewLink = () => {
        const offset = getTimeZoneOffset();
        SetTimeZoneOffset(offset);

        const utcDateTime = TeleLinkDateTime
            ? new Date(TeleLinkDateTime + ':00.000Z').toISOString()
            : null;

        AddLink({
            variables: {
                name: TeleLinkName,
                link: TeleLink,
                linkDateTime: utcDateTime != '' ? utcDateTime : null,
                typeVKS: VKSType,
                multi: TeleLinkSingle,
                timezoneOffset: offset
            }
        });
    };

    useEffect(()=>{
        if (error!="")
        {
            setNotify({ message: error, type: 'warning' })
        }
    }, [error])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setPlacement('bottom');
            } else {
                setPlacement('right');
            }
        };
        handleResize();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                iconRef.current &&
                !iconRef.current.contains(event.target)
            ) {
                setShowTitle(false)
            }
        }

        if (showTitle) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [showTitle])

    const ManualText = (
        <>
            <ul>
                <li>Укажите ссылку и время запланированной встречи.</li>
                <li>Пользователь может редактировать или удалять ссылку на запись в разделе "Запланированные встречи" личного кабинета.</li>
                <li>Бот придёт в назначенное время и начнёт запись, когда участников станет больше двух, не считая бота.</li>
                <li>Запись остановится через 2 минуты, если остаётся один участник и Таймлист Бот.</li>
                <li>Таймлист Бот может записать до 3х часов, после чего покинет встречу.</li>
                <li>Запись встречи автоматически появится в разделе "Мои файлы".</li>
                <li>Для автоматической расшифровки встреч необходимо активировать функцию в разделе "Личный кабинет - Настройки профиля", переключателем "Автоматическая расшифровка загружаемых файлов".</li>
                <li>В случае разрыва подключения у участников с ВКС, бот продолжит запись, пока на встрече более одного человека.</li>
            </ul>

            <p style={{ marginBottom: 0 }}><strong>Переключатель "Многоразовая запись по ссылке"</strong></p>
            <ul>
                <li>Используйте для записи регулярных встреч по постоянной ссылке.</li>
                <li>Таймлист Бот подключится к ссылке и будет ожидать участников.</li>
                <li>Все встречи, проведённые по данной ссылке, будут записаны и сохранены в кабинете.</li>
                <li>Бот находится в режиме ожидания участников круглосуточно.</li>
                <li>Запись начинается, как только число участников превышает двух, не считая бота.</li>
                <li>Запись остановится через 2 минуты, если остаётся один участник и Таймлист Бот.</li>
                <li>Таймлист Бот может записать до 3х часов за один сеанс, после чего сразу приступит к новой записи, если встреча не закончилась.</li>
            </ul>
        </>
    );

    const setTimePlusOneMinute = () => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 1);
        const localISOTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000)
            .toISOString()
            .slice(0, 16);
        SetTeleLinkDateTime(localISOTime);
    }

    return (
        <animated.div  style={{...styles, marginBottom:20}}>
            <div className="card"
                 style={{
                     minHeight:380,
                     height:"100%"
                 }}>
                <div className="card-body h-100">
                    <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                        <h5 className="text-center pt-3">
                            {'Вставьте ссылку на видеоконференцию и укажите название'}
                            <i
                                className="bx bx-info-circle fs-8 ms-1"
                                style={{
                                    cursor: 'pointer',
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    color: 'blue'
                                }}
                                ref={iconRef}
                                onClick={() => {
                                    setShowTitle(!showTitle)
                                }}
                            />
                            <span
                                className="position-absolute"
                                style={{
                                    top: '10px',
                                    left: '10px',
                                    padding: '3px 6px',
                                    backgroundColor: '#ffc107',
                                    color: 'black',
                                    borderRadius: '5px',
                                    fontSize: '0.875rem',
                                    fontWeight: 'bold'
                                }}
                            >
                              New
                            </span>
                        </h5>

                        <Popover
                            placement={placement}
                            isOpen={showTitle}
                            innerRef={popoverRef}
                            target={iconRef}
                            toggle={() => setShowTitle(!showTitle)}

                        >
                            <PopoverHeader
                                style={{
                                    backgroundColor: '#d6d6d6',
                                    color: '#000000',
                                }}
                            >Инструкция:</PopoverHeader>
                            <PopoverBody>
                                <p style={{
                                    fontSize: '0.67rem',
                                    backgroundColor: "#fafafa",
                                    color: "#000"
                                }}>{ManualText}</p>
                            </PopoverBody>
                        </Popover>

                        <select
                            className="form-select mb-2"
                            onChange={(e) => {
                                SeVKSType(e.target.value)
                                localStorage.setItem('VKSType', e.target.value)
                            }}>
                            <option selected={VKSType == 'telemost'} value="telemost">Телемост</option>
                            <option selected={VKSType == 'iva'} value="iva">Ива</option>
                            <option selected={VKSType == 'truconf'} value="truconf">Труконф</option>
                            <option selected={VKSType == '1s'} value="1s">1С</option>
                            <option selected={VKSType == 'ktalk'} value="ktalk">Контур.Толк</option>
                            <option selected={VKSType == 'express'} value="express">Express</option>
                        </select>

                        <input
                            type="text"
                            value={TeleLink}
                            className="form-control pe-5 mb-2 d-block w-100"
                            onChange={(e) => SetTeleLink(e.target.value)}
                            placeholder="Ссылка"
                        />
                        <input
                            type="text"
                            value={TeleLinkName}
                            className="form-control pe-5 mb-2 d-block w-100"
                            onChange={(e) => SetTeleLinkName(e.target.value)}
                            placeholder="Название"
                        />

                        <div className="form-check form-switch form-switch-lg" dir="ltr">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="BetaModelChecked"
                                checked={TeleLinkSingle}
                                onClick={() => {
                                    SetTeleLinkSingle(!TeleLinkSingle)
                                }} />
                            <label
                                className="form-check-label"
                                htmlFor="BetaModelChecked"
                            >{"Многоразовая запись по ссылке"}
                            </label>
                        </div>

                        {!TeleLinkSingle &&
                            <div className="position-relative mb-2 d-flex align-items-center">
                                <input
                                    id='dtInput'
                                    type="datetime-local"
                                    value={TeleLinkDateTime}
                                    className="form-control m-0 pe-5 d-block w-100"
                                    onChange={(e) => SetTeleLinkDateTime(e.target.value)}
                                    placeholder="Время и дата (ЧЧ.мм ДД.ММ.ГГ)"
                                />
                                <button
                                    type="button"
                                    className="position-absolute end-0 me-2 border text-dark fw-bold"
                                    onClick={setTimePlusOneMinute}
                                    style={{
                                        height: "60%",
                                        padding: "2px 4px",
                                        fontSize: "12px",
                                        borderRadius: "12px",
                                        backgroundColor: "#f8f9fa",
                                    }}
                                >
                                    NOW
                                </button>
                            </div>
                        }

                        <button type="button" className="btn btn-primary"
                                disabled={TeleLink == "" || TeleLinkName == ""}
                                onClick={() => {
                                    AddNewLink()
                                }}>
                            {"Записать"}
                        </button>
                    </div>
                </div>
            </div>
        </animated.div>
    )
}
