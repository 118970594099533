import React, { useState, useEffect } from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true, error: null, errorInfo: null };
    }
  
    componentDidCatch(error, info) {
      // You can also log the error to an error reporting service
      console.log(error, info);
      
      this.setState({
        error: error,
        errorInfo: info
      })
    }
  
    render() {
      if (this.state?.errorInfo) {
       // Error path
        return (
          <div>
            <h2>Что то пошло не так...</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          </div>
        );
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary