const langdata = {
    "Entrence": "Вход",
    "Welcome" : "Добро пожаловать в Таймлист Лайт!",
    "Phone number": "Номер телефона",
    "Password": "Пароль",
    "Remember me": "Запомнить меня",
    "Log In": "Войти",
    "Register": "Зарегистрироваться",
    "Remember password": "Забыли пароль",
    "Enter Password": "Введите пароль (не менее 8 символов)",
    "From words to deal": "От слов к делу",
    "Login": "Вход",
    "Accept": "Подтвердить",
    "Name": "Имя",
    "Phone": "Телефон",
    "Enter Name": "Введите имя",
    "terms1": "Подтверждаю, что ознакомился с ",
    "terms2": "Политикой конфиденциальности",
    "terms22": "cогласием на обработку персональных данных",
    "terms23": "лицензионным договором",
    "terms3": "и принимаю их условия.",
    "Already registered?": "Уже зарегистрированы?",
    "callawait": "Для подтверждения регистрации в течение 15 минут позвоните с указанного Вами номера по телефону:",
    "RegisterBtn": "Зарегистрироваться",
    "callawait2": "Звонок бесплатный из любой страны. Робот не будет брать трубку, поэтому оператор сотовой связи деньги не спишет.",
    "AwaitingCall": "Ждем звонка",
    "Wrong number": "Указали неверный номер?",
    "Back": "Назад",
    "Success" : "Успешно!",
    "Done": "Готово",
    "RegistartionFinalize": "Для завершения регистрации придумайте пароль.",
    "You are Logged Out": "Вы вышли из аккаунта",
    "Sign In": "Войти",
    "Restore password": "Восстановление пароля",
    "Enter your phone": "Введите номер телефона, под которым Вы зарегистрированы.",
    "Remember it": "Вспомнили пароль",
    "Back": "Назад",
    "Confirm": "Подтвердить",
    "My files": "Мои файлы",
    "My documents" : "Мои документы",
    "Wallet and tariffs": "Кошелёк и тарифы",
    "Feedback": "Техническая поддержка",
    "FAQ": "FAQ",
    "Exit": "Выход",
    "Logout": "Выход",
    "Balance": "Баланс",
    "Minutes": "Минут",
    "Rubles": "Рублей",
    "MyFiles": "Мои файлы",
    "Drop files here or click to upload": "Нажмите для загрузки или перетащите сюда видео или аудио файл в хорошем качестве размером до 6 часов и до 3,0Гб. Также Вы можете приложить текстовый документ в формате pdf, doc, docx. Имя файла не должно превышать 80 символов.",
    "Drop files here or click to upload for i": "Нажмите для загрузки или перетащите сюда видео или аудио файл в хорошем качестве размером до 6 часов и до 3,0Гб. Имя файла не должно превышать 80 символов.",
    "Upload file": "Загрузка файла",
    "Too big size": "Файл слишком большой",
    "Delete": "Удалить",
    "Wallet": "Кошелёк",
    "Feedback": "Техническая поддержка",
    "Send files": "Отправить файлы",
    "Are you shore delete file": "Вы уверены, что хотите удалить этот файл? Восстановить его будет невозможно!",
    "Are you divide this files": "Вы хотите расшифровать этот файл в виде одного общего документа или по отдельности?",
    "Cancel": "Отмена",
    "Split": "Раздельно",
    "Combine": "Вместе",
    "New file": "Загрузить файл",
    "Termins": "Мои термины",
    "Decryption": "Расшифровка",
    "Detocs": "Обработанный текст",
    "Normalized": "Обработанный текст",
    "Simplified": "Обработанный текст",
    "Meeting Analytics": "Аналитика",
    "OneClickDecryption": "Расшифровать в один клик",
    "Abstract": "Конспект",
    "Protocol": "Резюме",
    "Convert files": "Отправить",
    "Close file": "Скрыть",
    "Speakers count": "Укажите предположительное число спикеров",
    "Email": "Email",
    "Enter Email": "Введите email",
    "Country": "Страна",
    "File": "Файл:",
    "Speakers": "Спикеры",
    "Copied": "Текст скопирован",
    "PENDING": "Формирование резюме",
    "SUCCESS": "Резюме готово",
    "FAILURE": "Что-то пошло не так. Запросите протокол еще раз",
    "Save": 'Сохранить',
    "Saved": 'Сохранено',
    "EqualSpeakers": 'Имена спикеров не могут быть одинаковыми',
    'SaveSpeaker': 'Пожалуйста, сохраните имя спикера',
    'GetProtocol': 'Запрос отправлен',
    "GetAnalitic": 'Запрос отправлен',
    'ContinueTranscript': 'Расшифровать',
    'ContinuePickSpeakers': 'Расшифровать',
    'ContinuePickSpeakersExtended': 'Расшифровать',
    'DecryptionWithProtocol': "Автоматически получить резюме",
    'AutoScroll': "Автоскролл",
    modals: {
        'AutoProtocol1': 'Резюме для файла',
        'AutoProtocol2': 'был успешно создан',
        'AutoProtocolBTN': 'Закрыть',
        'Support1': 'Проблемы с расшифровкой?',
        'Support2': 'Свяжитесь через телеграм со службой поддержки или оставьте сообщение в форме обратной связи.',
        'Support3': 'Номер запроса',
        'SupportBTN': 'Написать в поддержку',
        'NewFile1': 'Расшифровка готова!',
        'NewFile2': 'Для возможности получения качественного резюме:',
        'NewFile3': '- проверьте расшифровку,',
        'NewFile4': '- переименуйте спикеров,',
        'NewFile5': '- отметьте тех спикеров, чьи слова наиболее важны',
        'NewFileBTN': 'Перейти к расшифровке',
        'LowBalance1': 'Упс! Средств недостаточно для расшифровки.',
        'LowBalance2': 'Приобрести пакет минут можно, написав нашему менеджеру в чат Telegram по ссылке:',
        'LowBalance3': 'или позвонив по телефону',
        "Share": "Поделиться",
    },
    fileStatus: {
        UPLOADED: 'Файл готов к расшифровке',
        SENT: 'Обрабатываем файл',
        STARTED: 'Расшифровка займет ~',
        PENDING: 'Расшифровка займет ~',
        NOT_PAID: 'Ожидает оплаты',
        FAILURE: 'Ошибка',
        SUCCESS: 'Готово',
    },
    'Min': 'мин.',
    'EqualFileNames': 'У файлов не может быть одинаковых названий',
    "Personal cabinet": "Личный кабинет",
    "Profile": "Настройки профиля",
    "UserProfile": "Профиль",
    "FIO" : "Ф.И.О.",
    "Enter FIO" : "Введите имя и фамилию",
    "Enter F" : "Фамилия",
    "Enter I" : "Имя",
    "Enter O" : "Отчество",
    "Enter email" : "Введите email",
    "Enter telegram" : "Введите telegram аккаунт без спецсимволов",
    "Password change" : "Смена пароля",
    "Enter Old Password" : "Введите старый пароль",
    "Enter New Password" : "Введите новый пароль",
    "Enter Repeat Password" : "Введите повтор пароля",
    "Change password" : "Изменить пароль",
    "Pay" : "Оплатить",
    "Upload files" : "Загрузка файлов на сервер...",
    "Upload file" : "Загрузка файла на сервер...",
    "Convert files to mp3" : "Конвертация файлов в mp3...",
    "Convert file to mp3" : "Конвертация файла в mp3...",
    "Jurface": "Юр. лицам",
    "OrgFullName" : "Полное наименование организации",
    "INN" : "ИНН",
    "OGRN" : "ОГРН / ОГРНИП",
    "KPP" : "КПП",
    "FizAddress" : "Физический адрес",
    "JurAddress" : "Юридический адрес",
    "BankName" : "Наименование банка",
    "BIK" : "БИК",
    "CorrSchet" : "Корреспонденский счет",
    "RasSchet" : "Расчетный счет",
    "EmailBuh" : "Email бухгалтерия",
    "FIORuk" : "ФИО Руководителя",
    "Requisites" : "Реквизиты",
    "Org account" : "Аккаунт организации",
    "Add org" : "Добавить организацию",
    "Terms" : "Термины",
    "Terms&Tags": "Теги и термины",
    "Tags": "Теги",
    "New term" : "Новый термин",
    "Contacts" : "Контакты",
    "Contact list" : "Список контактов",
    "userrole" : "должность",
    "Transcripts" : "Расшифровки",
    "Total transcripts" : "Всего расшифровок",
    "Accept" : "Принять",
    "YouOwnerOfTranscript" : "Вы владелец расшифровки и не можете взять ее к себе на стену!",
    "ConsolidationRemoved" : "Ссылка удалена",
    "Orders": "Расшифровок",
    "FileSize": "размер файла",
    "Created": "Создан",
    "Meetings": "Запланированные встречи"
}

export default langdata
