import React, {useState, useEffect} from "react";
import {createContext} from "react";

import { io } from 'socket.io-client';

const URL = 'wss://wsserver.timelist.ru';

const socket = io.connect( URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax : 5000,
  reconnectionAttempts: Infinity,
  autoConnect: true
});


export const WebsocketContext = createContext({})

export const WebsocketProvider = ({children}) => {
  
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [synchronizeEvent, setSynchronizeEvents] = useState({version:0});

  useEffect(() => {
   function onConnect() {
     setIsConnected(true);
     console.log('connected');
   }

   function onDisconnect() {
     setIsConnected(false);
   }

   function onSynchronizeEvent(value) {
    try
    {
      let data = JSON.parse(value)
      setSynchronizeEvents(JSON.parse(value));
    }
    catch(error)
    {
      console.error("onSynchronizeEvent", value, error)
    }
   }

   socket.on('connect', onConnect);
   socket.on('disconnect', onDisconnect);
   socket.on('synchronize', onSynchronizeEvent);

   return () => {
     socket.off('connect', onConnect);
     socket.off('disconnect', onDisconnect);
     socket.off('foo', onFooEvent);
   };
 }, []);

 useEffect(() => {
   if (synchronizeEvent && synchronizeEvent?.version) {
     if (localStorage.getItem('version') != synchronizeEvent?.version) {
       localStorage.setItem('version', synchronizeEvent?.version);
       window.location.reload();
     }
   }
 }, [synchronizeEvent?.version]);

  return (
    <WebsocketContext.Provider value={ { socket, isConnected, synchronizeEvent } }>
      {children}
    </WebsocketContext.Provider>
  );
};
