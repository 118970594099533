import React, {useState, useEffect} from 'react'
import Routes from './routes/index'
import './assets/scss/themes.scss'
import { TranslateProvider } from './providers/Translate'
import { NotifyProvider } from './providers/Notify'
import { WebsocketProvider } from './providers/Websocket'

function App() {

    return (
        <React.Fragment>
          <WebsocketProvider>
            <NotifyProvider>
                <TranslateProvider>
                    <Routes />
                </TranslateProvider>
            </NotifyProvider>
          </WebsocketProvider>
        </React.Fragment>
    )
}

export default App
