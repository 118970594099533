import { gql } from '@apollo/client'

const REGISTER = gql`
    mutation Register($id: String, $input: UserProfileInput) {
        createOrUpdateProfile(id: $id, input: $input) {
            id
            nickname
            f
            i
            o
            phone
            phoneConfirmed
            registerDateTime
            lastVisitDateTime
            AutoDecript
            tocken
        }
    }
`
const LOGIN = gql`
    mutation Login($phone: String!, $password: String!) {
        login(phone: $phone, password: $password) {
            id
            nickname
            f
            i
            o
            phone
            phoneConfirmed
            registerDateTime
            lastVisitDateTime
            AutoDecript
            tocken
        }
    }
`

const CHANGAPASSWORD = gql`
    mutation ChnageUserPassword($userId: String!, $password: String!) {
        changePassword(userId: $userId, password: $password)
    }
`
const UPLOADFILES = gql`
    mutation UploadFiles($userId: String!, $files: [Upload!]!) {
        uploadUserFiles(userId: $userId, files: $files) {
            path
            filename
            mimetype
            encoding
            error
        }
    }
`
const REMOVE_FILE = gql`
    mutation RemoveUserFile(
        $orderId: String
        $userId: String
        $filename: String
    ) {
        removeUserFile(orderId: $orderId, userId: $userId, filename: $filename)
    }
`
const REMOVE_ORDER = gql`
    mutation RemoveOrder($removeOrderId: String!) {
        removeOrder(id: $removeOrderId)
    }
`
const TRANSLATE_FILE = gql`
    mutation TranslateFile(
        $orderId: String
        $userId: String
        $filename: String
        $speakers: Int
        $autoprotocol: Boolean
        $resumeType: String
        $decriptLang: String
    ) {
        translateFile(
            orderId: $orderId
            userId: $userId
            filename: $filename
            speakers: $speakers
            autoprotocol: $autoprotocol
            resumeType: $resumeType
            decriptLang: $decriptLang
        ) {
            id
        }
    }
`
const EMAIL = gql`
    mutation Email($email: String, $message: String, $backemail: String) {
        sendmail(email: $email, message: $message, backemail: $backemail)
    }
`
const sendPhoneConfirmationMutation = gql`
    mutation AwaitCall($phone: String!) {
        awaitcall(phone: $phone)
    }
`

const UPDATE_ORDER_STRINGS = gql`
    mutation UpdateOrder($updateOrderId: String, $input: UpdateOrderInput) {
        updateOrder(id: $updateOrderId, input: $input) {
            lastCheckAnswer
            protocolAnswer
        }
    }
`

const UPDATE_ORDER_TITLE = gql`
    mutation UpdateOrderTitle($id: String!, $title: String) {
        updateOrderTitle(id: $id, title: $title) {
            id
        }
    }
`

const ORDERPUBLISHED = gql`
    mutation OrderPublished($orderId: String!, $publicOrder: Boolean!) {
        setOrderPublic(orderId: $orderId, publicOrder: $publicOrder) {
            id
        }
    }
`

const UPDATE_DECRYPTION_SPEAKERS = gql`
    mutation ChangeSpeakerName(
        $orderId: String!
        $initialName: String!
        $newName: String!
    ) {
        changeSpeakerName(
            orderId: $orderId
            initialName: $initialName
            newName: $newName
        ) {
            speakersData
            lastCheckAnswer
        }
    }
`

const CREATE_DECRYPTION_SPEAKER = gql`
    mutation AddSpeakerName(
        $orderId: String!
        $initialName: String!
        $speakerName: String!
    ) {
        addSpeakerName(
            orderId: $orderId
            initialName: $initialName
            speakerName: $speakerName
        ) {
            speakersData
            lastCheckAnswer
        }
    }
`

const REMOVE_DECRYPTION_SPEAKER = gql`
    mutation RemoveSpeakerName($orderId: String!, $speakerName: String!) {
        removeSpeakerName(orderId: $orderId, speakerName: $speakerName) {
            speakersData
            lastCheckAnswer
        }
    }
`

const UPDATE_DECRYPTION_LEADS = gql`
    mutation ChangeSpeakerRole(
        $orderId: String!
        $initialName: String!
        $role: SpeakerRoles
    ) {
        changeSpeakerRole(
            orderId: $orderId
            initialName: $initialName
            role: $role
        ) {
            speakersData
        }
    }
`
const GENERATE_PROTOCOL = gql`
    mutation GenerateProtocol(
        $orderId: String!
        $input: GenerateProtocolQuery,
        $resumeType: String
    ) {
        generateProtocol(orderId: $orderId, input: $input, resumeType: $resumeType) {
            protocolAnswer
        }
    }
`

const GENERATE_ANALITIC = gql`
mutation GenerateAnalitic(
    $orderId: String!
    $input: GenerateAnaliticQuery
) {
    generateAnalitic(orderId: $orderId, input: $input) {
        analiticId
    }
}
`
const CHANGEPASSBYPHONE = gql`
    mutation ChangePassword($phone: String!, $password: String!) {
        changeUserPasswordByPhone(phone: $phone, password: $password)
    }
`

const CHANGEPASS = gql`
    mutation ChangePassword2(
        $oldpassword: String!
        $newpassword: String!
        $newpassword2: String!
    ) {
        changeUserPasswordByPassword(
            oldpassword: $oldpassword
            newpassword: $newpassword
            newpassword2: $newpassword2
        )
    }
`

const SAVETERMS = gql`
    mutation SaveTerms($id: String!, $terms: String) {
        changeUserTerms(id: $id, terms: $terms) {
            id
            terms
        }
    }
`

const ConfirmEmail = gql`
    mutation Confirm {
        confirmEmail
    }
`

const ADDCONSOLIDATION = gql`
    mutation AddConsolidation($orderId: String!, $userIds: [String!]!) {
        addConsolidation(orderId: $orderId, userIds: $userIds)
    }
`
const REMOVECONSOLIDATION = gql`
    mutation RemoveConsolidation($orderId: String!, $userIds: [String!]!) {
        removeConsolidation(orderId: $orderId, userIds: $userIds)
    }
`

const mutations = {
    REGISTER,
    LOGIN,
    CHANGAPASSWORD,
    UPLOADFILES,
    REMOVE_FILE,
    TRANSLATE_FILE,
    ORDERPUBLISHED,
    EMAIL,
    sendPhoneConfirmationMutation,
    REMOVE_ORDER,
    UPDATE_ORDER_STRINGS,
    UPDATE_ORDER_TITLE,
    UPDATE_DECRYPTION_SPEAKERS,
    CREATE_DECRYPTION_SPEAKER,
    REMOVE_DECRYPTION_SPEAKER,
    UPDATE_DECRYPTION_LEADS,
    GENERATE_PROTOCOL,
    GENERATE_ANALITIC,
    CHANGEPASSBYPHONE,
    CHANGEPASS,
    ConfirmEmail,
    SAVETERMS,
    ADDCONSOLIDATION,
    REMOVECONSOLIDATION,
}

export default mutations
