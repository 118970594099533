import React from "react";
import s from './Textarea.module.css'

import ReactQuill, { Quill } from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';
import { parseTimeToSeconds } from '../../helpers/helpers_functions'
//import { ImageResize } from 'quill-image-resize-module';

Quill.register('modules/imageResize', ImageResize);

import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  //['blockquote', 'code-block'],
  ['link', 'image', 'video'],

  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  //[{ 'direction': 'rtl' }],                         // text direction

  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'font': [] }],
  //[{ 'align': [] }],

  ['clean']                                         // remove formatting button
];

let fullscreenEditor = false
let selectedRowindex = -1

export const TextareaAutoNew = ( { value, rowindex, speaker, onChange, isBold, tab, searchtext,
  selectedkey,
  SetFocusedKey,
  OnAddParagraph,
  OnRemoveParagraph,
  OnSaveData,
  hideSpeakers,
  editable,
  neutralizedText,
  detoxifiedText,
  simplifedText,
  detox,
  normalized,
  simplifed,
  orderId,
  newchecked,
  showcheckboxes,
  onToggleCheckbox,
  skipped,
  timestamp,
  videoRef,
  currentSpeakerTime,
  onBlurEditor
 } ) => {     // do not remove "tab". It's need to resize textarea
  const ref = React.useRef(null);
  const ref2 = React.useRef(null);
  const hiddenElement = React.useRef(null);
  const [height, setHeight] = React.useState(0)
  const [focused, SetFocused] = React.useState(false)
  const [onblur, SetBlur] = React.useState(false)
  const [fullscreen, SetFullscreen] = React.useState(false)
  const [toolbar, SetToolbar] = React.useState(false)
  const [visibletext, SetVisibleText] = React.useState(value ?? "")
  const [originaltext, SetOriginalText] = React.useState(value ?? "")
  const [border, SetBorder] = React.useState("0px solid black")
  const [checked, SetCheckedParagraph] = React.useState(false)
  const [HashChanged, SetHashChanged] = React.useState(false)
  function adjustHeight(el){
    if (el)
    {
        el.style.height = (el.scrollHeight > el.clientHeight) ? (el.scrollHeight)+"px" : "auto";
    }
  }

  const HightLightFileText = (text, substr) => {
    let rg = new RegExp(substr, 'gi');
    return substr!="" ? text.replace(rg, `<span style="color:red; font-weight:bold;">${substr.toUpperCase()}</span>`) : text
  }


  function unEscape(htmlStr) {
    htmlStr = htmlStr ? htmlStr : ""
    htmlStr = htmlStr.replace(/&lt;/g , "<");	 
    htmlStr = htmlStr.replace(/&gt;/g , ">");     
    htmlStr = htmlStr.replace(/&quot;/g , "\"");  
    htmlStr = htmlStr.replace(/&#39;/g , "\'");   
    htmlStr = htmlStr.replace(/&amp;/g , "&");
    return htmlStr;
  }

  function removeHtml(htmlStr) {
    return htmlStr
    //htmlStr.replace(/<\/?[^>]+(>|$)/g, "")
  }

  const HideSpeakersIfNeed = (text) => {
    if (!hideSpeakers) return text

    let clearText = text.substring(text.indexOf(':')+1)
    return clearText?.trim() ?? text
  }

  React.useEffect(() => 
  {
    //setHeight(hiddenElement.current.clientHeight)
    adjustHeight(ref.current)
    //adjustHeight(ref2.current)
    //Quill.register('modules/imageResize', ImageResize);
  }, [tab])

  React.useEffect(() => {
    const textareaRef = ref.current
    if (!ref && !textareaRef) return

    const autoResize = () => setTimeout(() => {
      //textareaRef.style.cssText = 'height:auto;'
      //textareaRef.style.cssText = `height: ${textareaRef.scrollHeight}px`
    }, 0)

    const keyHandler = (e) => {
      if (e.key === 'Escape' && !e.shiftKey) {
        e.preventDefault()

        if (fullscreenEditor == true)
        {
          SetFullscreen(false)
          fullscreenEditor = false
        } else 
        {
        
          //  onBlurEditor(visibletext)
          if (selectedRowindex == rowindex)
          {
            SetVisibleText(originaltext)
            selectedRowindex = -1
          }

          SetFocused(false)
          SetFocusedKey("")
          SetHashChanged(false)
          SetOriginalText(visibletext)
        }
      }
    }

    //ref?.current?.addEventListener('input', autoResize);
    //document.addEventListener('keyup', keyHandler);

    return () => {
      //textareaRef?.removeEventListener('input', autoResize)
      //document.removeEventListener('keyup', keyHandler);
      
    }
  }, [height, tab])

  React.useEffect(()=>{
    let copytext = value ?? ""
    let highlitedText = searchtext!="" ? copytext.replace(searchtext, `<b>${searchtext}</b>`) : (value ?? "")
    SetVisibleText(highlitedText)
  }, [searchtext])

  React.useEffect(()=>{
      SetFocused(selectedkey == `editor_${rowindex}`)
  }, [selectedkey])

  React.useEffect(()=>{

    const mouseKeyHandler = (e) => {
      if (e.which == 1)
      {
        SetFocused(false)
        SetFocusedKey("")
      }
    }

    
    const mouseKeyHandler2 = (e) => {
      if (e.which == 1)
      {
        e.stopPropagation()
        e.preventDefault()
      }
    }

    const closEditor = (e) => {
      //if (confirm("Сохранить изменения в редакторе?"))
      //{
        
      //}
//      onBlurEditor(visibletext)
      SetFocused(false)
      SetFocusedKey("")
    }

    let root = document.getElementById("root")
    let editorContainer = document.getElementsByClassName("editorContainer")[0]

    if (focused)
    {
      document.addEventListener('closeditor', closEditor)
    }
    else 
    {
      document.removeEventListener('closeditor', closEditor)

      if (HashChanged)
      {
        SetHashChanged(false)
        if (OnSaveData) OnSaveData()
      }
    }

    return () => {
      document.removeEventListener('closeditor', closEditor)
    }
  }, [focused])

  React.useEffect(()=>{
    if (value?.indexOf("<p>")<0)
    {
      let preparedText = value.split("\n").reduce((alldata, p)=>{
          return alldata + `<p>${p}</p>`
      }, "")
      SetVisibleText(preparedText)//(value.replace(/[\n]/ig, "<br>"))  
    } else 
    {
      SetVisibleText(value ?? "")
    }
  }, [value])

  React.useEffect(()=>{
    
  }, [orderId])

  React.useEffect(()=>{

    let neutralizedContent = value
    let detoxifiedContent = value
    let simplifedContent = value
    let skippedContent = value

    if (normalized && neutralizedText?.length > 0)
    {
      neutralizedText?.map((el)=>{
        neutralizedContent = neutralizedContent.replace(el[0], "<span style='color:#0b4e54; font-weight:bold' title='"+el[0]+"'>"+el[1]+"</span>")
      })
      
      SetVisibleText(neutralizedContent)
    }

    if (normalized && neutralizedText?.length == 0)
    {
      SetVisibleText(neutralizedContent)
    }

    if (detox && detoxifiedText?.length > 0)
    {
      detoxifiedText?.map((el)=>{
        detoxifiedContent = detoxifiedContent.replace(el[0], "<span style='color:#0b4e54; font-weight:bold' title='"+el[0]+"'>"+el[1]+"</span>")
      })

      SetVisibleText(detoxifiedContent)
    }

    if (detox && detoxifiedText?.length == 0)
    {
      SetVisibleText(detoxifiedContent)
    }

    if (simplifed && simplifedText?.length > 0)
    {
      simplifedText?.map((el)=>{
        simplifedContent = simplifedContent.replace(el[0], "<span style='color:#0b4e54; font-weight:bold' title='"+el[0]+"'>"+el[1]+"</span>")
      })  

      SetVisibleText(simplifedContent)
    }

    if (simplifed && simplifedText?.length == 0)
    {
      SetVisibleText(simplifedContent)
    }

    if (skipped && skipped?.length > 0)
    {
      let skippedcopy = [...skipped]
      skippedcopy?.map((el)=>{
        let data = el[2]
        if (data && data.indexOf(":")>=0)
        {
          data = el[2].split(":")[1].trim()
        }
        
        let skippedContent2 = skippedContent.replace(data, `<span style='color:#710732;' title='ИИ сомневается в данном участке текста. Вы можете его переслушать и скорректировать.'>${data}</span>`)
        if (skippedContent2 != skippedContent)
        {
          
        }

        skippedContent = skippedContent2
      })  

      SetVisibleText(skippedContent)
    }

  }, [value, normalized, neutralizedText, detox, detoxifiedText, simplifed, simplifedText, skipped])

  React.useEffect(()=>{
     let pchecked = parseInt(localStorage.getItem('order_'+orderId+'_row'+rowindex) ?? "1")
     SetCheckedParagraph(pchecked == 1)
  }, [orderId])

  React.useEffect(()=>{
    if (newchecked != undefined && (newchecked == true || newchecked == false))
    {
      localStorage.setItem('order_'+orderId+'_row'+rowindex, newchecked ? "1" : "0")
      SetCheckedParagraph(newchecked)
    }
  }, [newchecked])

  React.useEffect(()=>{
    //let ss = skipped?.filter(f=>f[0] == timestamp)
    
  }, [skipped])

    /*
  * Обработка клика по диалогу
  * */
  const handleSubtitleClick = React.useCallback((startTime) => {
      if (!startTime) return;
      if (videoRef.current && videoRef.current.currentTime !== startTime) {
          videoRef.current.currentTime = parseTimeToSeconds(startTime);
      }
  }, [videoRef]);
  
  return (
    <div className={ (fullscreen ? s.wrapperfullscreen : s.wrapper) + " editorContainer" } key={`editor_${rowindex}`} style={{display:"flex", flexDirection:"row", justifyContent:"start", alignItems:"start"}}>
      
      {showcheckboxes &&
          <input
              type="checkbox"
              id={`checkbox_${rowindex}`}
              checked={checked}
              style={{cursor:"pointer", marginTop:5}}
              onClick  ={(e) => {
                localStorage.setItem('order_'+orderId+'_row'+rowindex, !e.target.checked ? "1" : "0")
                SetCheckedParagraph(!e.target.checked)
                if (onToggleCheckbox)
                {
                  onToggleCheckbox(!e.target.checked)
                }
              }}
          />
      }
      {!focused ?
      <div
          ref={ ref }
          style={{
              width:"100%",
              minHeight:30,
              paddingRight: 60,
              border: visibletext?.trim().replace(/<[^>]*>?/gm, '') == "" ?
                      "1px dashed lightgray" :
                      "", fontSize: 13, fontFamily:
                      "Verdana, Calibri, sans-serif"
          }}
          className={ `${s.textarea_auto} ${isBold ? s.bold : ''}` }
          onClick={()=> {
              handleSubtitleClick(currentSpeakerTime || '');
              if (editable) SetFocusedKey(`editor_${rowindex}`)
          }}
          dangerouslySetInnerHTML={{__html: unEscape(HightLightFileText(HideSpeakersIfNeed(visibletext), searchtext))}}>
      </div>
      :
      <div>

        {rowindex == 0 && !fullscreen ?
            <div
                className="d-flex justify-content-center position-relative"
                style={{
                    height:30,
                    marginBottom:10
                }}>
                     <button
                      id = {`addbtn_${rowindex}`}
                      className='btn btn-sm btn-outline-secondary'
                      onClick={ () => {
                        OnAddParagraph(rowindex, "before")
                        if (editable) SetFocusedKey(`editor_${parseInt(rowindex)+1}`)
                      }}
                    >
                        <i class="fas fa-plus"></i> Вставить параграф выше
                    </button>
            </div> : <></>
        }

      {/*removeHtml*/}
      <ReactQuill theme="snow" 
        //className={!focused ? 'hidden-toolbar' : ''}
        modules = {{ toolbar : toolbar ? toolbarOptions : false,   imageResize: toolbar ? {
          parchment: Quill.import('parchment'),
          modules: ['Resize', 'DisplaySize']
       } : false }} key={`editor_${rowindex}`} 
        style={{background: isBold ? "#f7f7f7" : "", paddingRight: 60, height: fullscreen ? "95vh" : "auto"}} value={removeHtml(HideSpeakersIfNeed(visibletext))} 
        onFocus={ (range, source, editor) => {
          //SetFocused(true)
          selectedRowindex = rowindex
        }}
        onBlur={ (previousRange, source, editor) => {
         // SetFocused(false)
         if (HashChanged)
         {
          onBlurEditor(visibletext)
         }
        }}
        onChange={(text)=>{
          SetVisibleText(text)
          onChange(text)
          SetHashChanged(true)
        }} />

              {!fullscreen ? <div className="d-flex justify-content-center position-relative" style={{height:30, marginTop:10}}>
                     <button
                      id = {`addbtn_${rowindex}`}
                      className='btn btn-sm btn-outline-secondary'
                      onClick={ () => {OnAddParagraph(rowindex, "after")} }
                    >
                        <i class="fas fa-plus"></i> Вставить параграф ниже
                    </button>

                 </div> : <></>}
      </div>
    }

    {focused  ?
        <div className="d-flex flex-column justify-content-start position-absolute mt-2" style={{ right:15, top:0, zIndex: 100}}>

            <button
                title={"Панель инструментов"}
                id = {`toolbarbtn_${rowindex}`}
                className={toolbar ? 'btn btn-sm btn-secondary mb-2 stopMouseEvents' : 'btn btn-sm btn-outline-secondary mb-2 stopMouseEvents'}
                          onClick={ (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              SetToolbar(!toolbar)
                          } }
              >
                <i class={toolbar ? "fas fa-tools" : "fas fa-tools"}></i>
            </button>

            <button
                title={"На полный экран"}
                id = {`fullscreenbtn_${rowindex}`}
                className='btn btn-sm btn-outline-secondary mb-2 stopMouseEvents'
                          onClick={ (e) => {
                              e.preventDefault()
                              e.stopPropagation()
                              SetFullscreen(!fullscreen)
                              fullscreenEditor = !fullscreenEditor
                          } }
              >
                <i class={fullscreen ? "fa fa-compress" : "fa fa-expand"}></i>
            </button>

            <button
                title={"Удалить параграф"}
                id = {`removebtn_${rowindex}`}
                className='btn btn-sm btn-outline-secondary stopMouseEvents'
                          onClick={ (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            let result = confirm("Удалить параграф?");
                            if (result)
                            {
                              OnRemoveParagraph(rowindex)
                            }
                          } }
              >
                <i class="fas fa-trash"></i>
            </button> 
       
        </div>
        : null }

    </div>
  )
}
