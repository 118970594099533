import React, { useEffect, useState, useRef, useCallback } from 'react';
import { formatTime } from '../helpers/helpers_functions'

export const VideoPlayerProgressBar = React.memo(({ videoRef, onTimeUpdate }) => {
    const currentTimeRef = useRef(0);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const lastUpdateTime = useRef(0);

    const updateProgress = useCallback(() => {
        if (videoRef?.current) {
            const currentTime = videoRef?.current.currentTime;
            currentTimeRef.current = currentTime;
            onTimeUpdate(videoRef?.current.currentTime);

            if (Math.abs(currentTime - lastUpdateTime.current) > 0.1) {
                setCurrentTime(currentTime);
                lastUpdateTime.current = currentTime;
            }
        }
    }, []);

    const updateDuration = useCallback(() => {
        if (videoRef?.current) {
            const videoDuration = videoRef.current.duration || 0;
            setDuration(isNaN(videoDuration) || !isFinite(videoDuration) ? 0 : videoDuration);
        }
    }, []);

    useEffect(() => {
        const video = videoRef?.current;
        if (!video) return;

        const updateFrame = () => {
            updateProgress();
            requestAnimationFrame(updateFrame);
        };

        video.addEventListener('loadedmetadata', updateDuration);
        video.addEventListener('timeupdate', updateProgress);
        video.addEventListener('play', () => requestAnimationFrame(updateFrame));

        if (video.readyState >= 1) updateDuration();

        return () => {
            video.removeEventListener('loadedmetadata', updateDuration);
            video.removeEventListener('timeupdate', updateProgress);
            video.removeEventListener('play', () => requestAnimationFrame(updateFrame));
        };
    }, [updateProgress, updateDuration]);

    const handleSeek = (e) => {
        const newTime = parseFloat(e.target.value);
        if (videoRef?.current) {
            videoRef.current.currentTime = newTime;
            setCurrentTime(newTime);
            onTimeUpdate(newTime);
        }
    };

    return (
        <div className="d-flex align-items-center p-2 gap-2">
            <input
                type="range"
                className="form-range flex-grow-1"
                min="0"
                max={duration}
                value={currentTime}
                onChange={handleSeek}
                style={{
                    height: '10px',
                    borderRadius: '10px',
                    background: '#ddd',
                }}
            />
            <div className="text-nowrap small">
                {formatTime(currentTime)} / {formatTime(duration)}
            </div>
        </div>
    );
});
