import React, { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import useVideoControls from './UseVideoControls'
import { TranslateContext } from '../providers/Translate'

export const VideoPlayerControlPanel = React.memo(({
   videoRef,
   isTrackingEnabled,
   toggleTracking
}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showSlider, setShowSlider] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [volume, setVolume] = useState(1);
    const {language} = React.useContext(TranslateContext)
    const playbackRates = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

    useEffect(() => {
        const video = videoRef?.current;
        if (!video) return;

        const onPlay = () => setIsPlaying(true);
        const onPause = () => setIsPlaying(false);

        video.addEventListener('play', onPlay);
        video.addEventListener('pause', onPause);

        return () => {
            video.removeEventListener('play', onPlay);
            video.removeEventListener('pause', onPause);
        };
    }, []);

    const {
        playPause,
        rewind,
        forward,
        changeVolume,
        setPlaybackRate: changePlaybackRate,
        toggleFullScreen,
    } = useVideoControls(videoRef);


    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const handlePlayPause = () => {
        playPause();
        setIsPlaying((prev) => !prev);
    };
    const handlePlaybackRateChange = (rate) => {
        changePlaybackRate(rate);
        setPlaybackRate(rate);
    };
    const handleVolumeChange = (e) => {
        const normalizedVolume = e.target.value / 100;
        changeVolume(normalizedVolume);
        setVolume(normalizedVolume);
    };

    return (
        <div className="d-flex align-items-center justify-content-between p-3 bg-light w-100"
             style={{ height: '50px', borderRadius: '15px' }}>

            <div className="d-flex">
                <div className="d-flex align-items-center">
                    <button onClick={rewind} className="btn btn-light rounded-circle d-flex ms-md-0 ms-n3">
                        <i className="bx bx-rewind font-size-24" />
                    </button>
                    <button onClick={handlePlayPause} className="btn btn-light rounded-circle d-flex">
                        <i className={`bx ${isPlaying ? 'bx-pause' : 'bx-play'} font-size-24`} />
                    </button>
                    <button onClick={forward} className="btn btn-light rounded-circle d-flex">
                        <i className="bx bx-fast-forward font-size-24" />
                    </button>
                </div>

                <Dropdown
                    className="d-flex align-items-center justify-content-center"
                    isOpen={dropdownOpen}
                    toggle={toggleDropdown}
                >
                    <DropdownToggle caret
                                    className="btn btn-light"
                                    style={{ width: '50px' }}
                    >
                        {`${playbackRate}x`}
                    </DropdownToggle>
                    <DropdownMenu>
                        {playbackRates.map((rate) => (
                            <DropdownItem
                                key={rate}
                                onClick={() => handlePlaybackRateChange(rate)}
                                active={rate === playbackRate}
                            >
                                {`${rate}x`}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <button
                    className="btn btn-light d-flex align-items-center justify-content-center ms-md-2 btn-sm btn-sm-md-0"
                    title={language['AutoScroll']}
                    onClick={toggleTracking}
                    style={{
                        maxWidth: '150px',
                        backgroundColor: isTrackingEnabled ? '#dcdbdb' : '',
                        color: isTrackingEnabled ? '#000000' : '',
                    }}
                >
                    <i className="mdi mdi-swap-vertical fs-6 fs-sm-3"></i>
                    <span className="d-none d-md-inline ms-1">
                        {language['AutoScroll']}
                    </span>
                </button>
            </div>

            <div className="d-flex">
                <div
                    className="position-relative d-flex align-items-center me-md-2 me-0"
                    onMouseEnter={() => setShowSlider(true)}
                    onMouseLeave={() => setShowSlider(false)}
                >
                    <div
                        className="position-absolute"
                        style={{
                            left: '-50%',
                            top: '-170px',
                            transform: 'translateX(-50%)',
                            width: '40px',
                            opacity: showSlider ? 1 : 0,
                            visibility: showSlider ? 'visible' : 'hidden',
                            transition: 'opacity 0.5s ease, visibility 0.5s ease',
                        }}
                    >
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={volume * 100}
                            className="form-range"
                            onChange={handleVolumeChange}
                            style={{
                                transform: 'rotate(270deg)',
                                width: '100px',
                                height: '200px',
                                marginTop: '20px',
                            }}
                        />
                    </div>
                    <button
                        className="btn btn-light rounded-circle d-flex p-1"
                        onClick={() => setVolume(volume > 0 ? 0 : 1)}
                    >
                        <i className={`
                        bx ${
                            volume > 0 ?
                                'bx-volume-full' :
                                'bx-volume-mute'
                        } 
                        font-size-20`} />
                    </button>
                </div>

                <button
                    className="btn rounded-circle d-flex p-1"
                    onClick={toggleFullScreen}
                >
                    <i className="bx bx-fullscreen font-size-20" />
                </button>
            </div>
        </div>
    );
});
