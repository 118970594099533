import React, { useState, useEffect } from 'react';
import { Card, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row, UncontrolledDropdown, Dropdown } from 'reactstrap';
import { CardBody, CardHeader, UncontrolledButtonDropdown } from 'reactstrap';
import MetaTags from "react-meta-tags";

// redux
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../store/actions";

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import { Link } from 'react-router-dom';

import { usePresets } from "../../Hooks/PresetsHooks"
import { useUsers } from '../../Hooks/UserHooks';
import { TranslateContext } from "../../providers/Translate";
import { NotifyContext } from "../../providers/Notify";
import {useProfile} from "../../Hooks/UserHooks";

import { useSpring, animated } from '@react-spring/web'

const UserGrid = () => {
    const dispatch = useDispatch();

    const [totalUsers, SetTotalUsers] = useState(0)
    const { language } = React.useContext(TranslateContext)
    const { error: presetserror, LoadPresets } = usePresets()
    const [publicationsList, SetPublicationsList] = useState([])
    const [myconsolidations, SetMyConsolidations] = useState([])
    const [userListid, SetUserListid] = useState("")
    const { userProfile } = useProfile();
    const { setNotify } = React.useContext(NotifyContext);

    const {users, user, error, LoadUsers, LoadUser, LoadUserLight, RegisterUser, LoginUser, TestPhone, UserChangePassword, UserChangePasswordByPassword, UpdateUserProfile, ConfirmEmail, LoadTerms, SaveTerms, AddConsolidation, RemoveConsolidation} = useUsers({
        onDone: (type, data) => {
            if (type == "users")
            {
                SetTotalUsers(data?.total ?? 0)
            }

            if (type == "userlight")
            {
                if (data.id == userProfile?.id)
                {
                    var consolidationsIds = data.consolidations?.map((c) => {
                        return c.order?.id ?? ""
                    })

                    console.log(consolidationsIds)
                    SetMyConsolidations(consolidationsIds)
                }
            }

            if (type == "newConsolidation")
            {
                LoadUserLight({variables: {id: userProfile?.id}})
            }

            if (type == "removedConsolidation")
            {
                LoadUserLight({variables: {id: userProfile?.id}})
            }
        }

    })
    /*
    set breadcrumbs
    */

    useEffect(() => {
        const breadCrumbItems = {
            title: "Контакты",
        };
        dispatch(setBreadcrumb(breadCrumbItems));
        LoadUsers({variables: {page: 1, itemsCount: 500}})
    }, [dispatch]);

    const [modal_user, setmodal_user] = useState(false);
    const [modal_success, setmodal_success] = useState(false);

    function tog_user() {
        setmodal_user(!modal_user);
    }
    function tog_success() {
        setmodal_success(!modal_success);
    }

    const LoadUserPublications = (id) => {
        LoadUser({variables: {id: id}})
    }

    useEffect(()=>{
        if (user)
        {
            SetPublicationsList(user.publications)
            SetUserListid(user.id)
        }
    }, [user])

    useEffect(()=>{
        LoadUserLight({variables: {id: userProfile?.id}})
    }, [userProfile])

    const styles = useSpring({
        from: {
          opacity: 0,
          y: '10%',
        },
        to: {
          opacity: 1,
          y: '0%',
        },
        config: { duration: 500 },
      })


    return (
        <React.Fragment>
            <MetaTags>
            <title>Кабинет | Таймлист - ИИ расшифровки аудио и автопротоколирования</title>
            </MetaTags>
            <div className="page-content">
                <Container fluid>
                    <Row className="align-items-center">
                        <Col md={6}>
                            <div className="mb-3">
                                <h5 className="card-title">{ language["Contact list"] }<span className="text-muted fw-normal ms-2">({totalUsers})</span></h5>
                            </div>
                        </Col>

                        <Col md={6}>
                            <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                                {/*
                                <div>
                                    <ul className="nav nav-pills">
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/contacts-list" data-bs-toggle="tooltip" data-bs-placement="top" title="List"><i className="bx bx-list-ul"></i></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link active" to="/contacts-grid" data-bs-toggle="tooltip" data-bs-placement="top" title="Grid"><i className="bx bx-grid-alt"></i></Link>
                                        </li>
                                    </ul>
                                </div>
                                */}

                                {/*
                                <UncontrolledDropdown className="dropdown">
                                    <DropdownToggle tag="a" className="btn btn-link text-muted py-1 font-size-16 shadow-none dropdown-toggle">
                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>Action</DropdownItem>
                                        <DropdownItem>Another action</DropdownItem>
                                        <DropdownItem>Something else here</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                */}
                            </div>

                        </Col>
                    </Row>

                    <Row>

                        {users?.map((useritem, index)=>{
                            return  <Col xl={3} sm={6}>
                                    <animated.div style={{...styles}} key={index}>
                                        <Card>
                                            <div className="card-body">
                                                <UncontrolledDropdown className="dropdown float-end">
                                                    <DropdownToggle tag="a" className="text-muted dropdown-toggle font-size-16">
                                                        <i className="bx bx-dots-horizontal-rounded"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem onClick={() => {}}>{language["UserProfile"]}</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-md">
                                                        {!useritem?.avatar ?
                                                        <div className="avatar-title bg-soft-primary text-primary display-6 m-0 rounded-circle">
                                                            <i className="bx bxs-user-circle"></i>
                                                        </div> : <></>}
                                                        {useritem?.avatar ? <img src={avatar2} alt="" className="avatar-md rounded-circle img-thumbnail" /> : <></>}
                                                    </div>
                                                    <div className="flex-1 ms-3">
                                                        <h5 className="font-size-16 mb-1"><Link to="#" className="text-dark">{useritem?.f ?? ""}{" "}{useritem?.i ?? ""}{" "}{useritem?.o ?? ""}</Link></h5>
                                                        {/* <span className="badge badge-soft-success mb-0">{language["userrole"]}</span> */}
                                                    </div>
                                                </div>
                                                <div className="mt-3 pt-1">
                                                    {useritem?.phone != "" ?
                                                    <p className="text-muted mb-0"><i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary"></i>
                                                        {useritem?.phone}</p> : <></> }
                                                    {useritem?.email != "" ?
                                                    <p className="text-muted mb-0 mt-2"><i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary"></i>
                                                        <a href={`mailto: ${useritem?.email}`}>{useritem?.email}</a></p> : <></> }
                                                    {useritem?.telegram != "" ?
                                                    <p className="text-muted mb-0 mt-2"><i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary"></i>
                                                        {useritem?.telegram}</p> : <></>}
                                                    <p className="text-muted mb-0 mt-2"><i className="bx bx-message-square-dots me-1"></i>
                                                    {language["Total transcripts"]}{" "}{useritem?.publications?.length ?? 0}</p>
                                                </div>
                                                <div className="d-flex gap-2 pt-4">
                                                    {/*<button type="button" id={`btn_${user.id}`} className="btn btn-soft-primary btn-sm w-50" onClick={()=>LoadUserPublications(user.id)}><i className="bx bx-user me-1"></i> {language["UserProfile"]}</button>*/}

                                                    {useritem?.publications?.length ?? 0 > 0  ?
                                                    <UncontrolledDropdown className="w-100" isOpen={userListid == useritem.id}>
                                                        <DropdownToggle tag="a" className="btn btn-link w-100 py-1 font-size-16 shadow-none dropdown-toggle">
                                                            <button type="button" className="btn btn-primary btn-sm w-100" onClick={()=>{
                                                                    if (userListid == useritem.id) {
                                                                        SetUserListid("")
                                                                    } else {
                                                                        SetUserListid(useritem.id)
                                                                        LoadUserPublications(useritem.id)
                                                                    }
                                                                }}>{userListid == "" || userListid !== useritem.id ? <><i className="bx bx-message-square-dots me-1"></i>{language["Transcripts"]}</> : <><i className="bx bx-save me-1"></i>{language["Accept"]}</>}</button>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            {publicationsList?.filter(p=>p.owner?.id == useritem?.id)?.map((publication)=>{
                                                                let speakersCount = publication?.speakersData!="" ? JSON.parse(publication?.speakersData)?.length  : 0
                                                                return <DropdownItem key={publication.id} className="w-100" disabled={userProfile?.id == publication?.owner?.id} onClick={() => {
                                                                    var state = myconsolidations?.includes(publication.id) ?? false
                                                                    if (!state) {
                                                                        AddConsolidation({variables: {orderId: publication.id, userIds: [userProfile?.id]}})
                                                                    } else {
                                                                        RemoveConsolidation({variables: {orderId: publication.id, userIds: [userProfile?.id]}})
                                                                    }
                                                                }}>
                                                                        <div className="form-check form-switch form-switch-md my-1" dir="ltr">
                                                                            <input checked={myconsolidations?.includes(publication.id) ?? false} className="form-check-input" type="checkbox" id={`tr_${publication.id}`} onChange={(e) => {
                                                                                
                                                                                if (userProfile?.id == publication?.owner?.id)
                                                                                {
                                                                                    setNotify({ message: language["YouOwnerOfTranscript"], type: 'warning' })
                                                                                    e.preventDefault()
                                                                                    e.stopPropagation()
                                                                                    return
                                                                                }
                                                                                /*
                                                                                if (e.target.checked) {
                                                                                    AddConsolidation({variables: {orderId: publication.id, userIds: [userProfile?.id]}})
                                                                                } else {
                                                                                    RemoveConsolidation({variables: {orderId: publication.id, userIds: [userProfile?.id]}})
                                                                                }
                                                                                */
                                                                            }} />
                                                                            <label className="form-check-label" htmlFor={`tr_${publication.id}`}>{publication.file}, {publication.minutes} мин., спикеров {speakersCount}</label>
                                                                        </div>
                                                                    </DropdownItem>    
                                                            })}

                                                        </DropdownMenu>
                                                    </UncontrolledDropdown > : <></> }
                                                </div>
                                            </div>
                                        </Card>
                                    </animated.div>
                                </Col>
                        })}

                    </Row>

                    {/*
                    <Row className="g-0 align-items-center pb-4">
                        <Col sm={6}>
                            <div>
                                <p className="mb-sm-0">Showing 1 to 10 of 57 entries</p>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="float-sm-end">
                                <ul className="pagination mb-sm-0">
                                    <li className="page-item disabled">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-left"></i></Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link">5</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link to="#" className="page-link"><i className="mdi mdi-chevron-right"></i></Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    */}
                </Container>
            </div>

            {/* Add New Modal */}
            <Modal
                size="xl"
                isOpen={modal_user}
                toggle={() => {
                    tog_user();
                }}
                className="new-customer"
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="myExtraLargeModalLabel">
                        {" "}
                        Add New{" "}
                    </h5>

                    <button
                        onClick={() => {
                            setmodal_user(false);
                        }}
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Username">Username</label>
                                <input type="text" className="form-control" placeholder="Enter Username" id="AddNew-Username" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Position</label>
                                <select className="form-select">
                                    <option selected>Select Position</option>
                                    <option>Full Stack Developer</option>
                                    <option>Frontend Developer</option>
                                    <option>UI/UX Designer</option>
                                    <option>Backend Developer</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Email">Email</label>
                                <input type="text" className="form-control" placeholder="Enter Email" id="AddNew-Email" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="AddNew-Phone">Phone</label>
                                <input type="text" className="form-control" placeholder="Enter Phone" id="AddNew-Phone" />
                            </div>
                        </div>
                    </div>
                    <Row className="mt-2">
                        <div className="col-12 text-end">
                            <button
                                type="button"
                                className="btn btn-danger me-2"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-x me-1"></i> Cancel
                            </button>

                            <button
                                type="submit"
                                className="btn btn-success"
                                data-bs-toggle="modal"
                                data-bs-target="#success-btn"
                                id="btn-save-event"
                                onClick={() => {
                                    setmodal_success(true);
                                    setmodal_user(false);
                                }}
                            >
                                <i className="bx bx-check me-1"></i> Confirm
                            </button>
                        </div>
                    </Row>
                </div>
            </Modal>

            {/* Success Modal */}
            <Modal
                isOpen={modal_success}
                toggle={() => {
                    tog_success();
                }}
                id="success-btn"
                centered
            >
                <div className="modal-body">
                    <div className="text-center">
                        <i className="bx bx-check-circle display-1 text-success"></i>
                        <h3 className="mt-3">User Added Successfully</h3>
                    </div>
                </div>
            </Modal>

        </React.Fragment>
    );
};

export default UserGrid;