import React from 'react'
import useVideoControls from '../../src/Videoplayer/UseVideoControls'

export const VideoPlayer = ({ videoRef, video, video2, isVideoFile }) => {
    const {
        toggleFullScreen,
    } = useVideoControls(videoRef)
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: 'auto',
            }}
        >
            <video
                ref={videoRef}
                style={{
                    width: '100%',
                    marginBottom: '10px',
                    borderRadius: '20px',
                    display: 'block',
                    height: isVideoFile ? 'auto' : 0,
                }}
            >
                <source src={video2} />
                <source src={video} />
            </video>
            {isVideoFile && (
                <button
                    className="btn rounded-circle d-flex p-1"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        border: 'none',
                        padding: '10px',
                    }}
                    onClick={toggleFullScreen}
                >
                    <i className="bx bx-fullscreen font-size-20" />
                </button>
            )}
        </div>
    )
}

export default VideoPlayer
