import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { TranslateContext } from "../../../providers/Translate";
import { useSpring, animated } from '@react-spring/web'
import { DecryptionEmailSender } from './DecryptionEmailSender'

export const ExistFilePanel = ( {
     fromUser,
     canDelete,
     downloadDocs,
     deleteFile,
     copyDocument,
     isOpen,
     toggle,
     toggleSpeakersMobile,order } ) => {
  const { language } = React.useContext(TranslateContext)

  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
    config: {
      duration: 1500
    }
  })

  const stylesForBtn = useSpring({
    from: {
      opacity: 0,
      x: '-100%',
    },
    to: {
      opacity: 1,
      x: '0%',
    },
    config: {
      duration: 500
    }
  })

  return (
    <>
        <div className="row mt-3 align-items-center">

            {downloadDocs ?
                <div className="col-4 col-md-auto pr-0 pe-0">
                    <UncontrolledDropdown className="dropdown float-start w-100">
                        <DropdownToggle tag="a" className="text-muted dropdown-toggle w-100">
                            <animated.div style={{ ...stylesForBtn }}>
                                <button
                                    className="btn btn-sm btn-outline-info mdi mdi-download-outline w-100 d-none d-md-block"
                                    style={{ height: 30 }}><span style={{ marginLeft: 2 }}>Скачать результаты</span>
                                </button>
                                <button
                                    className="btn btn-sm btn-outline-info mdi mdi-download-outline w-100 d-block d-md-none"
                                    style={{ height: 30 }}><span style={{ marginLeft: 2 }}>Скачать</span></button>
                            </animated.div>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem onClick={() => downloadDocs('DOCX')}>DOCX</DropdownItem>
                            {/* <DropdownItem onClick={ () => downloadDocs('XLSX') }>XLSX</DropdownItem> */}
                            <DropdownItem onClick={() => downloadDocs('PDF')}>PDF</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
                : <></>}

            <div className="col-4 col-md-auto px-1">
                <animated.div style={{ ...stylesForBtn }}>
                    <button
                        className="btn btn-sm btn-outline-success mdi mdi-content-copy w-100"
                        style={{ height: 30 }}
                        onClick={copyDocument}
                    >
                        <span style={{ marginLeft: 2, fontSize: 10 }}>Копировать</span>
                    </button>
                </animated.div>
            </div>

            {canDelete ?
                <div className="col-auto ps-0 pe-1">
                    <animated.div style={{ ...stylesForBtn }}>
                        <button
                            className="btn btn-sm btn-outline-danger w-100"
                            style={{ height: 30 }}
                            onClick={deleteFile}>
                            <i className="bx bx-trash font-size-16" />
                            <span style={{ marginLeft: 2 }}>Удалить</span>
                        </button>
                    </animated.div>
                </div> : <></>}
            <div className="col-auto ms-0 mt-1 mt-md-0 ps-md-0">
                <DecryptionEmailSender order={order}/>
            </div>

        </div>

        <div className="row mt-3 d-block d-md-none">
            <div className="col-12 w-100">
                <animated.div style={{ ...stylesForBtn}}>
        <button onClick={ () => toggleSpeakersMobile() }
          className={ `btn btn-primary  w-100` }>
            Получить резюме/аналитику
        </button>
        </animated.div>
        </div>
      </div>

    </>
  )
}
