import React, { useEffect } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import {Button, Card, CardBody} from "reactstrap";
import classnames from 'classnames'
import { TranslateContext } from '../../../providers/Translate'
import s from '../myFiles.module.scss'
import { CloseIcon, PlusIcon } from '../../../assets/images/icons'
import { PendingIconGray } from '../../../assets/images/icons'
import EditIcon from '../../../assets/images/edit.svg'
import SaveIcon from '../../../assets/images/save.svg'
import { TextareaAuto } from '../../../components/TextareaAuto/TextareaAuto'
import { TextareaAutoNew } from '../../../components/TextareaAuto/TextareaAutoNew'
import Moment from 'react-moment'
import { Tooltip } from 'reactstrap'
import { useSpring, useSpringRef, animated } from '@react-spring/web'
import { parseTimeToSeconds } from '../../../helpers/helpers_functions'

import PlusIconImg from '../../../assets/images/plus.png'

// const { log } = console
export const Tabs = ({
    decryptionList,
    decryptionList2,
    timelist,
    protocolList,
    decryptionChange,
    speakerChange,
    protocolChange,
    deleteProtocolParagraph,
    tabs,
    activeTab,
    setActiveTab,
    addProtocolParagraph,
    speakers,
    unlockButton,
    playAudio,
    searchtext,
    OnAddEmptyParagraph,
    OnDeleteParagraph,
    fullscreen,
    isNotDocument,
    analitic,
    OnSaveData,
    isDataFile,
    detox,
    normalized,
    simplifed,
    order,
    toggleAllCheckbox,
    showcheckboxes,
    onToggleCheckbox,
    onShowCheckers,
    videoRef,
    isTrackingEnabled,
    currentTimeInSeconds,
    setCurrentTimeInSeconds,
    readonly
}) => {
    const { language } = React.useContext(TranslateContext)
    const [editedKey, SetEditSpeaker] = React.useState(-2)
    const [focusedkey, SetFocusedKey] = React.useState('')
    const [showPart2, SetShowPart2] = React.useState(isDataFile == true ? false : true)
    const [showcheckboxeslist, SetShowCheckboxes] = React.useState(showcheckboxes)
    const [hint1, setHint1] = React.useState('')

    const [neutralizedText, setNeutraized] = React.useState([])
    const [detoxifiedText, setDetoxified] = React.useState([])
    const [simplifedText, setSimplified] = React.useState([])
    const [skipped, setSkippedSegments] = React.useState([])

    const [newchecked, SetNewChecked] = React.useState(undefined)
    const lastCall = React.useRef(0);
    const scrollContainerRef = React.useRef(null);
    
    useEffect(() => {
        if (order)
        {
            setNeutraized(order?.neutralised_text ? JSON.parse(order?.neutralised_text) : [])
            setDetoxified(order?.detoxified_text ? JSON.parse(order?.detoxified_text) : [])
            setSimplified(order?.simplifed_text ? JSON.parse(order?.simplifed_text) : [])
        }
    }, [order])
    //neutralizedText 

    useEffect(()=>{
        if (editedKey == -1)
        {
            if (OnSaveData) OnSaveData()
            SetEditSpeaker(-2)
        }
    }, [editedKey])

    let StartPlayWith = (time) => {
        var h = time.getHours()
        var m = time.getMinutes()
        var s = time.getSeconds()
        playAudio(h, m, s)
        //alert(h+":"+m+":"+s)
    }

    const api = useSpringRef()
    
    const styles = useSpring({
        ref: api,
        from: {
          opacity: 0,
          x: '-100%',
        },
        to: {
          opacity: 1,
          x: '0%',
        },
        config: {
          duration: 250
        }
      })

    useEffect(() => {
        api.start({
            from: {
                opacity: 0,
                x: '-100%',
              },
              to: {
                opacity: 1,
                x: '0%',
              },
              config: {
                duration: 150
              }
        })
    }, [activeTab])

    useEffect(()=>{
        if (newchecked != undefined && toggleAllCheckbox)
        {
            toggleAllCheckbox(order?.id, newchecked)
        }
    }, [newchecked])

    useEffect(()=>{
        if (order?.skip_segm)
        {
            try
            {
                let d = JSON.parse(order?.skip_segm)
                setSkippedSegments(d)
                //console.log(d)
            }
            catch
            {
                console.error("Не удалось распарсить фрагменты текста с неуверенным содержанием! ", order?.skip_segm)
            }
        }
    }, [order?.skip_segm])

/*
* Реализован автоскролл для активного диалога с опцией вкл/выкл на панели управления видео/аудио,
*  а также добавлена подсветка активного диалога при воспроизведении.
* */
    React.useEffect(() => {
        let animationFrameId;
        const updateTime = () => {
            if (videoRef.current && !videoRef.current.paused) {
                const currentTime = videoRef.current.currentTime;

                if (Math.abs(currentTime - lastCall.current) >= 1) {
                    setCurrentTimeInSeconds(currentTime);
                    lastCall.current = currentTime;
                }

            }
            animationFrameId = requestAnimationFrame(updateTime);
        };
        animationFrameId = requestAnimationFrame(updateTime);

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
        };
    }, []);

    const scrollToActiveSubtitle = () => {
        if (!isTrackingEnabled || !scrollContainerRef.current) {
            return;
        }
        const container = scrollContainerRef.current;
        const activeSubtitle = container.querySelector('.text-primary');

        if (activeSubtitle) {
            const containerRect = container.getBoundingClientRect();
            const activeSubtitleRect = activeSubtitle.getBoundingClientRect();

            if (activeSubtitleRect.top < containerRect.top ||
                activeSubtitleRect.bottom > containerRect.bottom) {
                container.scrollTop =
                    activeSubtitle.offsetTop - container.offsetTop -
                    container.clientHeight / 2 + activeSubtitle.clientHeight / 2;
            }
        }
    };

    React.useEffect(() => {
        scrollToActiveSubtitle();
    },[currentTimeInSeconds, isTrackingEnabled]);


    const processedDecryptionList = React.useMemo(() => {
        return Object.entries(decryptionList).map(([key, value], index, arr) => {
            const nextItem = arr[index + 1];
            const nextSpeakerTime = nextItem?.[1]?.speakerTime;
            const currentSpeakerTime = arr[index]?.[1]?.speakerTime;

            const isActive =
                videoRef?.current?.currentTime >= parseTimeToSeconds(value.speakerTime) &&
                (!nextSpeakerTime || videoRef?.current?.currentTime < parseTimeToSeconds(nextSpeakerTime));

            return { key, value, nextSpeakerTime, currentSpeakerTime, isActive };
        });
    }, [decryptionList, videoRef?.current?.currentTime]);

    return (
        <div className="card mb-0">
            <Nav tabs className="nav nav-tabs nav-tabs-custom nav-justified">
                {tabs.map((tab, idx) => (
                    <NavItem key={idx}>
                        <NavLink
                            style={{ cursor: 'pointer' }}
                            className={classnames({
                                active: activeTab === tab,
                            })}
                            onClick={() => setActiveTab(tab)}
                        >
                            {detox && tab == 'Detocs' ? <span className="d-sm-block">{language['Detocs']}</span> : ''}
                            {normalized && tab == 'Detocs' ? <span className="d-sm-block">{language['Normalized']}</span> : ''}
                            {simplifed && tab == 'Detocs' ? <span className="d-sm-block">{language['Simplified']}</span> : ''}
                            {tab != 'Detocs' ? <span className="d-sm-block">{language[tab]}</span> : ''}
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>

            <TabContent activeTab={activeTab} className="p-xl-4 p-2">
                <TabPane tabId="Decryption" id="about" className="overflow-hidden" key={1}>
                    <animated.div  style={{...styles}}>
                    <div
                        ref={scrollContainerRef}
                        style={{
                            width: '100%',
                            height: fullscreen
                                ? window.innerHeight - 320
                                : '400px',
                            overflow: 'auto',
                        }}
                    >
                        
                        
                        <div>
                            <span style={{width: 200, display: 'inline-block'}}></span>
                            <button className={'btn btn-outline-secondary'}
                                    title={"Вы можете выбрать и отправить на резюме отдельные абзацы для повышения качества резюме. Проставьте галочки напротив нужных Вам абзацев и нажмите AI-ассистент для формирования резюме."}
                                    onClick={ () => {
                                        SetShowCheckboxes(!showcheckboxeslist)
                                        if (onShowCheckers) onShowCheckers(!showcheckboxeslist)
                                    }}
                            >{"Выбрать абзацы"}
                            </button>
                            
                            {showcheckboxeslist && <>
                            <span style={{width: 25, display: 'inline-block'}}></span>
                            <button
                                className={'btn btn-outline-secondary'}
                                onClick={ () => { SetNewChecked(!newchecked) } }>
                                {!newchecked ? "Выбрать все абзацы" : "Отменить все абзацы"}
                            </button>
                            </>}
                            {/*
                            <input type='checkbox' id={`allchecked_${order?.id}`} style={{marginRight:10, cursor:"pointer"}} onChange={(e) => SetNewChecked(e.target.checked)} />
                            <label for={`allchecked_${order?.id}`} style={{cursor:"pointer"}} onClick={() => unlockButton()}>{"Выбрать все"}</label>
                            */}
                        </div>

                        {processedDecryptionList.map(({ key, value, isActive, currentSpeakerTime}) => (
                            <>
                                <div key={key} className={`${s.message_line} ${isActive ? 'text-primary' : ''}`}>
                                    {isNotDocument ? (
                                        <div
                                            className={`${s.speaker} text-truncate`}
                                            style={{
                                                width: 200,
                                                fontWeight: speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                                    ? '600'
                                                    : '',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <select
                                                    className="form-control form-select w-auto"
                                                    disabled={readonly}
                                                    type="text"
                                                    style={{
                                                        width: 'auto',
                                                        maxWidth: 180,
                                                    }}
                                                    value={value.speaker}
                                                    onChange={(e) => {
                                                        //setInputSpeaker({...inputSpeaker, name: e.target.value}
                                                        unlockButton()
                                                        speakerChange(
                                                            e.target.value,
                                                            key
                                                        )
                                                        SetEditSpeaker(-1)

                                                    }}
                                                >
                                                    <option
                                                        key={1000}
                                                        value={''}
                                                    ></option>
                                                    {speakers?.map(
                                                        (speaker, idx) => (
                                                            <option
                                                                key={idx}
                                                                value={
                                                                    speaker.name
                                                                }
                                                            >
                                                                {speaker.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>

                                            {/*
                                            <div style={{textTransform: 'uppercase', display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                                                {value.speaker}{" : "}
                                            </div>
                                            */}

                                            {timelist && timelist != '' && value.speakerTime ? (
                                                <div className="d-flex align-items-center mt-2">
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() =>
                                                            StartPlayWith(
                                                                value.speakerTime
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: 16,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <Moment format="HH:mm:ss">
                                                            {value.speakerTime}
                                                        </Moment>
                                                    </a>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className={s.text}>
                                        <TextareaAutoNew
                                            editable={readonly == false}
                                            selectedkey={focusedkey}
                                            SetFocusedKey={SetFocusedKey}
                                            searchtext={searchtext}
                                            isBold={
                                                !!speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                            }
                                            rowindex={key}
                                            speaker={value.speaker}
                                            value={value.text}
                                            OnAddParagraph={(
                                                rowindex,
                                                operation
                                            ) => {
                                                OnAddEmptyParagraph(
                                                    rowindex,
                                                    operation
                                                )
                                            }}
                                            OnRemoveParagraph={(rowindex) => {
                                                OnDeleteParagraph(
                                                    rowindex,
                                                    value
                                                )
                                            }}
                                            onBlurEditor={(value)=>{
                                                decryptionChange(value, key)
                                            }}
                                            onChange={(value) => {
                                                unlockButton()
                                              //  decryptionChange(value, key)
                                            }}
                                            tab={activeTab}
                                            OnSaveData={OnSaveData}
                                            hideSpeakers={isNotDocument == false}
                                            newchecked={newchecked}
                                            orderId={order?.id}
                                            showcheckboxes={showcheckboxeslist}
                                            onToggleCheckbox = {(checked)=>{

                                                if (onToggleCheckbox)
                                                {
                                                    onToggleCheckbox(order.id, key, checked)
                                                }
                                            }}
                                            skipped={skipped}
                                            timestamp={value.speakerTime}
                                            videoRef={videoRef}
                                            currentSpeakerTime={currentSpeakerTime}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    </animated.div>
                </TabPane>

                <TabPane tabId="Protocol" id="tasks" className="overflow-hidden" key={2}>
                    <animated.div  style={{...styles}}>
                    <div className="col">
                        <div
                            style={{
                                width: '100%',
                                height: fullscreen
                                    ? window.innerHeight - 320
                                    : '400px',
                                overflow: 'auto',
                                display: 'block',
                            }}
                        >
                            <div
                                className="d-flex"
                                style={{ paddingBottom: 4 }}
                            >
                                {/*
                                <div
                                    className={s.add_paragraph}
                                    onClick={() => {
                                        unlockButton()
                                        addProtocolParagraph('FIRST_PART')
                                    }}
                                >
                                    +
                                </div>
                                */}
                                <h5 className={s.paragraph_title} style={{ marginBottom:20}}>
                                    {'Резюме'}
                                </h5>
                            </div>
                            {protocolList && protocolList['FIRST_PART'] &&
                                Object.entries(protocolList['FIRST_PART']).map(
                                    ([key, paragraph]) => (<>
                                        <div className="d-flex" key={key+1}>
                                            {paragraph.indexOf(
                                                'Ключевые моменты'
                                            ) < 0 ? (
                                                <>
                                                <div
                                                    className={
                                                        s.delete_paragraph
                                                    }
                                                    onClick={() => {
                                                        unlockButton()
                                                        deleteProtocolParagraph(
                                                            key,
                                                            'FIRST_PART'
                                                        )
                                                        
                                                    }}
                                                >
                                                    <CloseIcon />
                                                </div>
                                                
                                                    <div
                                                    title={"Добавить параграф ниже"}
                                                    className={
                                                        s.delete_paragraph
                                                    }
                                                    style={{marginLeft: 10, }}
                                                    onClick={() => {
                                                        unlockButton()
                                                        addProtocolParagraph('FIRST_PART', key)
                                                    }}
                                                        >
                                                          <img src={PlusIconImg} style={{width: 25, height: 25}} />
                                                    </div>

                                                </>
                                            ) : null}

                                            <TextareaAuto
                                                searchtext={searchtext}
                                                isBold={false}
                                                value={paragraph}
                                                onSaveChanges={OnSaveData}
                                                onChange={(value) => {
                                                    //unlockButton()
                                                    protocolChange(
                                                        value,
                                                        key,
                                                        'FIRST_PART'
                                                    )
                                                }}
                                                tab={activeTab}
                                            />
                                        </div>
                                    </>)
                                )}

                            {showPart2 && protocolList && protocolList['SECOND_PART'] && Object.entries(protocolList['SECOND_PART']).length > 0 &&
                            <div
                                className="d-flex"
                                style={{ paddingBottom: 4 }}
                            >
                                {/*
                                <div
                                    className={s.add_paragraph}
                                    onClick={() => {
                                        unlockButton()
                                        addProtocolParagraph('SECOND_PART')
                                    }}
                                >
                                    +
                                </div>
                                */}
                                <div className={s.paragraph_title} style={{ marginBottom:20, marginTop:20}}>
                                    {'Следующие шаги'}
                                </div>
                            </div>}
                            {showPart2 && protocolList && protocolList['SECOND_PART'] && Object.entries(protocolList['SECOND_PART']).length > 0 &&
                                Object.entries(protocolList['SECOND_PART']).map(
                                    ([key, paragraph]) => (
                                        <div className="d-flex" key={key}>
                                            <>
                                            <div
                                                className={s.delete_paragraph}
                                                onClick={() => {
                                                    unlockButton()
                                                    deleteProtocolParagraph(
                                                        key,
                                                        'SECOND_PART'
                                                    )
                                                }}
                                            >
                                                <CloseIcon />
                                            </div>
                                                
                                                <div title={"Добавить параграф ниже"} className={ s.delete_paragraph }
                                                style={{marginLeft: 10, }}
                                                onClick={() => {
                                                    unlockButton()
                                                    addProtocolParagraph('SECOND_PART', key)
                                                }}
                                                    >
                                                     <img src={PlusIconImg} style={{width: 25, height: 25}} />
                                                </div>

                                            </>
                                            <TextareaAuto
                                                searchtext={searchtext}
                                                isBold={false}
                                                value={paragraph}
                                                onSaveChanges={OnSaveData}
                                                onChange={(value) => {
                                                    //unlockButton()
                                                    protocolChange(
                                                        value,
                                                        key,
                                                        'SECOND_PART'
                                                    )
                                                }}
                                                tab={activeTab}
                                            />
                                        </div>
                                    )
                                )}
                            
                        </div>
                    </div>
                    </animated.div>
                </TabPane>

                <TabPane tabId="Meeting Analytics" id="analitics" className="overflow-hidden" key={3}>
                    <animated.div  style={{...styles}}>
                    <div className="col">
                    <div
                            style={{
                                width: '100%',
                                height: fullscreen
                                    ? window.innerHeight - 320
                                    : '400px',
                                overflow: 'auto',
                                display: 'block',
                            }}
                        >
                            {Object.entries(analitic).map(([key, value]) => (
                                <div key={key}>
                                    
                                    {key == "success" ? <h2>{"Успешность"}</h2> : key == "communication" ? <h2>{"Коммуникативность"}</h2> : <h2>{key}<small>{", заинтересованность: "}{value["interest"] ?? ""}</small></h2>}

                                    {key == "success" ? value.split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}
                                    
                                    {key == "communication" ? value.split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}

                                    {value["sentiment"] ? <h4>{"Настроение"}</h4> : null}
                                    {value["sentiment"] ? value["sentiment"].split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}

                                    {value["emotion"] ? <h4>{"Эмоциональность"}</h4> : null}
                                    {value["emotion"] ? value["emotion"].split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}

                                    {value["toxicity"] ? <h4>{"Токсичность"}</h4> : null}
                                    {value["toxicity"] ? value["toxicity"].split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}

                                    {value["misunderstanding"] ? <h4>{"Недопонимание"}</h4> : null}
                                    {value["misunderstanding"] ? value["misunderstanding"].split("\n").map((item)=>{
                                        return <p>{item}</p>
                                    }) : null}
                                    
                                </div>
                            ))}

                        </div>
                    </div>
                    </animated.div>
                </TabPane>

               {detox || normalized || simplifed ? 
                <TabPane tabId="Detocs" id="about" className="overflow-hidden" key={4}>
                    <animated.div  style={{...styles}}>
                    <div
                        style={{
                            width: '100%',
                            height: fullscreen
                                ? window.innerHeight - 320
                                : '400px',
                            overflow: 'auto',
                        }}
                    >
                        {processedDecryptionList.map(({ key, value, isActive, currentSpeakerTime }) => (
                            <>
                                <div key={key} className={`${s.message_line} ${isActive ? 'text-primary' : ''}`}>
                                    {isNotDocument ? (
                                        <div
                                            className={`${s.speaker} text-truncate`}
                                            style={{
                                                width: 200,
                                                fontWeight: speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                                    ? '600'
                                                    : '',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                <b>
                                                  {value.speaker}
                                                </b>
                                            </div>

                                            {timelist &&
                                            timelist != '' &&
                                            value.speakerTime ? (
                                                <div className="d-flex align-items-center mt-2">
                                                    <a
                                                        href="javascript:void(0)"
                                                        onClick={() =>
                                                            StartPlayWith(
                                                                value.speakerTime
                                                            )
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                            fontSize: 16,
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <Moment format="HH:mm:ss">
                                                            {value.speakerTime}
                                                        </Moment>
                                                    </a>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    ) : null}
                                    <div className={s.text}>
                                        <TextareaAutoNew
                                            editable={false}
                                            selectedkey={focusedkey}
                                            SetFocusedKey={SetFocusedKey}
                                            searchtext={searchtext}
                                            isBold={
                                                !!speakers?.find(
                                                    (speaker) =>
                                                        speaker.name ===
                                                        value.speaker
                                                )?.role
                                            }
                                            rowindex={key}
                                            speaker={value.speaker}
                                            value={value.text}
                                            OnAddParagraph={(
                                                rowindex,
                                                operation
                                            ) => {
                                                OnAddEmptyParagraph(
                                                    rowindex,
                                                    operation
                                                )
                                            }}
                                            OnRemoveParagraph={(rowindex) => {
                                                OnDeleteParagraph(
                                                    rowindex,
                                                    value
                                                )
                                            }}
                                            onChange={(value) => {
                                                unlockButton()
                                                decryptionChange(value, key)
                                            }}
                                            tab={activeTab}
                                            OnSaveData={OnSaveData}
                                            hideSpeakers={isNotDocument == false}
                                            neutralizedText={neutralizedText}
                                            detoxifiedText={detoxifiedText}
                                            simplifedText={simplifedText}
                                            detox={detox}
                                            normalized={normalized}
                                            simplifed={simplifed}
                                            videoRef={videoRef}
                                            currentSpeakerTime={currentSpeakerTime}
                                        />
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                    </animated.div>
                </TabPane> : <></>}

            </TabContent>
        </div>
    )
}
