import { gql } from '@apollo/client'

const REGISTER = gql`
    mutation Register($id: String, $input: UserProfileInput) {
        createOrUpdateProfile(id: $id, input: $input) {
            id
            nickname
            f
            i
            o
            phone
            phoneConfirmed
            registerDateTime
            lastVisitDateTime
            AutoDecript
            tocken
        }
    }
`
const LOGIN = gql`
    mutation Login($phone: String!, $password: String!) {
        login(phone: $phone, password: $password) {
            id
            nickname
            f
            i
            o
            phone
            phoneConfirmed
            registerDateTime
            lastVisitDateTime
            AutoDecript
            tocken
        }
    }
`

const CHANGAPASSWORD = gql`
    mutation ChnageUserPassword($userId: String!, $password: String!) {
        changePassword(userId: $userId, password: $password)
    }
`
const UPLOADFILEFROMLINK = gql`
    mutation UploadFileFromLink($orderId: String!, $speakers: Int, $autoprotocol: Boolean, $resumeType: String, $decriptLang: String) {
        uploadUserLink(orderId: $orderId, speakers: $speakers, autoprotocol: $autoprotocol, resumeType: $resumeType, decriptLang: $decriptLang) {
            path
            filename
            mimetype
            encoding
            error
        }
    }
`

const UPLOADFILES = gql`
    mutation UploadFiles($userId: String!, $files: [Upload!]!) {
        uploadUserFiles(userId: $userId, files: $files) {
            path
            filename
            mimetype
            encoding
            error
        }
    }
`

const REMOVE_FILE = gql`
    mutation RemoveUserFile(
        $orderId: String
        $userId: String
        $filename: String
    ) {
        removeUserFile(orderId: $orderId, userId: $userId, filename: $filename)
    }
`
const REMOVE_ORDER = gql`
    mutation RemoveOrder($removeOrderId: String!) {
        removeOrder(id: $removeOrderId)
    }
`
const TRANSLATE_FILE = gql`
    mutation TranslateFile(
        $orderId: String
        $userId: String
        $filename: String
        $speakers: Int
        $autoprotocol: Boolean
        $resumeType: String
        $decriptLang: String
    ) {
        translateFile(
            orderId: $orderId
            userId: $userId
            filename: $filename
            speakers: $speakers
            autoprotocol: $autoprotocol
            resumeType: $resumeType
            decriptLang: $decriptLang
        ) {
            id
        }
    }
`
const EMAIL = gql`
    mutation Email($email: String, $message: String, $backemail: String) {
        sendmail(email: $email, message: $message, backemail: $backemail)
    }
`
const sendPhoneConfirmationMutation = gql`
    mutation AwaitCall($phone: String!) {
        awaitcall(phone: $phone)
    }
`

const UPDATE_ORDER_STRINGS = gql`
    mutation UpdateOrder($updateOrderId: String, $input: UpdateOrderInput) {
        updateOrder(id: $updateOrderId, input: $input) {
            lastCheckAnswer
            protocolAnswer
        }
    }
`

const UPDATE_ORDER_FILTERED_DECRIPTION = gql`
    mutation UpdateOrderFDecription($id: String, $filteredDecription: String) {
        updateOrderFilteredDecription(id: $id, filteredDecription: $filteredDecription) {
            lastCheckAnswerFiltered
        }
    }
`

const UPDATE_ORDER_TITLE = gql`
    mutation UpdateOrderTitle($id: String!, $title: String) {
        updateOrderTitle(id: $id, title: $title) {
            id
        }
    }
`

const ORDERPUBLISHED = gql`
    mutation OrderPublished($orderId: String!, $publicOrder: Boolean!) {
        setOrderPublic(orderId: $orderId, publicOrder: $publicOrder) {
            id
        }
    }
`

const UPDATE_DECRYPTION_SPEAKERS = gql`
    mutation ChangeSpeakerName(
        $orderId: String!
        $initialName: String!
        $newName: String!
    ) {
        changeSpeakerName(
            orderId: $orderId
            initialName: $initialName
            newName: $newName
        ) {
            speakersData
            lastCheckAnswer
        }
    }
`

const CREATE_DECRYPTION_SPEAKER = gql`
    mutation AddSpeakerName(
        $orderId: String!
        $initialName: String!
        $speakerName: String!
    ) {
        addSpeakerName(
            orderId: $orderId
            initialName: $initialName
            speakerName: $speakerName
        ) {
            speakersData
            lastCheckAnswer
        }
    }
`

const REMOVE_DECRYPTION_SPEAKER = gql`
    mutation RemoveSpeakerName($orderId: String!, $speakerName: String!) {
        removeSpeakerName(orderId: $orderId, speakerName: $speakerName) {
            speakersData
            lastCheckAnswer
        }
    }
`

const UPDATE_DECRYPTION_LEADS = gql`
    mutation ChangeSpeakerRole(
        $orderId: String!
        $initialName: String!
        $role: SpeakerRoles
    ) {
        changeSpeakerRole(
            orderId: $orderId
            initialName: $initialName
            role: $role
        ) {
            speakersData
        }
    }
`
const GENERATE_PROTOCOL = gql`
    mutation GenerateProtocol(
        $orderId: String!
        $input: GenerateProtocolQuery,
        $resumeType: String
    ) {
        generateProtocol(orderId: $orderId, input: $input, resumeType: $resumeType) {
            protocolAnswer
        }
    }
`

const GENERATE_ANALITIC = gql`
mutation GenerateAnalitic(
    $orderId: String!
    $input: GenerateAnaliticQuery
) {
    generateAnalitic(orderId: $orderId, input: $input) {
        analiticId
    }
}
`
const CHANGEPASSBYPHONE = gql`
    mutation ChangePassword($phone: String!, $password: String!) {
        changeUserPasswordByPhone(phone: $phone, password: $password)
    }
`

const CHANGEPASS = gql`
    mutation ChangePassword2(
        $oldpassword: String!
        $newpassword: String!
        $newpassword2: String!
    ) {
        changeUserPasswordByPassword(
            oldpassword: $oldpassword
            newpassword: $newpassword
            newpassword2: $newpassword2
        )
    }
`

const SAVETERMS = gql`
    mutation SaveTerms($id: String!, $terms: String) {
        changeUserTerms(id: $id, terms: $terms) {
            id
            terms
        }
    }
`

const ConfirmEmail = gql`
    mutation Confirm {
        confirmEmail
    }
`

const ADDCONSOLIDATION = gql`
    mutation AddConsolidation($orderId: String!, $userIds: [String!]!) {
        addConsolidation(orderId: $orderId, userIds: $userIds)
    }
`
const REMOVECONSOLIDATION = gql`
    mutation RemoveConsolidation($orderId: String!, $userIds: [String!]!) {
        removeConsolidation(orderId: $orderId, userIds: $userIds)
    }
`

const ADDTAG = gql`
mutation AddTag($name: String!, $color: String!, $parent: String) {
    createTag(name: $name, color: $color, parent: $parent)
    {
        id name color
    }
}
`
const REMOVETAG = gql`
mutation RemoveTag($id: String!) {
    removeTag(id: $id)
}
`
const UPDATEORDERTAGS = gql`
mutation UpdateOrderTags($orderId: String!, $tags: [String!]!) {
    orderTags(orderId: $orderId, tags: $tags) 
    {
        id
    }
}
`

const UPDATETAG = gql`
mutation UpdateTag($id: String!, $name: String, $color: String, $parent: String) {
    updateTag(id: $id, name: $name, color: $color, parent: $parent)
    {
        id name color
    }
}
`

const ADDLINK = gql`
mutation AddLink($name: String, $link: String, $linkDateTime: DateTime, $typeVKS: String, $multi: Boolean, $timezoneOffset: String) {
    addLink(name: $name, link: $link, linkDateTime: $linkDateTime, typeVKS: $typeVKS, multi: $multi, timezoneOffset: $timezoneOffset)
    {
        name
        link
        linkDateTime
        timezoneOffset
        updatedAt
        createdAt
    }
}
`

const REMOVELINK = gql`mutation RemoveLink($id: String!) {
    removeLink(id: $id)
}
`
const UPDATELINK = gql`
mutation UpdateLink($id: String!, $name: String, $link: String, $linkDateTime: String) 
{
    updateLink(id: $id, name: $name, link: $link, linkDateTime: $linkDateTime)
    {
        path
        filename
        mimetype
        encoding
        error
        userId
    }
}
`

const SENDEMAILDECRYPTIONS = gql`
    mutation sendEmailDecryptions($orderId: String!, $userEmails: [String!]!) {
        sendEmailDecryptions(orderId: $orderId, userEmails: $userEmails)
    }
`

const mutations = {
    REGISTER,
    LOGIN,
    CHANGAPASSWORD,
    UPLOADFILES,
    UPLOADFILEFROMLINK,
    REMOVE_FILE,
    TRANSLATE_FILE,
    ORDERPUBLISHED,
    EMAIL,
    sendPhoneConfirmationMutation,
    REMOVE_ORDER,
    UPDATE_ORDER_STRINGS,
    UPDATE_ORDER_TITLE,
    UPDATE_ORDER_FILTERED_DECRIPTION,
    UPDATE_DECRYPTION_SPEAKERS,
    CREATE_DECRYPTION_SPEAKER,
    REMOVE_DECRYPTION_SPEAKER,
    UPDATE_DECRYPTION_LEADS,
    GENERATE_PROTOCOL,
    GENERATE_ANALITIC,
    CHANGEPASSBYPHONE,
    CHANGEPASS,
    ConfirmEmail,
    SAVETERMS,
    ADDCONSOLIDATION,
    REMOVECONSOLIDATION,
    ADDTAG,
    REMOVETAG,
    UPDATEORDERTAGS,
    UPDATETAG,
    ADDLINK,
    REMOVELINK,
    UPDATELINK,
    SENDEMAILDECRYPTIONS
}

export default mutations
