import { useState } from "react";
import { getLoggedinUser } from "../helpers/api_helper";

import { useLazyQuery, useMutation } from '@apollo/client';

import graphql from "../graphql/gql"

const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile] = useState(
    userProfileSession ? userProfileSession : null
  );
  const userId = userProfile?.id ?? ""

  return { userProfile, loading, userId };
};

const useUsers = ({onDone}) => {
  const [users, SetUsers] = useState();
  const [user, SetUser] = useState();
  const [error, SetError] = useState("");

  const [TestPhone] = useLazyQuery(graphql.queries.TESTPHONE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onDone) onDone("testphone", data.testphone)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
});

const [UserChangePassword] = useMutation(graphql.mutations.CHANGEPASSBYPHONE,
  {
    onCompleted: (data) => {
       if (onDone) onDone("passwordchanged", data.changeUserPasswordByPhone)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
});   

const [ConfirmEmail] = useMutation(graphql.mutations.ConfirmEmail,
  {
    onCompleted: (data) => {
       if (onDone) onDone("sendconfirm", data.confirmEmail)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
});   

const [UserChangePasswordByPassword] = useMutation(graphql.mutations.CHANGEPASS,
  {
    onCompleted: (data) => {
       if (onDone) onDone("passwordchanged", data.changeUserPasswordByPassword)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
});   

  const [RegisterUser] = useMutation(graphql.mutations.REGISTER,
    {
      onCompleted: (data) => {

         if (onDone) onDone("register", data.createOrUpdateProfile)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [UpdateUserProfile] = useMutation(graphql.mutations.REGISTER,
    {
      onCompleted: (data) => {

         if (onDone) onDone("updateprofile", data.createOrUpdateProfile)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [LoginUser] = useMutation(graphql.mutations.LOGIN,
    {
      onCompleted: (data) => {
         if (onDone) onDone("login", data.login)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [LoadUser] = useLazyQuery(graphql.queries.USERDATA,
    {
        fetchPolicy: "network-only",
        onCompleted: (data) => {
          SetUser(data.user)
          if (onDone) onDone("user", data.user)
        },
        onError(error) {
          SetError(error.message)
          setTimeout(() => {
              SetError("")
          }, 2000)
        }
    });   

  const [LoadUserLight] = useLazyQuery(graphql.queries.USERDATA,
      {
          fetchPolicy: "network-only",
          onCompleted: (data) => {
            if (onDone) onDone("userlight", data.user)
          },
          onError(error) {
            SetError(error.message)
            setTimeout(() => {
                SetError("")
            }, 2000)
          }
  });   
    


  const [LoadUsers] = useLazyQuery(graphql.queries.USERSLIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        SetUsers(data.userslist.items)
        if (onDone) onDone("users", data.userslist)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
  });

  const [LoadTerms] = useLazyQuery(graphql.queries.LOADTERMS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        SetUsers(data.users)
        if (onDone) onDone("loadTerms", data.userTerms)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
  });

  const [SaveTerms] = useMutation(graphql.mutations.SAVETERMS,
    {
      onCompleted: (data) => {
         if (onDone) onDone("saveTerms", data.changeUserTerms)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [AddConsolidation] = useMutation(graphql.mutations.ADDCONSOLIDATION,
    {
      onCompleted: (data) => {
         if (onDone) onDone("newConsolidation", data.addConsolidation)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [RemoveConsolidation] = useMutation(graphql.mutations.REMOVECONSOLIDATION,
    {
      onCompleted: (data) => {
        if (onDone) onDone("removedConsolidation", data.removeConsolidation)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [UserTags] = useLazyQuery(graphql.queries.TAGS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onDone) onDone("tags", data.tags)
    },
    onError(error) {
        SetError(error.message)
        setTimeout(() => {
          SetError("")
        }, 2000)
    },
  });

  const [AddTag] = useMutation(graphql.mutations.ADDTAG,
    {
      onCompleted: (data) => {
        if (onDone) onDone("addtag", data.createTag)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [RemoveTag] = useMutation(graphql.mutations.REMOVETAG,
  {
      onCompleted: (data) => {
        if (onDone) onDone("removetag", data.removeTag)
      },
      onError(error) {
          SetError(error.message)
          setTimeout(() => {
            SetError("")
          }, 2000)
      },
  });

  const [UpdateOrderTags] = useMutation(graphql.mutations.UPDATEORDERTAGS,
    {
        onCompleted: (data) => {
          if (onDone) onDone("ordertags", data.orderTags)
        },
        onError(error) {
            SetError(error.message)
            setTimeout(() => {
              SetError("")
            }, 2000)
        },
    });

  const [UpdateTag] = useMutation(graphql.mutations.UPDATETAG,
    {
        onCompleted: (data) => {
          if (onDone) onDone("updatetag", data.updateTag)
        },
        onError(error) {
            SetError(error.message)
            setTimeout(() => {
              SetError("")
            }, 2000)
        },
    });

  const [AssignTagToOrder] = useMutation(graphql.mutations.REMOVECONSOLIDATION,
  {
          onCompleted: (data) => {
            if (onDone) onDone("ordertags", data.removeConsolidation)
          },
          onError(error) {
              SetError(error.message)
              setTimeout(() => {
                SetError("")
              }, 2000)
          },
  });  

  const [RemoveTagFromOrder] = useMutation(graphql.mutations.REMOVECONSOLIDATION,
    {
            onCompleted: (data) => {
              if (onDone) onDone("ordertags", data.removeConsolidation)
            },
            onError(error) {
                SetError(error.message)
                setTimeout(() => {
                  SetError("")
                }, 2000)
            },
    });  
  
return {users, user, error, LoadUsers, LoadUser, LoadUserLight, RegisterUser, LoginUser, TestPhone, UserChangePassword, UserChangePasswordByPassword, UpdateUserProfile, ConfirmEmail, LoadTerms, SaveTerms,
  AddConsolidation, RemoveConsolidation,
  UserTags, AddTag, RemoveTag, UpdateTag, AssignTagToOrder, RemoveTagFromOrder, UpdateOrderTags}
}

export { useProfile, useUsers };
