import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { animated } from '@react-spring/web';
import { useUsers } from '../../../Hooks/UserHooks'
import { EmailList } from './EmailList'

export const DecryptionEmailSender = React.memo(( { order} ) => {
  const [emailList, setEmailList] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("")

  useEffect(() => {
    const savedEmails = localStorage.getItem('emailList');
    if (savedEmails) {
      setEmailList(JSON.parse(savedEmails));
    }
  }, []);

  useEffect(() => {
    if (emailList.length > 0) {
      localStorage.setItem('emailList', JSON.stringify(emailList));
    }
  }, [emailList]);

  const handleEmailSelectChange = (selectedOption) => {
    setSelectedEmails(selectedOption ? [selectedOption] : []);
  };

  const handleEmailRemove = (emailToRemove) => {
    setEmailList((prev) => prev.filter((email) => email.value !== emailToRemove.value));
    setSelectedEmails((prev) => prev.filter((email) => email.value !== emailToRemove.value));
  };

  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const handleCreateEmail = (inputValue) => {
    if (emailPattern.test(inputValue)) {
      const newEmail = { value: inputValue, label: inputValue };
      setEmailList((prev) => [...prev, newEmail]);
      setSelectedEmails([newEmail]);
      setInputValue("")
      setSelectedEmails([])
      setEmailError("");
    } else {
      setEmailError("Некорректный email. Пожалуйста, проверьте введённый адрес.");
    }
  };

  const handleCheckboxChange = (email) => {
    setSelectedEmails((prev) =>
        prev.some((selected) => selected.value === email.value)
            ? prev.filter((selected) => selected.value !== email.value)
            : [...prev, email]
    );
  };

  const handleDeleteSelectedEmail = () => {
    if (selectedEmails) {
      handleEmailRemove(selectedEmails);
    }
  };

  const { sendEmailDecryptions } = useUsers({
    onDone: (type, data) => {
      if (data) {
        setSuccessMessage("Email успешно отправлен!");
        console.log("Emails успешно отправлены")
      }
    },
  })
  const sendEmail = async () => {
    if (!selectedEmails?.length || !order?.id) return;

    setLoading(true);
    setSuccessMessage("");
    const emails = selectedEmails.map(email => email.value)

    try {
      await sendEmailDecryptions({
        variables: { orderId: order.id, userEmails: emails }
      });
    } catch (error) {
      console.error("Ошибка при отправке email:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <>
          <div>
            <animated.div>
              <button
                  onClick={() => setShowEmailModal(true)}
                  className="btn btn-sm btn-outline-warning d-block d-md-inline-block mx-auto mt-2 mt-md-0"
                  style={{ height: 30 }}
              >
                <span>Отправить результаты на e-mail</span>
              </button>
            </animated.div>
          </div>

        <Modal
            className="modal-lg modal-fullscreen11"
            isOpen={showEmailModal}
            toggle={() => setShowEmailModal(false)}
            centered
        >
          <ModalHeader
              toggle={() => setShowEmailModal(false)}
          >Выберите или введите email для отправки результата встречи.
          </ModalHeader>
          <ModalBody
              className="px-3 py-3 d-flex align-items-center flex-column"
          >
            <EmailList
                emailList={emailList}
                selectedEmails={selectedEmails}
                handleEmailSelectChange={handleEmailSelectChange}
                handleCreateEmail={handleCreateEmail}
                handleCheckboxChange={handleCheckboxChange}
                handleEmailRemove={handleEmailRemove}
                inputValue={inputValue}
                setInputValue={setInputValue}
                emailError={emailError}
                successMessage={successMessage}
                setSuccessMessage={setSuccessMessage}
                loading={loading}
            />
          </ModalBody>
          <ModalFooter>
            <button
                className="btn btn-sm btn-outline-danger"
                onClick={handleDeleteSelectedEmail}
                disabled={loading || !selectedEmails.length}
            >
              Удалить
            </button>
            <button
                type="button"
                className="btn btn-sm btn-warning"
                onClick={sendEmail}
                disabled={loading || !selectedEmails.length }
            >
              {loading ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Отправка...
                  </>
              ) : (
                  <span className="text-dark">Отправить</span>
              )
              }
            </button>
          </ModalFooter>
        </Modal>
      </>
  );
});
