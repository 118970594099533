import {Card, Col, Form, Row} from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import React, { useEffect, useState } from "react";
import {TranslateContext} from "../../providers/Translate";
import { formatBytes } from "../../helpers/helpers_functions";
import { useDispatch, useSelector } from "react-redux";
import { useSpring, animated } from '@react-spring/web'

import {
  showRightSidebarAction,
  showSearchBarAction,
  showUploaderBarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

export const UploadBlock = ({
  maxFileSize,
  isFilesUploading,
  isFilesConverting,
  sendFiles,
  terminationDate
}) => {

  const dispatch = useDispatch();
  const {language} = React.useContext(TranslateContext)

  const [uploadMode, setUploadMode] = useState(true)
  const [selectedFiles, setSelectedFiles] = useState([])
  const deleteFileFromUpload = (file, index) => {
    const newFilesList = selectedFiles.filter((item, i) => i !== index)
    setSelectedFiles(newFilesList)
  }
  function handleAcceptedFiles(files) {
    files.forEach(file => {
      console.log(file)
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    })
    setSelectedFiles(files)
  }

  const isWrongChars = (file) => {
    var ch1 = file.indexOf('\'')>=0
    var ch2 = file.indexOf('"')>=0
    var ch3 = file.indexOf('(')>=0
    var ch4 = file.indexOf(')')>=0
    var ch5 = file.indexOf(':')>=0
    var ch6 = file.indexOf("&")>=0
    var ch7 = file.indexOf(";")>=0
    var ch8 = file.length>80
    //var ch7 = file.split(".")?.length>2
    return ch1 || ch2 || ch3 || ch4 || ch5 || ch6 || ch7 || ch8
  }

  const HasWrongFiles = () => {
    var wrongs = 0
    selectedFiles.map((f)=>
    {
      wrongs += isWrongChars(f.name) ? 1 : 0
    })

    return wrongs>0
  }

  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
  })

  return (
    <animated.div  style={{...styles}}>
    <div className="card">
      {/*
      <div className="card-header">
        <div className="row">
          <div className="col-8 text-center">
            <h3>{language["MyFiles"]}</h3>
          </div>
          <div className="col-4 text-end">
            <button type="button" className="btn btn-sm btn-primary" onClick={()=>setUploadMode(!uploadMode)}>
              {uploadMode ? language["Close file"] : language["New file"]}
            </button>
          </div>
        </div>
      </div>
      */}

        <div className="card-body">
          <div className="text-center w-100">
          {!terminationDate && <button type="button" className={"btn btn-primary " + (uploadMode ? "mb-3" : "")} onClick={()=>{
            dispatch(showUploaderBarAction(false)); 
            //setUploadMode(!uploadMode)
          }}>
              {uploadMode ? language["Close file"] : language["New file"]}
            </button>}

            {terminationDate && (new Date(terminationDate).setHours(0, 0, 0, 0)>=(new Date().setHours(0, 0, 0, 0))) && <button type="button" className={(uploadMode ? "btn btn-outline-secondary mb-3" : "btn btn-primary")} onClick={()=>{
                 dispatch(showUploaderBarAction(false)); 
                 //setUploadMode(!uploadMode)
            }}>
              {uploadMode ? language["Close file"] : language["New file"]}
            </button>}

            {terminationDate && (new Date(terminationDate).setHours(0, 0, 0, 0)<(new Date().setHours(0, 0, 0, 0))) && 
            <>
              <h3 className="text-danger">Закончился срок действия пакета.</h3>
              <p>
                Для приобретения пакета обратитесь в Таймлист:<br/>
                <b>Telegram:</b> <a target="_blank" href="https://t.me/timelist_talk_bot">@timelist_talk_bot</a><br/>
                <b>Телефон:</b> +7 495 489 20 10
              </p>
            </>}

            </div>

            {uploadMode && <div>
            <Form className="dropzone">
              <Dropzone onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                  <div style={{textAlign: "center"}}>
                    <div
                      className="dz-message needsclick d-flex align-items-center justify-content-center"
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div>
                        <div className="mt-5">
                          <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                        </div>
                        {document.location.host == 'i.timelist.ru' ? 
                        <h5 style={{maxWidth: 600}}>{language["Drop files here or click to upload for i"]}</h5> : 
                        <h5 style={{maxWidth: 600}}>{language["Drop files here or click to upload"]}</h5>}
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Form>

            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles.map((f, i) => (
                <Card
                  className={f.size > 100
                    ? "mt-1 mb-0 shadow-none border-red dz-processing dz-image-preview dz-success dz-complete"
                    : "mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  }
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={f.name}
                          src={f.preview}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          <strong>{f.name}</strong>
                        </Link>
                        <p className="mb-0">
                          { f.formattedSize }
                          { f.size > maxFileSize
                            ? <strong style={ { color: "red", marginLeft: "10px" } }>
                                { language["Too big size"] }
                              </strong>
                            : <></>
                          }
                        </p>
                      </Col>

                      <Col className="col-auto text-end">
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => deleteFileFromUpload(f, i)}
                        >
                          {language["Delete"]}
                        </button>
                      </Col>

                    </Row>
                    {isWrongChars(f.name) ? <Row>
                      <Col>
                       <string style={{color:"red"}}>
                          В имени файла содержатся запрещенные символы (, ), ",  :, ', ; либо лишние точки (точка должна быть только перед расширением файла!), длина имени не более 80 символов, переименуйте и попробуйте загрузить повторно...
                        </string>
                      </Col>
                    </Row> : <></>}
                  </div>
                </Card>
              ))}
            </div>
          </div>}
        </div>


      {(selectedFiles.length > 0 || isFilesUploading || isFilesConverting) ? 
      <div className='card-footer text-center'>
        { selectedFiles.length > 0 && !isFilesUploading && !HasWrongFiles() && 
          <button
            type="button"
            className="btn btn-primary"
            onClick={ () => {
              sendFiles(selectedFiles)
              setUploadMode(false)
              setSelectedFiles([])
            } }
          >
            { language["Convert files"] }
          </button>
        }
        { isFilesUploading  ?
          <div className="d-flex align-items-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Отправка файла на сервер...</span>
            </div>
            <b style={{marginLeft:20}}>{ selectedFiles.length == 1 ? language["Upload file"] : language["Upload file"] }</b>
          </div> : <></>
        }
        { isFilesConverting ?
          <div className="d-flex align-items-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Конвертация файла в mp3...</span>
            </div> 
            <b style={{marginLeft:20}}>{ language["Convert file to mp3"] }</b>
          </div>: <></>
        }
      </div> : <></> }
    </div>
    </animated.div>
  )
}
