// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components_checkbox__Puh-h {
  margin-right: 5px;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  border: 2px solid #4A75DA;
  outline: none;
  cursor: pointer; }
  .components_checkbox__Puh-h:hover {
    box-shadow: 1px 1px 1px #4A75DA; }

.components_checkbox__Puh-h:checked {
  background-color: #fff;
  position: relative; }

.components_checkbox__Puh-h:checked::before {
  content: "\\2714";
  font-size: 1.2em;
  color: #4A75DA;
  position: absolute;
  top: -2px;
  left: 2px; }

.components_icon__iBvou svg {
  animation-name: components_icon__iBvou;
  animation-duration: 300ms; }

@keyframes components_icon__iBvou {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.components_icon_wrapper__xsPeo i, .components_icon_wrapper__xsPeo svg {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; }
`, "",{"version":3,"sources":["webpack://./src/pages/MyFiles/components/components.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EAGjB,gBAAgB;EAEhB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,eAAe,EAAA;EAXjB;IAaI,+BAA+B,EAAA;;AAInC;EACE,sBAAsB;EACtB,kBAAkB,EAAA;;AAGpB;EACE,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,SAAS;EACT,SAAS,EAAA;;AAEX;EACE,sCAAoB;EACpB,yBAAyB,EAAA;;AAE3B;EACE;IACE,UAAU,EAAA;EAEZ;IACE,UAAU,EAAA,EAAA;;AAId;EAEI,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe,EAAA","sourcesContent":[".checkbox {\r\n  margin-right: 5px;\r\n\r\n  -webkit-appearance: none;\r\n  appearance: none;\r\n\r\n  width: 22px;\r\n  height: 22px;\r\n  border-radius: 5px;\r\n  border: 2px solid #4A75DA;\r\n  outline: none;\r\n  cursor: pointer;\r\n  &:hover{\r\n    box-shadow: 1px 1px 1px #4A75DA;\r\n  }\r\n}\r\n\r\n.checkbox:checked {\r\n  background-color: #fff;\r\n  position: relative;\r\n}\r\n\r\n.checkbox:checked::before {\r\n  content: \"\\2714\";\r\n  font-size: 1.2em;\r\n  color: #4A75DA;\r\n  position: absolute;\r\n  top: -2px;\r\n  left: 2px;\r\n}\r\n.icon svg {\r\n  animation-name: icon;\r\n  animation-duration: 300ms;\r\n}\r\n@keyframes icon {\r\n  from {\r\n    opacity: 0;\r\n  }\r\n  to {\r\n    opacity: 1;\r\n  }\r\n}\r\n\r\n.icon_wrapper {\r\n  i, svg {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    font-size: 20px;\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `components_checkbox__Puh-h`,
	"icon": `components_icon__iBvou`,
	"icon_wrapper": `components_icon_wrapper__xsPeo`
};
export default ___CSS_LOADER_EXPORT___;
