import { gql } from '@apollo/client'

const LOGIN = gql`
    query Login($useremail: String!, $password: String!) {
        login(user: $useremail, password: $password) {
            tocken
            error
            status
            profile {
                id
                avatar
                account
                UserF
                UserI
                email
                phone
                Actived
                money
            }
        }
    }
`

const ME = gql`
    query Me {
        me {
            id
            avatar
            background
            web3avatar
            UserF
            UserI
            UserO
            account
            bio
            phone
            www
            amazon
            phoneConfirmed
            email
            emailConfirmed
            google
            twitter
            facebook
            spotify
            AutoFollowBackfortheDurationofSubscription
            Free1month
            NotifyTopFans
            Top1
            Top5
            PricePerMonth
            ShowActivityStatus
            FullyPrivateProfile
            ShowfansCountonyourProfile
            ShowMediaCountonyourProfile
            PublicFriendsList
            EnableUsernameForwarding
            SuggestionsOptOut
            EnableCommentsPosts
            ShowPostsTipsSum
            Watermarks
            PushNotifications
            EmailNotifications
            ImportantSubscriptionNotifications
            NewComments
            NewLikes
            DiscountsfromusersIusedtofollow
            UpcomingStreamReminders
            ShowProfileURL
            ShowAvatar
            website
            wishlist
            money
            faceIDVerification
            appVerification
            smsVerification
        }
    }
`

const SUGGESTIONS = gql`
    query Suggestions($itemsCount: Int!) {
        suggestions(count: $itemsCount) {
            id
            avatar
            background
            web3avatar
            UserF
            UserI
            UserO
            account
            bio
            phone
            www
            amazon
            phoneConfirmed
            email
            emailConfirmed
            google
            twitter
            facebook
            spotify
            AutoFollowBackfortheDurationofSubscription
            Free1month
            NotifyTopFans
            Top1
            Top5
            PricePerMonth
            ShowActivityStatus
            FullyPrivateProfile
            ShowfansCountonyourProfile
            ShowMediaCountonyourProfile
            PublicFriendsList
            EnableUsernameForwarding
            SuggestionsOptOut
            EnableCommentsPosts
            ShowPostsTipsSum
            Watermarks
            PushNotifications
            EmailNotifications
            ImportantSubscriptionNotifications
            NewComments
            NewLikes
            DiscountsfromusersIusedtofollow
            UpcomingStreamReminders
            ShowProfileURL
            ShowAvatar
            website
            wishlist
            money
            faceIDVerification
            appVerification
            smsVerification
        }
    }
`

const PROFILE = gql`
    query Profile($id: String!) {
        profile(id: $id) {
            id
            avatar
            city {
                id
                nameDE
                nameENG
            }
            role
            UserF
            UserI
            UserO
            phone
            phoneConfirmed
            email
            emailConfirmed
            CompanyLocation
            Company
            gender
        }
    }
`

const STATS = gql`
    query Stats {
        stats {
            newnotifications
            newmessages
            totalprojects
            totalopenprojects
            totalclosedprojects
            totalusers
            totalcustomers
            totalemployees
        }
    }
`

const CHATMESSAGES = gql`
    query ChatMessages(
        $fromuser: String!
        $touser: String!
        $fromdatetime: DateTime
    ) {
        chatmessages(
            fromuser: $fromuser
            touser: $touser
            fromdatetime: $fromdatetime
        ) {
            id
            message
            readed
            files
            createdDate
            fromuser {
                id
            }
            touser {
                id
            }
        }
    }
`
const CHATS = gql`
    query Chats {
        chats {
            opponent {
                id
                avatar
                background
                account
                UserF
                UserI
                UserO
            }
        }
    }
`

const LOADCOMMENTS = gql`
    query Comments($postId: String!) {
        comments(postId: $postId) {
            id
            Deleted
            comment
            createdDate
            commentId {
                id
                owner {
                    id
                    avatar
                    account
                    UserF
                }
            }
            files
            owner {
                id
                avatar
                account
                UserF
                email
                phone
            }
        }
    }
`

const GET_USERS_SEARCH = gql`
    query UsersSearch($search: String!, $maxUsers: Int) {
        usersSearch(search: $search, maxUsers: $maxUsers) {
            id
            avatar
            background
            web3avatar
            UserF
            UserI
            UserO
            account
            bio
            phone
            www
            amazon
            phoneConfirmed
            email
            emailConfirmed
            google
            twitter
            facebook
            spotify
            AutoFollowBackfortheDurationofSubscription
            Free1month
            NotifyTopFans
            Top1
            Top5
            PricePerMonth
            ShowActivityStatus
            FullyPrivateProfile
            ShowfansCountonyourProfile
            ShowMediaCountonyourProfile
            PublicFriendsList
            EnableUsernameForwarding
            SuggestionsOptOut
            EnableCommentsPosts
            ShowPostsTipsSum
            Watermarks
            PushNotifications
            EmailNotifications
            ImportantSubscriptionNotifications
            NewComments
            NewLikes
            DiscountsfromusersIusedtofollow
            UpcomingStreamReminders
            ShowProfileURL
            ShowAvatar
            website
            wishlist
            faceIDVerification
            appVerification
            smsVerification
        }
    }
`

const USERSLIST = gql`
    query UsersList(
        $startKey: DateTime
        $page: Int
        $sorting: String
        $itemsCount: Int
        $search: String
        $searchCountry: String
    ) {
        userslist(
            startKey: $startKey
            page: $page
            sorting: $sorting
            itemsCount: $itemsCount
            search: $search
            searchCountry: $searchCountry
        ) {
            hasNext
            lastKey
            total
            items {
                id
                nickname
                avatar
                f
                i
                o
                phone
                email
                telegram
                telegramID
                registerDateTime
                lastVisitDateTime
                publications {
                    id
                    file
                    owner {
                        id
                    }
                    minutes
                    speakers
                    speakersData
                    createdAt
                    transaltedAt
                }
                Online
            }
        }
    }
`

const MOTORSLIST = gql`
    query MotorsList(
        $pageNumber: Int!
        $itemsPerPage: Int!
        $motorID: String
        $fabrikat: String
        $manufacturer: String
        $motortype: String
        $minpowerkW: String
        $maxpowerkW: String
        $minspeedRpm: String
        $maxspeedRpm: String
        $minvoltageVolts: String
        $maxvoltageVolts: String
        $mincurrentConsumptionInAmperes: String
        $maxcurrentConsumptionInAmperes: String
        $minfrequencyHz: String
        $maxfrequencyHz: String
        $isoClass: String
        $serialFactorynumber: String
        $Motordesign: String
        $mindegreeOfprotection: String
        $maxdegreeOfprotection: String
        $Motortranslation: String
        $mintorque: String
        $maxtorque: String
        $minMotorsize: String
        $maxMotorsize: String
        $minefficiency: String
        $maxefficiency: String
        $mincosPhi: String
        $maxcosPhi: String
        $maximoNo: String
        $AMBNo: String
        $posNo: String
        $search: String
    ) {
        motors(
            pageNumber: $pageNumber
            itemsPerPage: $itemsPerPage
            search: $search
            motorID: $motorID
            fabrikat: $fabrikat
            manufacturer: $manufacturer
            motortype: $motortype
            minpowerkW: $minpowerkW
            maxpowerkW: $maxpowerkW
            minspeedRpm: $minspeedRpm
            maxspeedRpm: $maxspeedRpm
            minvoltageVolts: $minvoltageVolts
            maxvoltageVolts: $maxvoltageVolts
            mincurrentConsumptionInAmperes: $mincurrentConsumptionInAmperes
            maxcurrentConsumptionInAmperes: $maxcurrentConsumptionInAmperes
            minfrequencyHz: $minfrequencyHz
            maxfrequencyHz: $maxfrequencyHz
            isoClass: $isoClass
            serialFactorynumber: $serialFactorynumber
            Motordesign: $Motordesign
            mindegreeOfprotection: $mindegreeOfprotection
            maxdegreeOfprotection: $maxdegreeOfprotection
            Motortranslation: $Motortranslation
            mintorque: $mintorque
            maxtorque: $maxtorque
            minMotorsize: $minMotorsize
            maxMotorsize: $maxMotorsize
            minefficiency: $minefficiency
            maxefficiency: $maxefficiency
            mincosPhi: $mincosPhi
            maxcosPhi: $maxcosPhi
            maximoNo: $maximoNo
            AMBNo: $AMBNo
            posNo: $posNo
        ) {
            id
            article
            manufacturer {
                id
                nameDE
                nameENG
            }
            fabrikat {
                id
                nameDE
                nameENG
            }
            type
            powerkW
            speedRpm
            voltageVolts
            currentConsumptionInAmperes
            frequencyHz
            isoClass
            serialFactorynumber
            design
            degreeOfprotection
            translation
            torque
            size
            efficiency
            cosPhi
            maximoNo
            AMBNo
            posNo
            comments
            createdDate
            updatedDate
        }
    }
`

const WorkTypesEditRows = gql`
    query WorkTypesEditRowsGet {
        WorkTypesEditRows {
            id
            nameDE
            nameENG
            expanded
            Actived
            parentid {
                id
            }
        }
    }
`
const ProductTypesEditRows = gql`
    query ProductTypesEditRowsGet {
        ProductTypesEditRows {
            id
            nameDE
            nameENG
            expanded
            Actived
            parentid {
                id
            }
        }
    }
`
const ManufacturersEditRows = gql`
    query ManufacturersEditRowsGet {
        ManufacturersEditRows {
            id
            nameDE
            nameENG
            expanded
            Actived
            parentid {
                id
            }
        }
    }
`
const ProjectStatusEditRows = gql`
    query ProjectStatusEditRowsGet {
        ProjectStatusEditRows {
            id
            nameDE
            nameENG
            expanded
            required
            step
            color
            Actived
            parentid {
                id
            }
        }
    }
`
const RegionsRows = gql`
    query RegionsRowsGet {
        RegionsRows {
            id
            nameDE
            nameENG
            expanded
            Actived
            parentid {
                id
            }
        }
    }
`

const ProjectsPage = gql`
    query ProjectsPage(
        $fromKey: DateTime
        $page: Int
        $count: Int!
        $owner: String
        $source: String
        $ordernumber: String
        $dateentry: String
        $customername: String
        $billnumber: String
        $order: String
        $details: String
        $status: String
        $team: String
        $inteamuser: String
    ) {
        projects(
            fromKey: $fromKey
            page: $page
            count: $count
            owner: $owner
            source: $source
            ordernumber: $ordernumber
            dateentry: $dateentry
            customername: $customername
            billnumber: $billnumber
            order: $order
            details: $details
            status: $status
            team: $team
            inteamuser: $inteamuser
        ) {
            projects {
                id
                source
                orderNumber
                orderName
                description
                customer {
                    id
                    phone
                    avatar
                    email
                    UserF
                    UserI
                    UserO
                }
                manager {
                    id
                    phone
                    avatar
                    email
                    UserF
                    UserI
                    UserO
                }
                employee {
                    id
                    phone
                    avatar
                    email
                    UserF
                    UserI
                    UserO
                }
                entryDate
                entryTime
                scheduling
                schedulingDays
                schedulingDateTime
                resourcesPlanning
                product {
                    id
                }
                motor {
                    id
                    article
                    manufacturer {
                        id
                        nameDE
                        nameENG
                    }
                    fabrikat {
                        id
                        nameDE
                        nameENG
                    }
                    type
                }
                labels
                files
                createdDate
                updatedDate
                status {
                    id
                    nameDE
                    nameENG
                    color
                    step
                }
            }
            totalprojects
        }
    }
`

const PROJECT = gql`
    query ProjectsPage($id: String!) {
        project(id: $id) {
            id
            source
            orderNumber
            orderName
            description
            customer {
                id
                phone
                email
                UserF
                UserI
                UserO
            }
            manager {
                id
                phone
                email
                UserF
                UserI
                UserO
            }
            employee {
                id
                phone
                email
                UserF
                UserI
                UserO
            }
            entryDate
            entryTime
            scheduling
            schedulingDays
            schedulingDateTime
            resourcesPlanning
            product {
                id
            }
            motor {
                id
                article
                manufacturer {
                    id
                    nameDE
                    nameENG
                }
                fabrikat {
                    id
                    nameDE
                    nameENG
                }
                type
                powerkW
                speedRpm
                voltageVolts
                currentConsumptionInAmperes
                frequencyHz
                isoClass
                serialFactorynumber
                design
                degreeOfprotection
                translation
                torque
                size
                efficiency
                cosPhi
                maximoNo
                AMBNo
                posNo
                comments
            }
            labels
            files
            createdDate
            updatedDate
            status {
                id
                nameDE
                nameENG
                color
            }
        }
    }
`

const USERCHATS = gql`
    query UserChats($userId: String) {
        chats(userId: $userId) {
            opponent {
                id
                avatar
                email
                UserF
                UserI
                UserO
            }
            messagesCount
            lastmessage
            lastmessagedatetime
        }
    }
`

const APIANDKEYS = gql`
    query LoadKeys {
        keysFieldList {
            id
            name
            value
            updatedDate
        }
    }
`

const TESTPHONE = gql`
    query TestPhone($phone: String!) {
        testphone(phone: $phone)
    }
`

const LOAD_FILES_LIST = gql`
    query Orders(
        $userId: String
        $page: Int
        $itemsperpage: Int
        $searchtext: String
    ) {
        orders(
            userId: $userId
            page: $page
            itemsperpage: $itemsperpage
            searchtext: $searchtext
        ) {
            orders {
                id
                owner {
                    id
                    f
                    i
                    o
                    email
                    phone
                }
                durationSeconds
                file
                title
                filetype
                filesize
                minutes
                speakers
                speakersData
                leadSpeaker
                cost
                paid
                translation
                uploadResult
                uploadId
                lastCheckAnswer
                error
                summaryAnswer
                protocolAnswer
                uploadLastCheck
                lastCheckStatus
                time_code
                detoxified_text
                neutralised_text
                simplifed_text
                audio_anl_value
                audio_anl_message
                uploadProtocol
                protocolStatus
                analitic
                analiticStatus
                version
                uploadProtocolResult
                uploadTime
                transaltedAt
                resumeType
                public
                createdAt
            }
            files
            durations
            sizes
            totalOrders
            totalOrdersQuery
            orgdata {
                id
                name
                inn
                minutes
                domain
                orderDateTermination
                database
            }
        }
    }
`

const LOAD_SEARCH_LIST = gql`
    query PopularSearchLoad($fragment: String) {
        popularsearch(fragment: $fragment) {
            id
            owner {
                id
            }
            search
            complitedCounts
            results {
                id
            }
            lastExecute
            variants
        }
    }
`

const PRESETS = gql`
    query Presets($names: [String]) {
        presetslist(names: $names) {
            name
            value
        }
    }
`


const phoneConfirmationHasCall = gql`
    query HasCall($phone: String!) {
        hasawaitedcall(phone: $phone) {
            phone
            code
            callbackphone
            callback
            sendDateTime
            callbackDateTime
        }
    }
`
const INBOX = gql`
    query InboxSms($cnt: Int!, $hour: Int) {
        smscruinbox(cnt: $cnt, hour: $hour)
    }
`

const DOWNLOAD_DOCS = gql`
    query DownloadOrderFile(
        $orderId: String!
        $field: OrderAnswerField!
        $format: OrderAnswerFileFormat!
    ) {
        downloadOrderFile(orderId: $orderId, field: $field, format: $format) {
            filename
            b64string
        }
    }
`

const USERDATA = gql`
    query LoadUser($id: String) {
        user(id: $id) {
            id
            country {
                id
            }
            nickname
            f
            i
            o
            phone
            phoneConfirmed
            email
            emailConfirmed
            telegram
            telegramID
            publications {
                id
                file
                owner {
                    id
                }
                minutes
                speakers
                speakersData
                createdAt
                transaltedAt
            }
            consolidations {
                id
                order {
                    id
                    file
                    owner {
                        id
                    }
                    minutes
                    speakers
                    speakersData
                    createdAt
                    transaltedAt
                }
            }
            registerDateTime
            lastVisitDateTime
            balance
            minutes
            Online
            Admin
            pushdelivery
            AlterProtoServer
            AutoDecript
        }
    }
`

const CountriesList = gql`
    query LoadCountries {
        countries {
            id
            Country_ru
            Country_eng
            Country_phonecode
            PhoneMask
            Actived
            ActivedOnPhone
        }
    }
`

const LOADTERMS = gql`
    query Loadterms($id: String!) {
        userTerms(id: $id)
    }
`

const ORGDATA = gql`
    query LoadOrgData($url: String!) {
        orgbyurl(url: $url) {
            id
            name
            inn
            owner {
                id
            }
            minutecost
            tarif {
                id
            }
            registerByPhone
            registerByEmail
            phoneConfirmation
            emailConfirmation
            orderDateTermination
            Actived
        }
    }
`

const queries = {
    ME,
    LOGIN,
    PROFILE,
    SUGGESTIONS,
    STATS,
    CHATMESSAGES,
    CHATS,
    LOADCOMMENTS,
    GET_USERS_SEARCH,
    USERSLIST,
    MOTORSLIST,
    ProjectsPage,
    PROJECT,
    WorkTypesEditRows,
    ProductTypesEditRows,
    RegionsRows,
    ManufacturersEditRows,
    ProjectStatusEditRows,
    USERCHATS,
    APIANDKEYS,
    TESTPHONE,
    PRESETS,
    phoneConfirmationHasCall,
    CountriesList,
    INBOX,
    LOAD_FILES_LIST,
    LOAD_SEARCH_LIST,
    DOWNLOAD_DOCS,
    USERDATA,
    LOADTERMS,
    ORGDATA,
}

export default queries
