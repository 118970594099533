import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Icon from 'react-eva-icons'
import {
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
} from 'reactstrap'

import SimpleBar from 'simplebar-react'

//Import images
import logoDarkSm from '../../assets/images/logo-dark-sm.png'
import logoLight from '../../assets/images/logo-light.png'
import logoLightSm from '../../assets/images/logo-light-sm.png'
import logoCabinet from '../../assets/images/logo.png'
import logoCabinetSm from '../../assets/images/logo_sm.png'

//import language flag images
import usFlag from '../../assets/images/flags/us.jpg'
import spainFlag from '../../assets/images/flags/spain.jpg'
import germanyFlag from '../../assets/images/flags/germany.jpg'
import italyFlag from '../../assets/images/flags/italy.jpg'
import russiaFlag from '../../assets/images/flags/russia.jpg'

//import webApps images
import gitHub from '../../assets/images/brands/github.png'
import bitbucket from '../../assets/images/brands/bitbucket.png'
import dribbble from '../../assets/images/brands/dribbble.png'
import dropbox from '../../assets/images/brands/dropbox.png'
import mail_chimp from '../../assets/images/brands/mail_chimp.png'
import slack from '../../assets/images/brands/slack.png'

//user(avatar) Image
import avatarImage1 from '../../assets/images/users/avatar-1.jpg'
import avatarImage3 from '../../assets/images/users/avatar-3.jpg'
import avatarImage6 from '../../assets/images/users/avatar-6.jpg'
import avatarEmpty from '../../assets/images/users/noavatar.png'
import { Link } from 'react-router-dom'
import BreadCrumb from '../../components/Common/BreadCrumb'

import { connect } from 'react-redux'

//redux
import { useDispatch, useSelector } from 'react-redux'

// Redux Store
import {
    showRightSidebarAction,
    showSearchBarAction,
    showUploaderBarAction,
    toggleLeftmenu,
    changeSidebarType,
} from '../../store/actions'

import { useUsers } from '../../Hooks/UserHooks'
import { useProfile } from '../../Hooks/UserHooks'
import { TranslateContext } from '../../providers/Translate'

const Header = ({
    breadCrumbItems,
    showRightSidebar,
    showSearchBar,
    showUploaderBar,
    minutecost,
    balance,
}) => {
    const dispatch = useDispatch()

    const { language } = React.useContext(TranslateContext)
    const { userProfile, loading } = useProfile()
    const [opened, SetOpened] = useState(false)

    //Seacrh dropdown
    const [search, setSearch] = useState(false)
    const toggleSearch = () => {
        setSearch(!search)
    }

    //Language Dropdown
    const [languages, setLanguage] = useState(false)
    const toggleLanguage = () => {
        setLanguage(!languages)
    }

    //Webapps Dropdown
    const [webapps, setWebapps] = useState(false)
    const toggleWebapps = () => {
        setWebapps(!webapps)
    }

    //Notification Dropdown
    const [notification, setNotification] = useState(false)
    const toggleNotification = () => {
        setNotification(!notification)
    }

    //Profile Dropdown
    const [profile, setProfile] = useState(false)
    const toggleProfile = () => {
        setProfile(!profile)
    }

    //scroll navbar
    const [navClass, setnavClass] = useState(false)
    useEffect(() => {
        window.addEventListener('scroll', scrollNavigation, true)
    })

    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop
        if (scrollup > 50) {
            setnavClass('sticky')
        } else {
            setnavClass('')
        }
    }

    function tToggle() {
        var body = document.body
        if (window.screen.width <= 998) {
            body.classList.toggle('sidebar-enable')
        } else {
            body.classList.toggle('sidebar-enable')
            body.setAttribute('data-sidebar-size', 'lg')
        }

        SetOpened(body.classList.contains('sidebar-enable'))
    }

    useEffect(() => {
        //  console.log(balance)
    }, [balance])

    return (
        <React.Fragment>
            <header
                id="page-topbar"
                className={'isvertical-topbar ' + navClass}
            >
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box d-none">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logoCabinetSm} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoCabinet} alt="" height="22" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-lg">
                                    <img src={logoCabinet} alt="" height="22" />
                                </span>
                                <span className="logo-sm">
                                    <img src={logoCabinetSm} alt="" height="22" />
                                </span>
                            </Link>
                        </div>

                        <button
                            type="button"
                            className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn topnav-hamburger"
                            style={{
                                transform: opened
                                    ? 'translateX(0%) translateY(0%) rotate(180deg)'
                                    : 'translateX(0%) translateY(0%) rotate(0deg)',
                            }}
                            onClick={() => tToggle()}
                        >
                            <div className="hamburger-icon open">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>

                        <BreadCrumb breadCrumbItems={breadCrumbItems} />
                    </div>
                    <div className="d-flex align-items-center">
                       

                        {!showSearchBar && (
                            <div className="dropdown d-inline-block">
                                <button
                                    onClick={() => {
                                        dispatch(
                                            showSearchBarAction(!showSearchBar)
                                        )
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                    }}
                                    type="button"
                                    className="btn header-item noti-icon right-bar-toggle"
                                    id="right-bar-toggle"
                                >
                                    <Icon
                                        name="search-outline"
                                        fill="#555b6d"
                                        size="large"
                                    />
                                </button>
                            </div>
                        )}

                        <div className="dropdown d-none d-md-inline-block">
                            <button
                                className="btn header-item"
                                type="button"
                                onClick={() => {}}
                            >
                                <b>Баланс</b>
                                <h4
                                    style={{
                                        display: 'inline',
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                >
                                    {balance?.balance ?? 0}
                                </h4>
                                мин.
                            </button>
                        </div>

                        <div className="dropdown d-none d-md-inline-block">
                            <button
                                className="btn header-item"
                                type="button"
                                onClick={() => {}}
                            >
                                <b>Расшифровки</b>
                                <h4
                                    style={{
                                        display: 'inline',
                                        marginLeft: 5,
                                        marginRight: 5,
                                    }}
                                >
                                    {balance?.totalOrders ?? 0}
                                </h4>
                                шт.
                            </button>
                        </div>

                        {!showUploaderBar && (
                            <div className="dropdown d-inline-block" style={{ marginLeft: 10, marginRight: 10 }}>
                                <button
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth',
                                        })
                                        dispatch(
                                            showUploaderBarAction(
                                                !showUploaderBar
                                            )
                                        )
                                    }}
                                    type="button"
                                    className="btn btn-primary right-bar-toggle"
                                    id="right-bar-toggle2"
                                >
                                    {showUploaderBar
                                        ? 'Скрыть загрузку'
                                        : 'Загрузить/записать'}
                                </button>
                            </div>
                        )}

                        {/*
                        <div className="dropdown d-inline-block">
                            <button className="btn header-item noti-icon right-bar-toggle" type="button" onClick={()=>{}}>
                                    <i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i>
                                    <b>1 мин.</b> - <b>{minutecost} руб.</b>
                            </button>
                        </div>
                        */}
                        {/*
                        <div className="dropdown d-inline-block">
                            <button
                                onClick={() => {
                                    dispatch(
                                        showRightSidebarAction(
                                            !showRightSidebar
                                        )
                                    )
                                }}
                                type="button"
                                className="btn header-item noti-icon right-bar-toggle"
                                id="right-bar-toggle"
                            >
                                <Icon
                                    name="settings-outline"
                                    fill="#555b6d"
                                    size="large"
                                />
                            </button>
                        </div>*/}

                        <Dropdown
                            className="d-inline-block"
                            isOpen={profile}
                            toggle={toggleProfile}
                        >
                            <DropdownToggle
                                tag="a"
                                type="button"
                                className="btn header-item user text-start d-flex align-items-center"
                                id="page-header-user-dropdown"
                                to="#"
                            >
                                <img
                                    className="rounded-circle header-profile-user"
                                    src={avatarEmpty}
                                    alt="Header Avatar"
                                />
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end pt-0">
                                <div className="p-3 border-bottom">
                                    <h6 className="mb-0">
                                        {userProfile?.phohe}
                                    </h6>
                                    {/*  <p className="mb-0 font-size-11 text-muted">jennifer.bennett@email.com</p>   */}
                                </div>
                                <DropdownItem href="/faq">
                                    <i className="mdi mdi-lifebuoy text-muted font-size-16 align-middle me-1"></i>{' '}
                                    <span className="align-middle">FAQ</span>
                                </DropdownItem>
                                <div className="dropdown-divider"></div>
                                {/* <DropdownItem href="#"><i className="mdi mdi-wallet text-muted font-size-16 align-middle me-1"></i> <span className="align-middle">{context.langdata[context.language]["Balance"]} : <b>$6951.02</b></span></DropdownItem> */}
                                <DropdownItem href="/auth-logout">
                                    <i className="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>{' '}
                                    <span className="align-middle">
                                        {language['Logout']}
                                    </span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
            </header>
        </React.Fragment>
    )
}

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
        state.Layout
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType,
})(Header)
