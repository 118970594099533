import React, { useEffect } from "react";
import { TranslateContext } from "../../../providers/Translate";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  UploadIcon,
  FailureStatusIcon,
  PaymentErrorIcon,
  PendingIcon,
  StartedIcon,
  SuccessStatusIcon,
} from "../../../assets/images/icons";
import s from "./components.module.scss";
import { Modal } from "reactstrap";

import config from "../../../config";
import {IsDataFile} from "../FileBlock";

import { useProfile, useUsers } from '../../../Hooks/UserHooks'

import { useSpring, animated } from '@react-spring/web'

export const NewFilePanel = ( { fromUser, canDelete, deleteFile, fileStatus, onHelp, waitingTime, startTranslationFile, fileName, isIdExist, toggleSpeakersMobile, onStartTranslationFile, onCancelTranslationFile, protocolStatus, filesize, OnCreateResume, downloadDocs, copyDocument } ) => {
  
  const styles = useSpring({
    from: {
      opacity: 0,
      y: '10%',
    },
    to: {
      opacity: 1,
      y: '0%',
    },
  })


  const { language } = React.useContext(TranslateContext)
  const { userProfile } = useProfile()

  const [speakers, setSpeakers] = React.useState(0)
  const speakersCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [isOpen, setIsOpen] = React.useState(false)
  const [autoproto, setAutoProto] = React.useState(false)
  const [time, setTime] = React.useState(0) //localStorage.getItem("time_"+fileName) ?? 0)
  const [time2, setTime2] = React.useState(-1)//localStorage.getItem("time2_"+fileName) ?? -1)
  const [percentes, setPercentes] = React.useState(0)
  const [percentes2, setPercentes2] = React.useState(0)
  const [autoclick, setAutoClick] = React.useState(true)
  const [autoclick2, setAutoClick2] = React.useState(true)
  const [resumeType, setResumeType] = React.useState("None")
  const [decriptLang, setLanguage] = React.useState("")

  const processIcons = {
    UPLOADED: <UploadIcon/>,
    SENT: <StartedIcon/>,
    STARTED: <StartedIcon/>,
    PENDING: <PendingIcon/>,
    NOT_PAID: <PaymentErrorIcon/>,
    FAILURE: <FailureStatusIcon/>,
    SUCCESS: <SuccessStatusIcon/>,
  }
  //React.useEffect(() => fileStatus === 'UPLOADED' && setIsOpen(true), [fileStatus])

  useEffect(() => {
    if (isOpen)
    {
      onStartTranslationFile()
    } 
  }, [isOpen])

  useEffect(() => {
    if (!IsDataFile(fileName) && fileStatus == 'UPLOADED' && userProfile?.AutoDecript == true && time<15 && autoclick)
    {
      //Implementing the setInterval method
      let tm = setTimeout(() => {
        setTime(time + 1);
      }, 1000);
    
      return () => clearTimeout(tm);
    }

    if (IsDataFile(fileName) && protocolStatus != 'SUCCESS' && protocolStatus != "PENDING" && userProfile?.AutoDecript == true && time<15 && autoclick)
    {
        //Implementing the setInterval method
        let tm = setTimeout(() => {
          setTime(time + 1);
        }, 1000);
      
        return () => clearTimeout(tm);
    }
 }, [time]);

 useEffect(() => {
  
  if (!IsDataFile(fileName) && time2>=0 && time2<15  && userProfile?.AutoDecript == true && autoclick2)
  {
    //Implementing the setInterval method
    let tm2 = setTimeout(() => {
      setTime2(time2 + 1);
    }, 1000);
  
    return () => clearTimeout(tm2);
  }
}, [time2]);

  useEffect(() => {
    setPercentes(100 * (time/15))
    localStorage.setItem("time_"+fileName, time)
  }, [time])

  useEffect(() => {
    setPercentes2(100 * (time2/15))
    localStorage.setItem("time2_"+fileName, time2)
  }, [time2])


  useEffect(() => {
    if (percentes>=100)// && location.host == "debug.timelist.ru")
    {
      if (!IsDataFile(fileName))
      {
        setTime2(0)
        setAutoClick2(true)
        setIsOpen(true)
      } else 
      {
        setTime(0)
        setAutoClick(false)
        if (OnCreateResume) OnCreateResume(localStorage.getItem("resumeType") ?? 'None')
      }
    }      
  }, [percentes])

  useEffect(() => {
    if (percentes2>=100)// && location.host == "debug.timelist.ru")
    {
      startTranslationFile(speakers, autoproto)
      setAutoClick2(false)
      setTime2(-1)
      setIsOpen(!isOpen)
    }      
  }, [percentes2])
  
  useEffect(() => {
    if (isOpen)
    {
      setAutoClick2(false)
      setTime2(0)
    }
  }, [speakers, decriptLang, resumeType, autoproto])


  return (
    <animated.div  style={{...styles}}>
    {!IsDataFile(fileName) && <div className="row mt-3">
      <div className='d-flex col-auto' style={ { maxHeight: 38 }}>
        <div className='d-flex align-items-center me-2'>
          { language.fileStatus[fileStatus] }
          { (fileStatus === 'PENDING' || fileStatus === 'STARTED') && waitingTime.length && <div className='ms-2'>{ Math.ceil(Number(waitingTime[1]) / 60) }</div> }
          { (fileStatus === 'PENDING' || fileStatus === 'STARTED') && <div className='ms-2'>{language['Min']}</div> }
        </div>
        <div className='d-flex align-items-center'>{ processIcons[fileStatus] }</div>
      </div>
      </div>}

      
      <div className="row mt-3">
        
      { fileStatus === 'NOT_PAID' &&
        <div className="col-auto" style={ { width: 128, maxHeight: 38, display: 'flex' } }>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={ () => 
            {
              setPercentes(0)
              setTime(0)
              setAutoClick(false)
              setIsOpen(true) 
            }}
          >
            { language['ContinueTranscript'] }
          </button>
        </div>
      }

      { (fileStatus === 'UPLOADED' || fileStatus === 'FAILURE') && !IsDataFile(fileName) &&
        <div className="col-auto" style={ { width: 150, maxHeight: 38, display: 'flex' } }>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            style={{background: userProfile?.AutoDecript == true && `linear-gradient(90deg, #0f1c42 ${percentes}%, #3b76e1 ${percentes}%)`}}
            onClick={ () => 
              {
                setPercentes(0)
                setTime(0)
                setAutoClick(false)
                setIsOpen(true) 
              }}
          >
            {language['ContinuePickSpeakers']}

          </button>
        </div>
      }

      {protocolStatus === "PENDING" &&  fileStatus === 'UPLOADED' && IsDataFile(fileName) && <div className="col-auto d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary m-1" role="status">
            <span className="sr-only">Loading...</span>
        </div>
        <div style={{marginLeft: 10}}>{"подготовка резюме..."}</div>
      </div>}

      {protocolStatus != "PENDING" &&  fileStatus === 'UPLOADED' && IsDataFile(fileName) &&
        <div className="col-auto" style={ {  maxHeight: 38, display: 'flex' } }>

          {document.location.host != "i.timelist.ru" ? 
          <div className='d-flex align-items-center justify-content-center'>

            <UncontrolledDropdown className="dropdown float-start" disabled={!!protocolStatus && protocolStatus !== 'FAILURE' && protocolStatus !== 'NOT_EXIST' && protocolStatus !== 'SUCCESS'}>
                  <DropdownToggle tag="a" className="text-muted dropdown-toggle primary">
                      <button  className='btn btn-primary'
                            style={{width:200, background: userProfile?.AutoDecript == true && `linear-gradient(90deg, #0f1c42 ${percentes}%, #3b76e1 ${percentes}%)`}}
                      >{'Создать резюме'}</button>
                  </DropdownToggle>
                  <DropdownMenu style={{width: 200}} className="dropdown-menu-end">
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('meeting') } }>{"Универсальное"}</DropdownItem>
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('meeting') } }>{"Совещание"}</DropdownItem>
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('sales') } }>{"Продажа"}</DropdownItem>
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('interview') } }>{"Интервью"}</DropdownItem>
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('lecture') } }>{"Обучение"}</DropdownItem>
                    <DropdownItem onClick={ () => { if (OnCreateResume) OnCreateResume('document') } }>{"Документ"}</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
          </div> : 

          <>

            <div className='d-flex align-items-center justify-content-center'>
              <button className='btn btn-primary' 
              style={{background: userProfile?.AutoDecript == true && `linear-gradient(90deg, #0f1c42 ${percentes}%, #3b76e1 ${percentes}%)`}}
              disabled={!!protocolStatus && protocolStatus !== 'FAILURE' && protocolStatus !== 'NOT_EXIST' && protocolStatus !== 'SUCCESS'}  onClick={ () => { if (OnCreateResume) OnCreateResume('None') } }>{'Создать резюме'}</button>
            </div>

          </>
          }

        </div>
      }

      {canDelete && (protocolStatus !== "PENDING" && (fileStatus === 'UPLOADED' || fileStatus === 'NOT_PAID' || fileStatus === 'SUCCESS' || fileStatus === 'FAILURE')) ? 
      <div className='col-auto'>
        <button
          className='btn btn-outline-secondary'
          onClick={ deleteFile }
        >
          <i className="bx bx-trash font-size-16"/>
        </button>
      </div> : <></>}
      
      </div>

      <Modal
        isOpen={ isOpen }
        toggle={ () =>
          {
            onCancelTranslationFile()
            setIsOpen(!isOpen) 

            setPercentes(0)
            setTime(0)
            setAutoClick2(false)
          }}
        id="exampleModalScrollable"
        centered
      >
        <div className="modal-body text-center">
          <div className='d-flex justify-content-center text-center font-size-20'>
            <div className='ms-1 text-uppercase' style={{ wordBreak: "break-all", maxWidth: 400, fontWeight: "bold"  }}>{ language["File"] }{" "}{ fileName }</div>
          </div>
        </div>
        <div className="modal-body  justify-content-center align-items-center text-center">
          {document.location.host != "i.timelist.ru" ?
          <div className='row mb-3'>
            <div className='col'>
            <p className='font-size-18' >{ "Укажите язык расшифровки" }</p>
            <select className="form-select" onChange={ (e) => setLanguage(e.target.value) }>
              <option value="">{ "Автоопределение" }</option>  
              <option value="ru">{ "Русский" }</option>  
              <option value="en">{ "Английский" }</option>  
            </select>
            </div>
          </div> : null}
          <div className='row'>
            <div className='col'>
            <p className='font-size-18' >{ language["Speakers count"] }</p>
              { speakersCount.map(( number, idx ) => (
                <button
                  key={ idx }
                  type="button"
                  onClick={ () => setSpeakers(number) }
                  className={ speakers === number ? "btn btn-primary" : "btn btn-outline-primary" }
                  style={ { marginRight: 10, marginBottom: 10 } }
                >
                  { idx + 1 === speakersCount.length ? `${ number }+` : number }
                </button>
              )) } 
            </div>
          </div>
          <div className='row' style={ { marginTop: 20 } }>
            <div className='col'>
              <button
                onClick={ () => setSpeakers(0) }
                className={ speakers === 0 ? "btn btn-secondary" : "btn btn-outline-secondary" }
                style={ { marginRight: 10, marginBottom: 10 } }
              >
                Затрудняюсь ответить
              </button>
            </div>
          </div>
        </div>
        
        {config.ui.protocolButton == 1 ?
        <div className="modal-footer  justify-content-center align-items-center">
          <input type="checkbox" id="speakersCheckbox" checked={autoproto} onClick={ () => setAutoProto(!autoproto) } style={{cursor: 'pointer'}} />
          <label style={{cursor: 'pointer'}} for="speakersCheckbox">{ language["DecryptionWithProtocol"] }</label>
          {/*
          <button
              type="button"
              onClick={ () => {
                setSpeakers(-1)
                startTranslationFile(-1)
                setIsOpen(!isOpen)
              }}
              className="btn btn-primary "
            >
              { language["OneClickDecryption"] }
            </button>
            */}

        </div> : null}

        {config.ui.protocolButton == 1 && document.location.host != "i.timelist.ru" && autoproto ?
        <div className="modal-footer  justify-content-center align-items-center">
              <p className='font-size-18' >{ "Укажите тип резюме" }</p>

              <select className="form-select mt-2" onChange={ (e) => {
                setResumeType(e.target.value)
                localStorage.setItem("resumeType", e.target.value)
               }}>
                <option selected={localStorage.getItem("resumeType") == ""} value={"meeting"}>{ "Универсальное" }</option>  
                <option selected={localStorage.getItem("resumeType") == "meeting"} value={"meeting"}>{ "Совещание" }</option>  
                <option selected={localStorage.getItem("resumeType") == "sales"} value={"sales"}>{ "Продажа" }</option>  
                <option selected={localStorage.getItem("resumeType") == "interview"} value={"interview"}>{ "Интервью" }</option>  
                <option selected={localStorage.getItem("resumeType") == "lecture"} value={"lecture"}>{ "Обучение" }</option>  
                <option selected={localStorage.getItem("resumeType") == "document"} value={"document"}>{ "Документ" }</option>  
              </select>
        </div> : null}

        <div className="modal-footer  justify-content-center align-items-center">
          <button
            type="button"
            style={{background: userProfile?.AutoDecript == true && `linear-gradient(90deg, #0f1c42 ${percentes2}%, #3b76e1 ${percentes2}%)`}}
            onClick={ () => {
              startTranslationFile(speakers, autoproto, resumeType, decriptLang)
              setIsOpen(!isOpen)
              setAutoClick2(false)
            }}
            className="btn btn-primary "
          >
            { language["ContinueTranscript"] }
          </button>
          <button
            type="button"
            onClick={ () => 
              {
                onCancelTranslationFile()
                setIsOpen(!isOpen)
              } }
            className="btn btn-outline-primary "
          >
            { language["Cancel"] }
          </button>
        </div>
      </Modal>
      </animated.div>
  )
}
