import { useCallback } from 'react';

const useVideoControls = (videoRef) => {

    const playPause = useCallback(() => {
        const video = videoRef.current;
        if (!video) return;

        if (video.paused) {
            video.play();
        } else {
            video.pause();
        }
    }, []);

    const rewind = useCallback(() => {
        const video = videoRef.current;
        if (!video) return;

        video.currentTime = Math.max(video.currentTime - 5, 0);
    }, []);

    const forward = useCallback(() => {
        const video = videoRef.current;
        if (!video) return;

        video.currentTime = Math.min(video.currentTime + 5, video.duration);
    }, []);

    const changeVolume = useCallback((volume) => {
        const video = videoRef.current;
        if (!video) return;

        video.volume = volume;
    }, []);

    const setPlaybackRate = useCallback((rate) => {
        const video = videoRef.current;
        if (!video) return;

        video.playbackRate = rate;
    }, []);

    const toggleFullScreen = useCallback(async () => {
        const video = videoRef.current;
        if (!video) return;

        try {
            if (document.fullscreenElement) {
                await document.exitFullscreen();
            } else {
                await video.requestFullscreen();
            }
        } catch (err) {
            console.error('Error fullscreen mode:', err);
        }

    }, []);

    return { playPause, rewind, forward, changeVolume, setPlaybackRate, toggleFullScreen };
};

export default useVideoControls;
