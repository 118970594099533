module.exports = {
    google: {
        API_KEY: '',
        CLIENT_ID:
            '23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com',
        SECRET: '',
    },
    // на прремисах ставим 1
    onpremis: 0,
    facebook: {
        APP_ID: '',
    },
    apiserver: {
        domainAndProto: 'https://api.timelist.ru',
        host: 'https://api.timelist.ru/api',
        wsshost: 'https://subscriptions.timelist.ru',
    },
    ui: {
        protocolButton: 1,
    },
    fileserver: {
        host: "https://static.timelist.ru"
    },
    videoBot: {
       // сервер видеобота
       url: "https://confbot.timelist.ru"
    },
    wssserver: {
        // наш сервер сокет
        url: "wss://wsserver.timelist.ru"
    }
}
