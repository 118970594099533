/**
 * Formats the size
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  if (hours > 0) {
    return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`;
  }
};

export const parseTimeToSeconds = (time) => {
  if (!time) return 0;

  const dateString = typeof time === 'string' ? time : time.toString();
  const timeMatch = dateString.match(/\d{2}:\d{2}:\d{2}/);

  if (!timeMatch) return 0;

  const [hours, minutes, seconds] = timeMatch[0].split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};
