import React from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import graphql from '../graphql/gql'
import { useProfile } from './UserHooks'
import { NotifyContext } from '../providers/Notify'

// const { log } = console

export const useFileUploader = (onDone = {}) => {
    const { userId } = useProfile()
    const { setNotify } = React.useContext(NotifyContext)

    const onError = (error) =>
    {
        setNotify({ message: error.message, type: 'danger' })
    }

    const [uploadTitle] = useMutation(graphql.mutations.UPDATE_ORDER_TITLE, {
        onCompleted: (data) => {
            if (onDone) onDone('title', data.updateOrderTitle)
            return data.updateOrderTitle
        },
        onError,
    })
    const updateOrderTitleAPI = (orderId, title) =>
        uploadTitle({ variables: { id: orderId, title: title } })

    const [uploadFiles] = useMutation(graphql.mutations.UPLOADFILES, {
        onCompleted: (data) => {
            //if (onDone) onDone('files', data.uploadUserFiles)
            return data.uploadUserFiles
        },
        onError,
    })
    const uploadFilesAPI = (files) =>
        uploadFiles({ variables: { userId, files } })

    const [removeFile] = useMutation(graphql.mutations.REMOVE_FILE, {
        onCompleted: (data) => data.removeUserFile,
        onError,
    })
    const removeFileAPI = (deletedData) =>
        removeFile({ variables: { ...deletedData, userId } })

    const [translateFile] = useMutation(graphql.mutations.TRANSLATE_FILE, {
        onCompleted: (data) => data.translateFile,
        onError,
    })
    const translateFileAPI = (filename, orderId, speakers, autoprotocol, resumeType, decriptLang) => {
        return translateFile({
            variables: {
                orderId,
                userId,
                filename,
                speakers: speakers,
                autoprotocol: autoprotocol,
                resumeType: resumeType, 
                decriptLang: decriptLang
            },
        })
    }

    const [downloadDocs] = useLazyQuery(graphql.queries.DOWNLOAD_DOCS, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => data,
        onError,
    })
    const downloadDocsAPI = (queries) => downloadDocs({ variables: queries })

    const [updateOrderStrings] = useMutation(
        graphql.mutations.UPDATE_ORDER_STRINGS,
        {
            onCompleted: (data) => data,
            onError,
        }
    )
    const updateDecryptionAPI = (updateOrderId, newDecryption) => {
        return updateOrderStrings({
            variables: {
                updateOrderId,
                input: { lastCheckAnswer: newDecryption },
            },
        }).then(({ data }) => data.updateOrder.lastCheckAnswer)
    }

    const [UpdateOrderPublished] = useMutation(
        graphql.mutations.ORDERPUBLISHED,
        {
            onCompleted: (data) => {
                return data.setOrderPublic
            },
            onError,
        }
    )
    const updateOrderPublishedState = (updateOrderId, publicOrder) => {
        return UpdateOrderPublished({
            variables: { orderId: updateOrderId, publicOrder: publicOrder },
        }).then(({ data }) => data.id)
    }

    const updateProtocolAPI = (updateOrderId, newProtocol) => {
        return updateOrderStrings({
            variables: {
                updateOrderId,
                input: { protocolAnswer: newProtocol },
            },
        }).then(({ data }) => data.updateOrder.protocolAnswer)
    }

    const [updateSpeakers] = useMutation(
        graphql.mutations.UPDATE_DECRYPTION_SPEAKERS,
        {
            onCompleted: (data) => data,
            onError,
        }
    )
    const updateSpeakersAPI = (orderId, initialName, newName) => {
        return updateSpeakers({
            variables: { orderId, initialName, newName },
        }).then(({ data }) => ({
            updatedDecryption: data.changeSpeakerName.lastCheckAnswer,
            updatedSpeakers: data.changeSpeakerName.speakersData
                ? JSON.parse(data.changeSpeakerName.speakersData)
                : [],
        }))
    }

    const [createSpeaker] = useMutation(
        graphql.mutations.CREATE_DECRYPTION_SPEAKER,
        {
            onCompleted: (data) => data,
            onError,
        }
    )
    const createSpeakerAPI = (orderId, initialName, speakerName) => {
        return createSpeaker({
            variables: { orderId, initialName, speakerName },
        }).then(({ data }) => ({
            updatedDecryption: data.addSpeakerName.lastCheckAnswer,
            updatedSpeakers: data.addSpeakerName.speakersData
                ? JSON.parse(data.addSpeakerName.speakersData)
                : [],
        }))
    }

    const [removeSpeaker] = useMutation(
        graphql.mutations.REMOVE_DECRYPTION_SPEAKER,
        {
            onCompleted: (data) => data,
            onError,
        }
    )
    const removeSpeakerAPI = (orderId, speakerName) => {
        return removeSpeaker({ variables: { orderId, speakerName } }).then(
            ({ data }) => ({
                updatedDecryption: data.removeSpeakerName.lastCheckAnswer,
                updatedSpeakers: data.removeSpeakerName.speakersData
                    ? JSON.parse(data.removeSpeakerName.speakersData)
                    : [],
            })
        )
    }

    const [updateLeads] = useMutation(
        graphql.mutations.UPDATE_DECRYPTION_LEADS,
        {
            onCompleted: (data) => data,
            onError,
        }
    )
    const updateLeadsAPI = (orderId, initialName, role) => {
        return updateLeads({ variables: { orderId, initialName, role } }).then(
            ({ data }) =>
                data.changeSpeakerRole.speakersData
                    ? JSON.parse(data.changeSpeakerRole.speakersData)
                    : []
        )
    }

    const [generateProtocol] = useMutation(
        graphql.mutations.GENERATE_PROTOCOL,
        {
            onCompleted: (data) => data,
            onError,
        }
    )

    const [generateAnalitic] = useMutation(
        graphql.mutations.GENERATE_ANALITIC,
        {
            onCompleted: (data) => data,
            onError,
        }
    )

    const generateProtocolAPI = (orderId, resumeType, filteredDecription) => {
        return generateProtocol({
            variables: { orderId, input: { mainSpeakers: true, useFilteredDecription: filteredDecription }, resumeType: resumeType },
        }).then((data) => data)
    }

    const generateAnaliticAPI = (orderId) => {
        return generateAnalitic({
            variables: { orderId, input: {  } },
        }).then((data) => data)
    }

    const publishOrderAPI = (orderId, published) => {
        return updateOrderPublishedState(orderId, published)
    }

    return {
        removeFileAPI,
        translateFileAPI,
        uploadFilesAPI,
        downloadDocsAPI,
        updateSpeakersAPI,
        createSpeakerAPI,
        removeSpeakerAPI,
        updateLeadsAPI,
        updateDecryptionAPI,
        updateProtocolAPI,
        generateProtocolAPI,
        publishOrderAPI,
        updateOrderTitleAPI,
        generateAnaliticAPI
    }
}
