import React, { useState, useEffect } from 'react'
import { Col, Modal, Row } from 'reactstrap'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Popover,
    PopoverBody,
    PopoverHeader,
    Tooltip,
    UncontrolledPopover,
} from 'reactstrap'

import 'react-h5-audio-player/lib/styles.css'
import MetaTags from 'react-meta-tags'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { useDispatch, useSelector } from 'react-redux'
import { setBreadcrumb, setUserBalance } from '../../store/actions'
import { useFileUploader } from '../../Hooks/FileUploaderHook'
import { usePresets } from '../../Hooks/PresetsHooks'
import { UploadBlock } from './UploadBlock'
import { TeleLinkUploadBlock } from "./TeleLinkUploadBlock"
import { SearchBlock } from './SearchBlock'
import { BalanceBlock } from './BalanceBlock'
import { FileBlock } from './FileBlock'
import { useLoadData } from '../../Hooks/LoadDataHook'
import { TranslateContext } from '../../providers/Translate'
import { NotifyContext } from '../../providers/Notify'
import { Link } from 'react-router-dom'

import { useUsers } from '../../Hooks/UserHooks'
import { useProfile } from '../../Hooks/UserHooks'
import { v4 as uuidv4 } from 'uuid';
import ErrorBoundary from '../../components/ErrorBoundaru'

import {
    showRightSidebarAction,
    showSearchBarAction,
    showUploaderBarAction,
    toggleLeftmenu,
    changeSidebarType,
  } from "../../store/actions";
  

const animatedComponents = makeAnimated()

const optionGroup2 = [
    {
        label: 'Picnic',
        options: [
            { label: 'Mustard', value: 'Mustard' },
            { label: 'Ketchup', value: 'Ketchup' },
            { label: 'Relish', value: 'Relish' },
        ],
    },
    {
        label: 'Camping',
        options: [
            { label: 'Tent', value: 'Tent' },
            { label: 'Flashlight', value: 'Flashlight' },
            { label: 'Toilet Paper', value: 'Toilet Paper' },
        ],
    },
]

const Paginator = ({ page, totalOrders, itemsperpage, GoToPage }) => {
    const [pagesCount, SetPagesCount] = useState(0)

    useEffect(() => {
        SetPagesCount(Math.ceil(totalOrders / itemsperpage))
    }, [itemsperpage, totalOrders])

    return pagesCount > 1 ? (
        <Row>
            <Col className="col-md-12">
                <nav
                    aria-label="..."
                    className="d-flex align-items-center justify-content-center"
                >
                    <ul
                        className="pagination"
                        style={{ display: 'block', textAlign: 'center' }}
                    >
                        <li
                            className="page-item d-none d-md-inline-block"
                            key={'pagonatopaginator_r_0'}
                            style={{ display: 'inline-block' }}
                        >
                            <Link
                                className="page-link"
                                style={{ fontSize: 11 }}
                                to="#"
                                onClick={() => GoToPage(Math.max(1, page - 1))}
                                tabIndex="-1"
                            >
                                Назад
                            </Link>
                        </li>
                        {Array(pagesCount)
                            .fill(0)
                            .map((item, index) => {
                                return (
                                    <li
                                        className={'page-item'}
                                        style={{
                                            marginLeft: 2,
                                            marginRight: 2,
                                            marginBottom: 5,
                                            display: 'inline-block',
                                        }}
                                        key={'paginator_' + index + 1}
                                    >
                                        <Link
                                            className={'page-link'}
                                            style={{
                                                fontSize: 11,
                                                borderColor:
                                                    index + 1 == page
                                                        ? 'darkgray'
                                                        : '',
                                                fontWeight:
                                                    index + 1 == page
                                                        ? 'bold'
                                                        : '',
                                            }}
                                            to="#"
                                            onClick={() => GoToPage(index + 1)}
                                        >
                                            {index + 1}
                                        </Link>
                                    </li>
                                )
                            })}
                        <li
                            className="page-item d-none d-md-inline-block"
                            key={'paginator_' + 1000}
                            style={{ display: 'inline-block' }}
                        >
                            <Link
                                className="page-link"
                                style={{ fontSize: 11 }}
                                to="#"
                                onClick={() =>
                                    GoToPage(Math.min(pagesCount, page + 1))
                                }
                            >
                                Далее
                            </Link>
                        </li>
                    </ul>
                </nav>
            </Col>
        </Row>
    ) : null
}

let timer = null
export const MyFiles = () => {
    const dispatch = useDispatch()

    const { showSearchBar, showUploaderBar } = useSelector((state) => ({
        showSearchBar: state.Layout.showSearchBar,
        showUploaderBar: state.Layout.showUploaderBar,
    }))

    const { language } = React.useContext(TranslateContext)
    const { error: presetserror, LoadPresets } = usePresets()
    const { uploadFilesAPI, uploadFileFromLinkAPI } = useFileUploader()
    const { setNotify } = React.useContext(NotifyContext)
    const { userProfile } = useProfile()

    const [uploading, SetStartUploading] = useState(false)
    const [protocolModal, setProtocolModal] = React.useState(false)
    const [analiticaModal, setAnaliticaModal] = React.useState(false)
    const [selectShareUsersModal, setSelectShareUsersModal] =
        React.useState(false)
    const [createdProtocolName, setCreatedProtocolName] = React.useState(null)
    const [usersListForShare, setUsersListForShare] = React.useState([])

    const [minuteCost, setMinuteCost] = useState(0)
    const [maxFileSize, setMaxFileSize] = useState(0)
    const [isFilesUploading, setIsFilesUploading] = useState(false)
    const [isFilesConverting, setIsFilesConverting] = useState(false)
    const [balance, setBalance] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [selectedMulti3, setselectedMulti3] = useState(null)
    const [activedOrder, SetActivedOrder] = useState(null)
    const [page, SetPage] = useState(1)
    const [itemsperpage, SetPageSize] = useState(15)
    const [screenBlocked, SetBlockScreen] = React.useState("")
    
    const [searchtags, SetSearchTags] = useState(JSON.parse(localStorage.getItem('selectedTags') ?? '[]'))
    const [searchtext, SetSearchText] = useState('')
    const [searchtext2, SetSearchText2] = useState('')

    const [refreshIndex, setRefreshIndex] = useState(0)
    const tabId = window.name || (window.name = uuidv4());
    const [activeOrderId, setActiveOrderId] = useState(null);
    const orderRef = React.useRef({});

    const onProtocolCreate = (data) => {
        setCreatedProtocolName(data.videoLinkKey != "" ? data.title : data.file)
        setProtocolModal(true)
    }

    const onAnaliticaCreate = (name) => {
        setAnaliticaModal(true)
    }

    const onConvertationFileStart = (data) => {
        dispatch(showUploaderBarAction(true)); 
        setIsFilesConverting(true)
    }

    const onConvertationFileStop = (data) => {
        setIsFilesConverting(false)
        dispatch(showUploaderBarAction(false)); 
        //reloadOrdersAPI()
    }

    const { LoadUsers, AddConsolidation, RemoveConsolidation } = useUsers({
        onDone: (type, data) => {
            if (type == 'users') {
                var listGroup = [{ label: 'Все', options: [] }]
                data?.items?.forEach((userItem) => {
                    if (userItem.id != userProfile.id) {
                        listGroup[0].options.push({
                            label: `${userItem.f} ${userItem.i} ${userItem.o} ${
                                userItem.email ? userItem.email : ''
                            } ${userItem.phone ? userItem.phone : ''}`,
                            value: `${userItem.id}`,
                        })
                    }
                })
                setUsersListForShare(listGroup)
            }
        },
    })

    const {
        ordersList,
        orgdata,
        reloadOrdersAPI,
        loadSearchList,
        totalOrders,
        searchlist,
    } = useLoadData({
        onProtocolCreate,
        onAnaliticaCreate,
        onConvertationFileStart,
        onConvertationFileStop,
        onStartUpload: () => {
            SetStartUploading(true)
        },
        onDoneUpload: () => {
            SetStartUploading(false)
            SetBlockScreen("")
        },
        page: page,
        itemsperpage: itemsperpage,
        searchtext: searchtext2,
        tags: searchtags
    })

    const sendFiles = (selectedFiles) => {
        const filesWithEqualName = ordersList.orders
            ? selectedFiles.filter((file) =>
                  ordersList.orders.some(
                      (order) =>
                          order.file.split('.')[0] === file.name.split('.')[0]
                  )
              )
            : []
        if (filesWithEqualName.length) {
            setNotify({ message: language['EqualFileNames'], type: 'warning' })
            return
        }
        
        setIsFilesUploading(true)
        dispatch(showUploaderBarAction(true)); 

        uploadFilesAPI(selectedFiles).then(() => {
            setIsFilesUploading(false)
            dispatch(showUploaderBarAction(false)); 
            reloadOrdersAPI()
        })
    }
    const closeProtocolModal = () => {
        reloadOrdersAPI()
        setProtocolModal(!protocolModal)
        setCreatedProtocolName(null)
    }

    const closeAnaliticaModal = () => {
        reloadOrdersAPI()
        setAnaliticaModal(!analiticaModal)
    }

    const closeSharedUsersModal = () => {
        SetActivedOrder(null)
        setSelectShareUsersModal(!selectShareUsersModal)
    }

    const closeSharedUsersAndCreateModal = () => {
        setSelectShareUsersModal(!selectShareUsersModal)

        if (activedOrder && selectedMulti3) {
            var IdsList = selectedMulti3?.map((element) => element.value)
            //alert(IdsList)
            AddConsolidation({
                variables: { orderId: activedOrder.id, userIds: IdsList },
            })

            setselectedMulti3([])
        }
    }

    function handleMulti3(selectedItems) {
        setselectedMulti3(selectedItems)
    }

    function GoToPage(newpage) {
        SetPage(newpage)
    }

    //  useEffect(() => setMinutes(Math.round(balance / Math.max(1, minuteCost))), [balance, minuteCost])
    useEffect(() => reloadOrdersAPI(), [reloadOrdersAPI])

    useEffect(() => {
        LoadPresets({
            variables: { names: ['minutecost', 'maxfilesize'] },
        }).then(({ data }) => {
            const minuteCost =
                data?.presetslist?.filter(
                    (item) => item.name && item.name === 'minutecost'
                ) ?? []
            const maxFileSize =
                data?.presetslist?.filter(
                    (item) => item.name && item.name === 'maxfilesize'
                ) ?? []

            minuteCost.length && setMinuteCost(parseFloat(minuteCost[0].value))
            maxFileSize.length && setMaxFileSize(parseInt(maxFileSize[0].value))
        })
    }, [LoadPresets])

    useEffect(() => {
        dispatch(setBreadcrumb({ title: 'Рабочий стол' }))
    }, [dispatch])

    useEffect(() => {
        loadSearchList({ variables: { fragment: searchtext } })
    }, [searchtext])

    useEffect(()=>{
        reloadOrdersAPI()
        if (searchtags?.length>0)
        {
            dispatch(
                showSearchBarAction(true)
            )
        }
    }, [searchtags])

    useEffect(() => {}, [searchlist])

    useEffect(() => {
        
        const CLickOnDoc = (e) => {
            if (e.target && e.button == 0)
            {
                if (!e.target.closest(".editorContainer"))
                {
                    let event = new Event("closeditor");
                    document.dispatchEvent(event);
                }
            }   
        }
        
        document.addEventListener('mouseup', (e) => CLickOnDoc(e))

        return () =>  {
            document.removeEventListener('mouseup', (e) => CLickOnDoc)
        }
    }, [])

    React.useEffect(() => {
        try {
            const savedOrderId = sessionStorage.getItem(`activeOrderId_${tabId}`);
            if (savedOrderId) {
                setActiveOrderId(savedOrderId);
            }
        } catch (error) {
            console.error("Error restore order:", error);
        }
    }, []);

    const toggleOrderId = (orderId) => {
        const OFFSET = 100;
        try {
            if (activeOrderId === orderId) {
                setActiveOrderId(null);
                sessionStorage.removeItem(`activeOrderId_${tabId}`);
                sessionStorage.removeItem(`scrollPosition_${tabId}`);
            } else {
                setActiveOrderId(orderId);
                sessionStorage.setItem(`activeOrderId_${tabId}`, orderId);

                if (orderRef.current[orderId]) {
                    const rect = orderRef.current[orderId].getBoundingClientRect();
                    const offsetTop = rect.top + window.scrollY - OFFSET;
                    sessionStorage.setItem(`scrollPosition_${tabId}`, offsetTop);
                }
            }
        } catch (error) {
            console.error("Error save to sessionStorage:", error);
        }
    };

    return (
        <React.Fragment>
            <MetaTags>
                <title>
                    Кабинет | Таймлист - ИИ расшифровки аудио и
                    автопротоколирования
                </title>
            </MetaTags>
            <div className="page-content">
                <Row>
                    <div className="d-block d-md-none user-sidebar col-md-2">
                        <BalanceBlock
                            orgdata={orgdata}
                            minutes={balance}
                            totalOrders={totalOrders}
                        />
                    </div>

                    {showSearchBar ? (
                        <div className="col-md-12">
                            <SearchBlock
                                refresh={refreshIndex}
                                searchlist={searchlist}
                                onTagsChange={(tags) =>
                                {
                                   SetSearchTags(tags)
                                }}
                                onEnter={(text) => {
                                    GoToPage(1)
                                    SetSearchText2(text)
                                }}
                                onChange={(text) => {
                                    SetSearchText(text)
                                }}
                            />
                        </div>
                    ) : null}

                    <div className="col-md-12">
                        {isFilesUploading || isFilesConverting || showUploaderBar ? (
                            <div className='d-flex flex-wrap'>
                                <div className={document.location.hostname == "i.timelist.ru" ? "col-md-12" : "col-md-9"}>
                                    <UploadBlock
                                        maxFileSize={maxFileSize}
                                        isFilesUploading={isFilesUploading}
                                        isFilesConverting={isFilesConverting}
                                        sendFiles={sendFiles}
                                        terminationDate={orgdata?.orderDateTermination}
                                    />
                                </div>
                                
                                {document.location.hostname != "i.timelist.ru" ?  
                                    <div className="col-md-3">
                                        <TeleLinkUploadBlock />
                                    </div> : null}
                            </div>
                        ) : null}

                        {uploading ? (
                            <Row className="my-3">
                                <Col className="col-md-12 text-center">
                                    <div
                                        className="d-flex justify-content-between align-items-center"
                                        role="alert"
                                        style={{ width: 220, margin: '0 auto' }}
                                    >
                                        <span>Загрузка данных...</span>
                                        <span
                                            className="spinner-border text-primary m-1"
                                            style={{ marginLeft: 10 }}
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Загрузка данных...
                                            </span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        ) : null}

                        {totalOrders > 0 && (
                            <Paginator
                                page={page}
                                itemsperpage={itemsperpage}
                                totalOrders={totalOrders}
                                GoToPage={GoToPage}
                            />
                        )}

                        {ordersList &&
                            ordersList.orders?.map((order, index) => (
                                <ErrorBoundary>
                                    <FileBlock
                                        index={index}
                                        total={ordersList.orders?.length ?? 1}
                                        key={order.id}
                                        order={order}
                                        onChangeSpeaker={(orderId, oldSpeakerName, speakerName)=>{
                                            //alert(oldSpeakerName+" -> "+speakerName)
                                            SetBlockScreen(orderId)
                                            //reloadOrdersAPI()
                                        }}
                                        searchtext={searchtext2}
                                        userProfile={userProfile}
                                        orgdata={orgdata}
                                        tagsupdate={()=>{
                                            setRefreshIndex(refreshIndex+1)
                                        }}
                                        screenBlocked={screenBlocked}
                                        BlockSCreenMode={SetBlockScreen}
                                        reloadData={reloadOrdersAPI}
                                        onShareClick={(orderItem) => {
                                            SetActivedOrder(orderItem)
                                            LoadUsers({
                                                variables: {
                                                    page: 1,
                                                    itemsCount: 500,
                                                },
                                            })
                                            setSelectShareUsersModal(true)
                                        }}
                                        activeOrderId={activeOrderId}
                                        toggleOrderId={toggleOrderId}
                                        orderRef={orderRef}
                                        tabId={tabId}
                                        profile={userProfile}
                                    />
                                </ErrorBoundary>
                            ))}

                        {totalOrders > 0 && (
                            <Paginator
                                page={page}
                                itemsperpage={itemsperpage}
                                totalOrders={totalOrders}
                                GoToPage={GoToPage}
                            />
                        )}
                    </div>
                </Row>
            </div>

            <Modal
                isOpen={protocolModal}
                toggle={closeProtocolModal}
                id="exampleModalScrollable"
                centered
            >
                <Col className="px-5 py-3 d-flex align-items-center flex-column">
                    <div className="text-center">
                        {language.modals['AutoProtocol1']} <b>{createdProtocolName}</b> {language.modals['AutoProtocol2']}
                    </div>

                    <button
                        className="btn btn-primary mt-3"
                        type="button"
                        onClick={closeProtocolModal}
                    >
                        {language.modals['AutoProtocolBTN']}
                    </button>
                </Col>
            </Modal>

            <Modal
                isOpen={analiticaModal}
                toggle={closeAnaliticaModal}
                id="exampleModalScrollable22"
                centered
            >
                <Col className="px-5 py-3 d-flex align-items-center flex-column">
                    <div className="text-center">
                        {`Аналитика сформирована.`}
                    </div>

                    <button
                        className="btn btn-primary mt-3"
                        type="button"
                        onClick={closeAnaliticaModal}
                    >
                        {"Ok"}
                    </button>
                </Col>
            </Modal>

            <Modal
                className="modal-lg modal-fullscreen11"
                isOpen={selectShareUsersModal}
                toggle={closeSharedUsersModal}
                id="exampleModalScrollable2"
                centered
            >
                <div className="px-3 py-3 d-flex align-items-center flex-column">
                    <div className="mb-3 templating-select select2-container w-100">
                        <label className="control-label w-100 py-3">
                            Выберите в списке пользователей, которым желаете
                            передать свою расшифровку...
                        </label>
                        <Select
                            value={selectedMulti3}
                            isMulti={true}
                            onChange={handleMulti3}
                            options={usersListForShare}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={false}
                            placeholder="Выберите пользователей..."
                            components={animatedComponents}
                        />
                    </div>

                    <button
                        className="btn btn-primary mt-3"
                        type="button"
                        onClick={closeSharedUsersAndCreateModal}
                    >
                        {language.modals['Share']}
                    </button>
                </div>
            </Modal>
        </React.Fragment>
    )
}
