import { useLazyQuery, useSubscription } from "@apollo/client";
import graphql from "../graphql/gql";
import { useProfile } from "./UserHooks";
import React, { useState } from "react";
import { NotifyContext } from "../providers/Notify";

// const {log} = console

export const useLoadData = ({ onProtocolCreate, onAnaliticaCreate, onConvertationFileStart, onConvertationFileStop, onStartUpload = {}, onDoneUpload = {}, page = 1, itemsperpage = 200, searchtext = "", tags = [] }) => {
  const { userId } = useProfile();
  const { setNotify } = React.useContext(NotifyContext);

  const [ordersList, setOrdersList] = useState(null);
  const [orgdata, setOrgData] = useState(null);
  const [searchlist, setSearchList] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0)

  const onError = ( error ) => setNotify({ message: error.message, type: 'error' })

  const [loadFilesList] = useLazyQuery(graphql.queries.LOAD_FILES_LIST, {
    fetchPolicy: "network-only",
    onCompleted: ( data ) => data,
    onError,
  })

  const [loadSearchList] = useLazyQuery(graphql.queries.LOAD_SEARCH_LIST, {
    fetchPolicy: "network-only",
    onCompleted: ( data ) => setSearchList(data.popularsearch),
    onError,
  })

  const reloadOrdersAPI = React.useCallback(() => {
    onStartUpload()
    loadFilesList({ variables: { userId, page: page, itemsperpage: itemsperpage, searchtext: searchtext, tags: tags } })
      .then(( { data } ) => {
        // data && log('ORDERS', data.orders)
        data && setOrdersList(data.orders)
        data && setOrgData(data.orders.orgdata)
        data && setTotalOrders(data.orders.totalOrders ?? 0)
        onDoneUpload()
      })
  }, [loadFilesList, userId, page, itemsperpage, searchtext, tags])

  const onFileUpdate = ( { data }) => {
    const newOrder = data.data.transformationDone
    
    if (newOrder.lastCheckStatus === 'SUCCESS') {
      const updatedOrders = ordersList.orders.map(order =>
        order.id === newOrder.id
          ? ({ ...newOrder, lastCheckStatus: 'NEW_FILE', owner: {id : userId} })
          : order
      )
      setOrdersList({ ...ordersList, orders: updatedOrders })
      return
    }

    reloadOrdersAPI()
  }

  useSubscription(graphql.subscriptions.ON_ORDERS_UPDATE, {
    variables: { userId },
    onData: onFileUpdate
  })

  useSubscription(graphql.subscriptions.GENERATION_PROTOCOL_DONE, {
    variables: { userId },
    onData: ( { data } ) => onProtocolCreate(data.data.generationProtocolDone.file),
    onError,
  })

  useSubscription(graphql.subscriptions.GENERATION_ANALITICA_DONE, {
    variables: { userId },
    onData: ( { data } ) => onAnaliticaCreate(data.data.generationAnaliticDone.file),
    onError,
  })

  useSubscription(graphql.subscriptions.convertationToMp3Start, {
    variables: { userId },
    onData: ( { data } ) => onConvertationFileStart(data.data.convertationToMp3Start),
    onError,
  })

  useSubscription(graphql.subscriptions.convertationToMp3Stop, {
    variables: { userId },
    onData: ( { data } ) => onConvertationFileStop(data.data.convertationToMp3Stop),
    onError,
  })

  return { ordersList, orgdata, reloadOrdersAPI, loadSearchList, totalOrders, searchlist }
}
